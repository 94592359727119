import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
  Button,
  Box,
  Alert,
  AlertTitle,
  Divider
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import authService from '../services/authService';
import neichaLogo from '../assets/neicha-logo.png';

const AuthOverlay = () => {
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState({
    loading: false,
    operation: '',
    timeout: false,
    errors: null
  });
  
  // Listen for auth service events
  useEffect(() => {
    const timeoutId = { current: null };
    
    const unsubscribe = authService.addListener((event) => {
      console.log("Auth event:", event.type);
      
      if (event.type === 'refreshingToken' && event.isRefreshing) {
        setStatus({
          loading: true,
          operation: 'Refreshing your authentication',
          timeout: false,
          errors: null
        });
        setOpen(true);
        
        // Set timeout for slow operations
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
          setStatus(s => ({ ...s, timeout: true }));
        }, 15000);
      } 
      else if (event.type === 'fetchingApiKeys' && event.isFetching) {
        setStatus({
          loading: true,
          operation: 'Authenticating your session',
          timeout: false,
          errors: null
        });
        setOpen(true);
        
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = setTimeout(() => {
          setStatus(s => ({ ...s, timeout: true }));
        }, 15000);
      }
      else if (event.type === 'apiKeyError') {
        setStatus({
          loading: false,
          operation: '',
          timeout: false,
          errors: event.errors
        });
        setOpen(true);
        
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
          timeoutId.current = null;
        }
      }
      else if (
        (event.type === 'tokenRefreshed' || 
         event.type === 'apiKeysUpdated' ||
         (event.type === 'refreshingToken' && !event.isRefreshing) ||
         (event.type === 'fetchingApiKeys' && !event.isFetching))
      ) {
        // Only close if we don't have errors
        if (!status.errors) {
          setTimeout(() => {
            setOpen(false);
            setStatus(s => ({ ...s, loading: false, timeout: false }));
          }, 500);
        }
        
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
          timeoutId.current = null;
        }
      }
    });
    
    return () => {
      unsubscribe();
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [status.errors]);
  
  const handleLogout = () => {
    authService.clearAuthData();
    logout({ returnTo: window.location.origin });
  };
  
  const handleManualRefresh = () => {
    window.location.reload();
  };
  
  return (
    <Dialog 
      open={open} 
      maxWidth="sm" 
      fullWidth
      disableEscapeKeyDown={!!status.errors}
      onClose={() => !status.errors && setOpen(false)}
    >
      <DialogContent>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center',
          py: 4
        }}>
          <img 
            src={neichaLogo} 
            alt="Neicha Logo" 
            style={{ width: '180px', marginBottom: '24px' }} 
          />
          
          {status.loading && !status.timeout && !status.errors && (
            <>
              <CircularProgress size={60} sx={{ mb: 3 }} />
              <Typography variant="h6">{status.operation}.. Please Wait</Typography>
            </>
          )}
          
          {status.timeout && !status.errors && (
            <>
              <CircularProgress size={60} sx={{ mb: 3 }} />
              <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                It seems this is taking longer than expected.
              </Typography>
              <Button 
                variant="contained" 
                onClick={handleManualRefresh}
              >
                Click here to refresh your session manually
              </Button>
            </>
          )}
          
          {status.errors && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <Alert severity="error" sx={{ mb: 2 }}>
                <AlertTitle>Authentication Error</AlertTitle>
                <Typography variant="body1">
                  You don't have access to some required services.
                  <br />
                  <strong>Please contact your administrator for access.</strong>
                </Typography>
              </Alert>
              
              <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                Missing permissions:
              </Typography>
              
              {Object.entries(status.errors).map(([service, error]) => (
                <Alert 
                  key={service} 
                  severity="warning"
                  sx={{ mb: 1 }}
                >
                  <strong>{service}:</strong> {error}
                </Alert>
              ))}
              
              <Divider sx={{ my: 3 }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button 
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AuthOverlay;