// app.js

import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes, 
  Route,
  useLocation,
  useNavigate,
  Navigate,
  Outlet
} from 'react-router-dom';

import { CssBaseline, Drawer, List, ListItem, ListItemText, Container, CircularProgress, useMediaQuery, Toolbar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Collapse, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './components/Header';
import Dashboard from './components/Dashboard';
import ClickCollectOrders from './components/ClickCollectOrders';
import BulkOrders from './components/BulkOrders';
import StockRequests from './components/StockRequests';
import ConfigureStore from './components/ConfigureStore';
import Settings from './components/Settings';
import ViewRequest from './components/ViewRequest';
import CreateStockRequest from './components/CreateStockRequest';
import StoreSelection from './components/StoreSelection';
import Autoship from './components/AutoshipSchedules';
import BulkSchedules from './components/BulkSchedules';
import BulkScheduleDetails from './components/BulkScheduleDetails';
import BarcodeListen from './components/BarcodeListen';
import MobileHome from './components/Mobile/MobileHome';
import StockTake from './components/Mobile/StockTake';
import SearchInventory from './components/Mobile/SearchInventory';
import BatchReceiveBulkOrders from './components/Mobile/BatchReceiveBulkOrders';
import SmsCustomer from './components/Mobile/SmsCustomer';
import Production from './components/Production';
import ViewClickCollectOrder from './components/ViewClickCollectOrder';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Logout from './components/Logout';
import LabelTemplateBuilder from './components/LabelTemplate';
import MobileSettings from './components/Mobile/MobileSettings';
import StockLevelManager from './components/StockLevelManager';
import PickAndPack from './components/Mobile/PickAndPack';
import OrderReport from './components/OrderReport';
import CreateBulkOrder from './components/CreateBulkOrder';
import ExpressDelivery from './components/ExpressDelivery';
import ProductionSchedule from './components/ProductionSchedule';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import useAuth from './hooks/useAuth';
import authService from './services/authService';
import AuthAlerts from './components/AuthAlerts';
import NetworkDetector from './components/NetworkDetector';
import neichaLogo from './assets/neicha-logo.png';
import ConfigureProteins from './components/ConfigureProteins';
import packageJson from '../package.json';

// Same MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#9ba03b',
    },
    secondary: {
      main: '#000000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
  },
});

// NavItem for the Drawer
const NavItem = ({ to, text, style, storeId, modalHandler, currentPath }) => {
  const navigate = useNavigate();
  const isActive = currentPath === (storeId ? `${to}/${storeId}` : to);

  const handleClick = () => {
    if (modalHandler) {
      modalHandler();
    } else {
      const linkTo = storeId ? `${to}/${storeId}` : to;
      navigate(linkTo);
    }
  };

  return (
    <ListItem
      button
      onClick={handleClick}
      style={{ ...style, backgroundColor: isActive ? '#f0f0f0' : 'inherit' }}
    >
      <ListItemText primary={text} />
    </ListItem>
  );
};

const drawerWidth = '15vw';
const contentWidth = '85vw';

const DesktopLayout = () => {
  return (
    <div style={{paddingLeft:"1em", paddingRight:"1em"}}>
      {/* All child routes (desktop-only) will render inside here */}
      <Outlet />
    </div>
  );
};

function App() {
  // ------------------------------------------------------------------------------
  // 1) All your "top-level" logic: Auth0, tokens, store fetching, loading states
  // ------------------------------------------------------------------------------
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileDevice = useMediaQuery('(max-width:768px)');

  // Replace individual Auth0 hooks with useAuth
  const { 
    isLoading: auth0Loading,
    isAuthenticated,
    user,
    loginWithRedirect,
    logout,
    apiKeys,
    getApiKey,
    getAccessTokenSilently  // Add this line to extract the function from useAuth
  } = useAuth();

  const [loading, setLoading] = useState(true); // for store loading
  const [stores, setStores] = useState([]);

  const appVersion = packageJson.version;

  const [selectedStore, setSelectedStore] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('selectedStore'));
    } catch {
      return null;
    }
  });

  const [spinnerTimeout, setSpinnerTimeout] = useState(false);
  const [spinnerTimeoutDuration] = useState(30000);

  // Add this state near your other states
  const [authStuckDetection, setAuthStuckDetection] = useState({
    startTime: null,
    timeoutId: null
  });

  useEffect(() => {
    const onPosRoute = location.pathname.startsWith('/pos/');
    const onMobileRoute = location.pathname.startsWith('/mobile/');
  
    // If device is mobile, user is authenticated, 
    // store is selected, 
    // and we’re not already on a /mobile or /pos route => go to /mobile/home
    if (
      isMobileDevice &&
      isAuthenticated &&
      selectedStore &&
      !onPosRoute &&
      !onMobileRoute
    ) {
      navigate('/mobile/home');
    }
  }, [isMobileDevice, isAuthenticated, selectedStore, location, navigate]);  

  const [bulkOrderModalOpen, setBulkOrderModalOpen] = useState(false);
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [reportingOpen, setreportingOpen] = useState(false);

  // PWA Install
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [installDialogOpen, setInstallDialogOpen] = useState(false);

  // Page-level spinner for route transitions
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    setPageLoading(true);
    const timer = setTimeout(() => setPageLoading(false), 500);
    return () => clearTimeout(timer);
  }, [location]);

  // ----------
  // Handler logic
  // ----------
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => clearStorageAndLogout();
  const handleBulkOrdersClick = () => setreportingOpen(!reportingOpen);
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        setDeferredPrompt(null);
        setInstallDialogOpen(false);
      });
    }
  };

  const clearStorageAndLogout = () => {
    localStorage.removeItem('selectedStore');
    authService.clearAuthData(); // Use the auth service to clear auth data
    logout({ returnTo: window.location.origin });
  };

  const handleOpenBulkOrderModal = () => setBulkOrderModalOpen(true);
  const handleCloseBulkOrderModal = () => setConfirmCloseDialogOpen(true);
  const handleConfirmClose = () => {
    setConfirmCloseDialogOpen(false);
    setBulkOrderModalOpen(false);
  };
  const handleCancelClose = () => setConfirmCloseDialogOpen(false);

  // Add this state near other state declarations
  const [configOpen, setConfigOpen] = useState(false);

  // Add this handler with other handlers
  const handleConfigClick = () => setConfigOpen(!configOpen);

  // ----------
  // Auth0 => fetch stores
  // ----------
  useEffect(() => {
    // If Auth0 done loading and user is not authenticated => redirect
    if (!auth0Loading && !isAuthenticated) {
      localStorage.setItem('redirectAfterLogin', location.pathname);
      loginWithRedirect();
    }
  }, [auth0Loading, isAuthenticated, loginWithRedirect, location.pathname]);

  // Replace old token handling with new auth system
  useEffect(() => {
    if (isAuthenticated && apiKeys.wms) {
      fetchStores();
    }
  }, [isAuthenticated, apiKeys]);

  const fetchStores = async () => {
    try {
      // Use the API key from auth service instead of sessionStorage
      const access_token = getApiKey('wms');
      if (!access_token) throw new Error('Access token not found');

      const response = await axios.get('https://wms-api.neicha.com.au/stores', {
        headers: { Authorization: `Bearer ${access_token}` }
      });
      setStores(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stores:', error);
      clearStorageAndLogout();
    }
  };

  // ----------
  // "Before install" prompt
  // ----------
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  useEffect(() => {
    // After successful Auth0 login, initialize our auth service
    if (isAuthenticated && !auth0Loading) {
      console.log("Auth0 authenticated, initializing auth service...");
      
      // Make Auth0 client available globally for new tabs
      window.auth0 = {
        getTokenSilently: getAccessTokenSilently,
        getUser: () => Promise.resolve(user)
      };
      
      // Only call this function if it exists (on first tab)
      if (typeof window.__auth0_getUserInfo === 'function') {
        window.__auth0_getUserInfo({
          getTokenSilently: getAccessTokenSilently,
          getUser: () => Promise.resolve(user)
        });
      } else {
        // For new tabs, directly initialize auth service
        authService.init({
          getTokenSilently: getAccessTokenSilently,
          getUser: () => Promise.resolve(user)
        }).then(() => {
          // After initializing, fetch API keys if needed
          const currentKeys = authService.getApiKeys();
          if (Object.keys(currentKeys).length === 0) {
            authService.fetchApiKeys();
          }
        });
      }
    }
  }, [isAuthenticated, auth0Loading, user, getAccessTokenSilently]);

  useEffect(() => {
    let timeoutId;
    
    if (auth0Loading || (loading && isAuthenticated)) {
      timeoutId = setTimeout(() => {
        setSpinnerTimeout(true);
      }, spinnerTimeoutDuration);
    }
    
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [auth0Loading, loading, isAuthenticated, spinnerTimeoutDuration]);

  // Add this effect to detect stuck auth state
  useEffect(() => {
    if (auth0Loading || (loading && isAuthenticated)) {
      // Start stuck detection if not already started
      if (!authStuckDetection.startTime) {
        const stuckTimeout = setTimeout(() => {
          console.error("Authentication appears stuck, forcing logout");
          clearStorageAndLogout();
        }, 45000); // 45 seconds max for auth process
        
        setAuthStuckDetection({
          startTime: Date.now(),
          timeoutId: stuckTimeout
        });
      }
    } else {
      // Reset stuck detection when not loading
      if (authStuckDetection.timeoutId) {
        clearTimeout(authStuckDetection.timeoutId);
        setAuthStuckDetection({
          startTime: null,
          timeoutId: null
        });
      }
    }
    
    return () => {
      if (authStuckDetection.timeoutId) {
        clearTimeout(authStuckDetection.timeoutId);
      }
    };
  }, [auth0Loading, loading, isAuthenticated]);

  // ----------
  // 2) If STILL loading from Auth0 or fetching stores => show spinner
  // ----------
  if (auth0Loading || (loading && isAuthenticated)) {
    return (
      <div style={{ 
        height: '100vh', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center', 
        flexDirection: 'column',
        backgroundColor: '#f5f5f5' 
      }}>
        <img 
          src={neichaLogo} 
          alt="Neicha Logo" 
          style={{ 
            width: '220px', 
            marginBottom: '2rem' 
          }} 
        />
        <CircularProgress sx={{ color: '#9ba03b' }} />
        
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {spinnerTimeout ? 
              "Loading is taking longer than expected." : 
              "Authenticating your session..."}
          </Typography>
          
          {spinnerTimeout && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.location.reload()}
                sx={{ mt: 2, mr: 1 }}
              >
                Retry
              </Button>
              
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  window.location.href = '/';
                }}
                sx={{ mt: 2 }}
              >
                Logout & Reset
              </Button>
            </>
          )}
        </Box>
      </div>
    );
  }

  // 3) If user hasn't selected store and not on mobile => show store selection
  //    (Adjust logic to your preference)
  const storeNotSelected = !selectedStore && !isMobileDevice;
  if (storeNotSelected) {
    return (
      <StoreSelection
        stores={stores}
        onStoreSelect={(store) => {
          setSelectedStore(store);
          localStorage.setItem('selectedStore', JSON.stringify(store));
        }}
      />
    );
  }

  // 4) Decide which layout to show based on the path
  //    e.g. if path starts with "/pos/", skip header & drawer
  //         if path starts with "/mobile/", show mobile
  //         else normal layout
  const isPosRoute = location.pathname.startsWith('/pos/');
  const isMobileRoute = location.pathname.startsWith('/mobile/');

  // 5) Render
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthAlerts />
      <NetworkDetector />
      {/* If not a POS route and not a Mobile route => show normal header/drawer */}
      {isMobileRoute && (
      <>
        <Header
          anchorEl={anchorEl}
          handleMenu={handleMenu}
          handleClose={handleClose}
          handleLogout={handleLogout}
        />
      </>
    )}

    {!isPosRoute && !isMobileRoute && (
        <>
          <Header
            anchorEl={anchorEl}
            handleMenu={handleMenu}
            handleClose={handleClose}
            handleLogout={handleLogout}
          />
          {/* If store is selected, show the drawer */}
          {selectedStore && (
            <>
              <BarcodeListen />
              <Drawer
                variant="permanent"
                style={{ width: drawerWidth, flexShrink: 0 }}
                PaperProps={{ style: { width: drawerWidth, boxSizing: 'border-box' } }}
              >
                <Toolbar />
                <List>
                <small style={{marginLeft:"1em", color:"grey"}}>DASHBOARD</small>
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/dashboard" text="Dashboard" />
                  {/* Reporting accordion */}
                  <ListItem button onClick={handleBulkOrdersClick}>
                    <ListItemText primary="Reporting" />
                    {reportingOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={reportingOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <NavItem
                        style={{ paddingLeft: '2em', cursor: 'pointer', background: '#f7f7f7' }}
                        to="/order-report"
                        text="Bulk Order Report"
                        currentPath={location.pathname}
                      />
                      <NavItem
                        style={{ paddingLeft: '2em', cursor: 'pointer', background: '#f7f7f7' }}
                        to="/production"
                        text="Production Reporting"
                        currentPath={location.pathname}
                      />
                    </List>
                  </Collapse>
                  <small style={{marginLeft:"1em", color:"grey"}}>BULK ORDERING</small>
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} text="Create Bulk Order" modalHandler={handleOpenBulkOrderModal} />
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/bulk-orders" text="Search Bulk Orders" />
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/bulk-schedules" text="Bulk Schedules" />
                  <small style={{marginLeft:"1em", color:"grey"}}>STOCK MANAGEMENT</small>
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/stock-requests" text="Stock Requests" />
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/stock-availability" text="Stock Availability" />
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/production-schedule" text="Production Schedule" />
                  <small style={{marginLeft:"1em", color:"grey"}}>WEB ORDERS </small>
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/click-collect-orders" text="Click & Collect Orders" />
                  <NavItem currentPath={location.pathname} style={{ cursor: 'pointer' }} to="/autoship-schedules" text="Autoship Schedules" />
                  <small style={{marginLeft:"1em", color:"grey"}}>VERSION {appVersion} </small>
                  <ListItem button onClick={handleConfigClick}>
                    <ListItemText primary="Configuration" />
                    {configOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={configOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <NavItem
                        style={{ paddingLeft: '2em', cursor: 'pointer', background: '#f7f7f7' }}
                        to="/configure-store"
                        text="Configure Store"
                        storeId={selectedStore?.id}
                        currentPath={location.pathname}
                      />
                      <NavItem
                        style={{ paddingLeft: '2em', cursor: 'pointer', background: '#f7f7f7' }}
                        to="/configure-proteins"
                        text="Configure Proteins"
                        currentPath={location.pathname}
                      />
                      <NavItem
                        style={{ paddingLeft: '2em', cursor: 'pointer', background: '#f7f7f7' }}
                        to="/settings"
                        text="Settings"
                        currentPath={location.pathname}
                      />
                    </List>
                  </Collapse>
                </List>
              </Drawer>
            </>
          )}
        </>
      )}

      {/* Main container for content (only if not mobile or pos route) */}
      <div
        style={
          !isPosRoute && !isMobileRoute
            ? { marginLeft: drawerWidth, width: contentWidth, backgroundColor: '#fafafa', paddingTop: '6em' }
            : {}
        }
      >
        {/* ------------------------------- */}
        {/* ALL Routes in ONE place        */}
        {/* ------------------------------- */}
        <Routes>
          {/* POS routes (no header / no drawer) */}
          <Route path="/pos/create-bulk"       element={<CreateBulkOrder />} />
          <Route path="/pos/view-schedules"    element={<BulkSchedules  style={{ margin: '1em' }} />} />
          <Route path="/pos/view-schedule/:id" element={<BulkScheduleDetails  style={{ margin: '1em' }} />} />
          <Route path="/pos/express-delivery"  element={<ExpressDelivery  style={{ margin: '1em' }} />} />

          {/* MOBILE routes */}
          <Route path="/mobile/home" element={<MobileHome />} />
          <Route path="/mobile/stock-take" element={<StockTake />} />
          <Route path="/mobile/search-inventory" element={<SearchInventory />} />
          <Route path="/mobile/batch-receive-bulk-orders" element={<BatchReceiveBulkOrders />} />
          <Route path="/mobile/sms-customer" element={<SmsCustomer />} />
          <Route path="/mobile/settings" element={<MobileSettings stores={stores} />} />
          <Route path="/mobile/pick-pack" element={<PickAndPack />} />

         {/* Desktop routes with a layout wrapper */}
         <Route element={<DesktopLayout />}>
           <Route path="/dashboard" element={<Dashboard />} />
           <Route path="/click-collect-orders" element={<ClickCollectOrders stores={stores} />} />
           <Route path="/bulk-orders" element={<BulkOrders />} />
           <Route path="/bulk-schedules" element={<BulkSchedules />} />
           <Route path="/bulk-schedules/:id" element={<BulkScheduleDetails />} />
           <Route path="/stock-requests" element={<StockRequests />} />
           <Route path="/autoship-schedules" element={<Autoship />} />
           <Route path="/order-report" element={<OrderReport />} />
           <Route path="/production" element={<Production />} />
           <Route path="/production-schedule" element={<ProductionSchedule />} />
           <Route path="/configure-store/:id" element={<ConfigureStore stores={stores} />} />
           <Route path="/settings" element={<Settings stores={stores} />} />
           <Route path="/view-request/:id" element={<ViewRequest />} />
           <Route path="/view-click-collect-order/:id" element={<ViewClickCollectOrder />} />
           <Route path="/create-stock-request" element={<CreateStockRequest />} />
           <Route path="/create-bulk-order" element={<CreateBulkOrder />} />
           <Route path="/label-template" element={<LabelTemplateBuilder />} />
           <Route path="/stock-availability" element={<StockLevelManager />} />
           <Route path="/configure-proteins" element={<ConfigureProteins />} />
         </Route>

          {/* Utility routes */}
          <Route path="/logout" element={<Logout />} />

          {/* Catch-all => navigate somewhere */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>

        {/* Show spinner overlay on route changes */}
        {pageLoading && (
          <div style={{
            position: 'absolute', top:0, left:0, width:'100%', height:'100%',
            backgroundColor:'rgba(255, 255, 255, 0.7)',
            display:'flex', justifyContent:'center', alignItems:'center',
            zIndex:10
          }}>
            <CircularProgress sx={{ color: '#9ba03b' }} />
          </div>
        )}
      </div>

      {/* Create Bulk Order Dialog */}
      <Dialog
        fullScreen
        open={bulkOrderModalOpen}
        PaperProps={{ style: { overflow: 'hidden' } }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseBulkOrderModal();
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="bulk-order-dialog-title"
      >
        <DialogTitle style={{ backgroundColor: '#9ba03b', color: 'white' }} id="bulk-order-dialog-title">
          Create Bulk Order
          <Button onClick={handleCloseBulkOrderModal} style={{ float: 'right' }}>
            Cancel
          </Button>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: '0px', paddingRight: '0px', overflow: 'hidden' }}>
          <CreateBulkOrder />
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmCloseDialogOpen}
        onClose={handleCancelClose}
        aria-labelledby="confirm-close-dialog-title"
      >
        <DialogTitle id="confirm-close-dialog-title">
          Are you sure you want to close the Create Bulk Order page?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirmClose} style={{ backgroundColor: '#9ba03b', color: 'white' }}>
            Yes, Close
          </Button>
          <Button onClick={handleCancelClose} style={{ backgroundColor: 'lightgray', color: 'black' }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Install App Dialog */}
      <Dialog open={installDialogOpen} onClose={() => setInstallDialogOpen(false)}>
        <DialogTitle>Install App</DialogTitle>
        <DialogContent>
          <p>Would you like to install this app?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInstallClick}
            variant="contained"
            color="primary"
            startIcon={isMobileDevice ? <InstallMobileIcon /> : <InstallDesktopIcon />}
          >
            Install
          </Button>
          <Button onClick={() => setInstallDialogOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}