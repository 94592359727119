import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import { 
  Typography, 
  Paper, 
  Box, 
  Grid, 
  Button, 
  Card, 
  CardContent, 
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import useAuth from '../hooks/useAuth';

// Map between numeric day index and string key for API
const dayIndexToKey = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  null: 'unassigned'
};

// Map between string key and numeric day index for API
const keyToDayIndex = {
  'monday': 1,
  'tuesday': 2,
  'wednesday': 3,
  'thursday': 4,
  'friday': 5,
  'unassigned': null
};

const ProductionSchedule = () => {
  const { getApiKey } = useAuth();
  
  // Add edit mode state
  const [editMode, setEditMode] = useState(false);
  
  // State for schedule, loading, and error
  const [schedule, setSchedule] = useState({
    unassigned: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: []
  });
  
  const [loading, setLoading] = useState(true);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [error, setError] = useState(null);
  
  // Dialog states
  const [confirmClearOpen, setConfirmClearOpen] = useState(false);
  const [productsDialogOpen, setProductsDialogOpen] = useState(false);
  const [selectedProtein, setSelectedProtein] = useState(null);

  // Function to toggle edit mode
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Fetch schedule data on component mount
  useEffect(() => {
    fetchSchedule();
  }, []);
  
  const fetchSchedule = async () => {
    try {
      setLoading(true);
      const accessToken = getApiKey('wms');
      const response = await axios.get('https://wms-api.neicha.com.au/protein/schedule', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setSchedule(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load production schedule');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const saveSchedule = async (newSchedule) => {
    try {
      setSaveInProgress(true);
      const accessToken = getApiKey('wms');
      await axios.post('https://wms-api.neicha.com.au/protein/schedule', 
        newSchedule,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      // No need to fetch again as we already have the updated state
      setError(null);
    } catch (err) {
      setError('Failed to save schedule changes');
      console.error(err);
      // Refresh to get the server's actual state
      fetchSchedule();
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleClearSchedule = () => {
    setConfirmClearOpen(true);
  };

  const confirmClearSchedule = async () => {
    try {
      const allProteins = [
        ...schedule.unassigned,
        ...schedule.monday,
        ...schedule.tuesday,
        ...schedule.wednesday,
        ...schedule.thursday,
        ...schedule.friday
      ];
      
      // Create a new schedule with all proteins in unassigned
      const newSchedule = {
        unassigned: allProteins,
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: []
      };
      
      await saveSchedule(newSchedule);
      setSchedule(newSchedule);
    } catch (err) {
      setError('Failed to clear schedule');
      console.error(err);
    } finally {
      setConfirmClearOpen(false);
    }
  };

  const handleOpenProductsDialog = (protein) => {
    setSelectedProtein(protein);
    setProductsDialogOpen(true);
  };

  // Handle dragging and dropping proteins - only when in edit mode
  const onDragEnd = async (result) => {
    // If not in edit mode, don't process drag operations
    if (!editMode) return;
    
    const { source, destination } = result;
    
    // Dropped outside a droppable area
    if (!destination) return;
    
    // If dropped in the same place
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    
    // Create a copy of the current schedule
    const newSchedule = {...schedule};
    
    // Remove from source
    const sourceList = [...newSchedule[source.droppableId]];
    const [removedItem] = sourceList.splice(source.index, 1);
    newSchedule[source.droppableId] = sourceList;
    
    // If the destination is "trash", move to unassigned
    if (destination.droppableId === "trash") {
      newSchedule.unassigned.push(removedItem);
    } else {
      // Add to destination
      const destList = [...newSchedule[destination.droppableId]];
      destList.splice(destination.index, 0, removedItem);
      newSchedule[destination.droppableId] = destList;
    }
    
    // Update local state immediately for responsive UI
    setSchedule(newSchedule);
    
    // Save the changes to the server
    await saveSchedule(newSchedule);
  };

  // Render each day column
  const renderDay = (day, title) => {
    return (
      <Grid item xs={12} sm={6} md={2}>
        <Paper 
          elevation={3}
          sx={{
            p: 2,
            height: '100%',
            minHeight: 400,
            backgroundColor: '#f8f8f8',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography variant="h6" align="center" gutterBottom sx={{ borderBottom: '2px solid #9ba03b', pb: 1 }}>
            {title}
          </Typography>
          <Droppable droppableId={day} isDropDisabled={!editMode}>
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  flexGrow: 1,
                  minHeight: '100px',
                  backgroundColor: 'white',
                  p: 1,
                  borderRadius: 1,
                  boxShadow: 'inset 0 0 5px rgba(0,0,0,0.1)',
                  overflow: 'auto'
                }}
              >
                {schedule[day].map((protein, index) => (
                  <Draggable key={protein.id} draggableId={`${protein.id}`} index={index} isDragDisabled={!editMode}>
                    {(provided) => (
                      <Card
                        ref={provided.innerRef}
                        {...(editMode ? provided.draggableProps : {})}
                        {...(editMode ? provided.dragHandleProps : {})}
                        sx={{
                          mb: 1,
                          backgroundColor: '#9ba03b',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#889033',
                            transform: editMode ? 'scale(1.02)' : 'none',
                          },
                          transition: 'all 0.2s',
                          cursor: editMode ? 'grab' : 'pointer',
                          border: editMode ? '2px dashed rgba(255,255,255,0.5)' : 'none'
                        }}
                        onClick={() => handleOpenProductsDialog(protein)}
                      >
                        <CardContent sx={{ py: 1, '&:last-child': { pb: 1 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="body1">{protein.name}</Typography>
                          {protein.products && protein.products.length > 0 && (
                            <InfoIcon fontSize="small" />
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Paper>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 2, mb: "1em" }}>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h4" gutterBottom>
              Production Schedule
            </Typography>
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={editMode ? "success" : "primary"}
              startIcon={editMode ? <SaveIcon /> : <EditIcon />}
              onClick={toggleEditMode}
              sx={{ ml: 2 }}
            >
              {editMode ? "Save & Exit Edit Mode" : "Edit Schedule"}
            </Button>
            
            {editMode && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<RestartAltIcon />}
                onClick={handleClearSchedule}
                disabled={saveInProgress}
                sx={{ ml: 2 }}
              >
                Clear Schedule
              </Button>
            )}
          </Grid>
        </Grid>
        
        {editMode && (
          <Chip 
            label="Edit Mode - Drag items to rearrange" 
            color="warning" 
            sx={{ mt: 1 }} 
          />
        )}
        
        {saveInProgress && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Saving changes...
            </Typography>
          </Box>
        )}
      </Box>
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          {/* Unassigned proteins pool */}
          <Grid item xs={12} sm={6} md={2}>
            <Paper 
              elevation={3}
              sx={{
                p: 2,
                height: '100%',
                minHeight: 400,
                backgroundColor: '#f0f0f0'
              }}
            >
              <Typography variant="h6" align="center" gutterBottom sx={{ borderBottom: '2px solid #000', pb: 1 }}>
                Unassigned
              </Typography>
              <Droppable droppableId="unassigned" isDropDisabled={!editMode}>
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ 
                      minHeight: '100px',
                      backgroundColor: 'white',
                      p: 1,
                      borderRadius: 1,
                      boxShadow: 'inset 0 0 5px rgba(0,0,0,0.1)',
                      overflow: 'auto',
                      maxHeight: '500px'
                    }}
                  >
                    {schedule.unassigned.map((protein, index) => (
                      <Draggable key={protein.id} draggableId={`${protein.id}`} index={index} isDragDisabled={!editMode}>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...(editMode ? provided.draggableProps : {})}
                            {...(editMode ? provided.dragHandleProps : {})}
                            sx={{
                              mb: 1,
                              backgroundColor: '#333',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: '#555',
                                transform: editMode ? 'scale(1.02)' : 'none',
                              },
                              transition: 'all 0.2s',
                              cursor: editMode ? 'grab' : 'pointer',
                              border: editMode ? '2px dashed rgba(255,255,255,0.5)' : 'none'
                            }}
                            onClick={() => handleOpenProductsDialog(protein)}
                          >
                            <CardContent sx={{ py: 1, '&:last-child': { pb: 1 }, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="body1">{protein.name}</Typography>
                              {protein.products && protein.products.length > 0 && (
                                <InfoIcon fontSize="small" />
                              )}
                            </CardContent>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Paper>
          </Grid>
          
          {/* Week days */}
          {renderDay('monday', 'Monday')}
          {renderDay('tuesday', 'Tuesday')}
          {renderDay('wednesday', 'Wednesday')}
          {renderDay('thursday', 'Thursday')}
          {renderDay('friday', 'Friday')}
          
          {/* Trash area - only visible in edit mode */}
          {editMode && (
            <Grid item xs={12}>
              <Droppable droppableId="trash">
                {(provided) => (
                  <Paper
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    elevation={2}
                    sx={{
                      p: 2,
                      mt: 2,
                      backgroundColor: '#ffeeee',
                      border: '2px dashed #ff5555',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minHeight: 100
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <DeleteIcon color="error" sx={{ fontSize: 30, mr: 1 }} />
                      <Typography variant="h6" color="error">
                        Drag here to remove from schedule
                      </Typography>
                    </Box>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
          )}
        </Grid>
      </DragDropContext>

      {/* Confirmation dialog for clearing schedule */}
      <Dialog
        open={confirmClearOpen}
        onClose={() => setConfirmClearOpen(false)}
      >
        <DialogTitle>Clear Production Schedule</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to clear the entire production schedule? All proteins will be moved to unassigned.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmClearOpen(false)}>Cancel</Button>
          <Button onClick={confirmClearSchedule} variant="contained" color="error">
            Clear Schedule
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Protein Products Dialog */}
      <Dialog
        open={productsDialogOpen}
        onClose={() => setProductsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{selectedProtein?.name} Products</span>
          <Button 
            variant="text" 
            color="inherit" 
            onClick={() => setProductsDialogOpen(false)}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </DialogTitle>
        <DialogContent dividers>
          {selectedProtein?.products && selectedProtein.products.length > 0 ? (
            <List>
              {selectedProtein.products.map((product, index) => (
                <React.Fragment key={product.sku}>
                  <ListItem>
                    <ListItemText 
                      primary={product.productname} 
                      secondary={`SKU: ${product.sku}`} 
                    />
                  </ListItem>
                  {index < selectedProtein.products.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography color="text.secondary" align="center" sx={{ py: 2 }}>
              No products assigned to this protein.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ProductionSchedule;