import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import authService from '../services/authService';

function AuthAlerts() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  
  useEffect(() => {
    const unsubscribe = authService.addListener((event) => {
      if (event.type === 'tokenRefreshError') {
        setMessage('Failed to refresh authentication. Please log in again.');
        setSeverity('error');
        setOpen(true);
      }
      else if (event.type === 'apiKeyFetchError') {
        setMessage('Failed to get API access. Please refresh the page.');
        setSeverity('error');
        setOpen(true);
      }
      else if (event.type === 'tokenExpired') {
        setMessage('Your session has expired. Please log in again.');
        setSeverity('warning');
        setOpen(true);
      }
    });
    
    return unsubscribe;
  }, []);
  
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <Alert severity={severity} onClose={() => setOpen(false)}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AuthAlerts;