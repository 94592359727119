import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Menu, 
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Paper,
  Box,
  Divider,
  Chip,
  Avatar,
  Tooltip
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { useAuth0 } from '@auth0/auth0-react';
import authService from '../services/authService';

const Header = ({ anchorEl, handleMenu, handleClose, handleLogout }) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isAuth0User, setIsAuth0User] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({
    accessToken: {
      expiresAt: null,
      timeRemaining: null
    },
    refreshToken: {
      expiresAt: null,
      timeRemaining: null
    }
  });
  const [serviceTokens, setServiceTokens] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuth0User(user.sub && user.sub.startsWith('auth0|'));

      // Check if the user has 'requirePasswordUpdate' set to true in metadata
      if (user['https://wms.neicha.com.au/app_metadata']?.requirePasswordUpdate === true) {
        // Force redirect to password reset
        //handleGeneratePasswordResetLink(true);
      }
    }
  }, [isAuthenticated, user]);

  // Fetch token info from Auth0 and localStorage
  const fetchTokenInfo = () => {
    try {
      // Get Auth0 token data
      const tokenKey = `@@auth0spajs@@::${window.authConfig?.clientId || "yFOdJ3ssdx5K5scm6uhvBcioH8OZ9672"}::${window.authConfig?.audience || "https://api.neicha.com.au/"}::openid profile email update:users read:users offline_access create:user_tickets access:wms access:bulk_system access:petfresh_backend access:qpilot`;
      const tokenDataStr = localStorage.getItem(tokenKey);
      
      if (!tokenDataStr) {
        setTokenInfo({
          accessToken: { expiresAt: null, timeRemaining: "Not available" },
          refreshToken: { expiresAt: null, timeRemaining: "Not available" }
        });
        return;
      }
      
      const tokenData = JSON.parse(tokenDataStr);
      const now = Math.floor(Date.now() / 1000);
      
      // Calculate access token expiration
      let accessExpiry = null;
      let accessRemaining = "Unknown";
      
      if (tokenData.body?.expires_in) {
        const decodedToken = parseJwt(tokenData.body.access_token);
        if (decodedToken && decodedToken.exp) {
          accessExpiry = new Date(decodedToken.exp * 1000);
          const remainingSecs = decodedToken.exp - now;
          
          if (remainingSecs > 0) {
            // Format time remaining with appropriate units
            if (remainingSecs > 86400) { // More than 24 hours
              const days = Math.floor(remainingSecs / 86400);
              const hours = Math.floor((remainingSecs % 86400) / 3600);
              accessRemaining = `${days}d ${hours}h`;
            } else if (remainingSecs > 3600) { // More than 60 minutes
              const hours = Math.floor(remainingSecs / 3600);
              const mins = Math.floor((remainingSecs % 3600) / 60);
              accessRemaining = `${hours}h ${mins}m`;
            } else {
              const mins = Math.floor(remainingSecs / 60);
              const secs = remainingSecs % 60;
              accessRemaining = `${mins}m ${secs}s`;
            }
          } else {
            accessRemaining = "Expired";
          }
        }
      }
      
      // Calculate refresh token expiration (typically 30 days)
      let refreshExpiry = null;
      let refreshRemaining = "Unknown";
      
      if (tokenData.body?.refresh_token) {
        // Decode refresh token if possible (some providers encode expiry)
        try {
          const decodedRefresh = parseJwt(tokenData.body.refresh_token);
          if (decodedRefresh && decodedRefresh.exp) {
            refreshExpiry = new Date(decodedRefresh.exp * 1000);
            const remainingSecs = decodedRefresh.exp - now;
            
            if (remainingSecs > 0) {
              const days = Math.floor(remainingSecs / (24 * 3600));
              const hours = Math.floor((remainingSecs % (24 * 3600)) / 3600);
              refreshRemaining = `${days}d ${hours}h`;
            } else {
              refreshRemaining = "Expired";
            }
          } else {
            // Default to 30 days from now if can't determine
            refreshExpiry = new Date(Date.now() + 30 * 24 * 3600 * 1000);
            refreshRemaining = "~30 days (estimated)";
          }
        } catch (e) {
          refreshExpiry = new Date(Date.now() + 30 * 24 * 3600 * 1000);
          refreshRemaining = "~30 days (estimated)";
        }
      }
      
      setTokenInfo({
        accessToken: {
          expiresAt: accessExpiry,
          timeRemaining: accessRemaining,
          token: tokenData.body?.access_token?.substring(0, 15) + "..." || "Not available"
        },
        refreshToken: {
          expiresAt: refreshExpiry,
          timeRemaining: refreshRemaining,
          exists: !!tokenData.body?.refresh_token
        }
      });
      
      // Get service-specific API tokens
      const apiKeys = authService.getApiKeys();
      setServiceTokens(apiKeys);
      
    } catch (error) {
      console.error("Error fetching token info:", error);
      setTokenInfo({
        accessToken: { expiresAt: null, timeRemaining: "Error" },
        refreshToken: { expiresAt: null, timeRemaining: "Error" }
      });
    }
  };
  
  // Parse JWT token
  const parseJwt = (token) => {
    if (!token) return null;
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Error parsing JWT:", e);
      return null;
    }
  };

  const handleOpenAccountDialog = () => {
    fetchTokenInfo(); // Refresh token info
    setAccountDialogOpen(true);
    handleClose(); // Close the menu
  };

  const handleGeneratePasswordResetLink = async (forceRedirect = false) => {
    setLoading(true);
    try {
      const accessToken = authService.getApiKey("wms");

      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch('https://wms-api.neicha.com.au/create-password-reset-ticket/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user_id: user.sub,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const passwordResetUrl = data.ticket;

        if (forceRedirect) {
          window.location.href = passwordResetUrl;
        } else {
          alert(`Password reset link generated. You will be redirected to: ${passwordResetUrl}`);
          window.location.href = passwordResetUrl;
        }
      } else {
        const errorData = await response.json();
        alert(`Failed to generate password reset link: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error generating password reset link:', error);
      alert('Error generating password reset link');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AppBar position="fixed" style={{ zIndex: 1300 }}>
        <Toolbar>
          <Logo style={{ height: 40, marginRight: 16 }} />
          <Typography
            variant="h6"
            style={{ color: 'white' }}
            noWrap
            sx={{
              display: {
                xs: 'none', // hide on extra-small screens
                sm: 'block', // show on small screens and up
              },
            }}
          >
            Warehouse Management System
          </Typography>
          <Typography
            variant="h6"
            style={{ color: 'white' }}
            noWrap
            sx={{
              display: {
                xs: 'block', // show on extra-small screens
                sm: 'none', // hide on small screens and up
              },
            }}
          >
            WMS
          </Typography>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{ color: 'white' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem disabled>Welcome {user?.name || 'User'}</MenuItem>
              <MenuItem onClick={handleOpenAccountDialog}>Account Details</MenuItem>
              {isAuth0User && (
                <MenuItem onClick={handleGeneratePasswordResetLink} disabled={loading}>
                  Reset Password
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      
      {/* Account Details Dialog */}
      <Dialog 
        open={accountDialogOpen} 
        onClose={() => setAccountDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Account Details
          <IconButton
            aria-label="close"
            onClick={() => setAccountDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <span>✕</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {user && (
            <Grid container spacing={3}>
              {/* User Profile Section */}
              <Grid item xs={12}>
                <Paper 
                  elevation={2} 
                  sx={{ 
                    p: 3, 
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'center', sm: 'flex-start' },
                    gap: 3
                  }}
                >
                  <Avatar 
                    src={user.picture} 
                    alt={user.name}
                    sx={{ width: 80, height: 80 }}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h5">{user.name}</Typography>
                    <Typography variant="body1" color="text.secondary">{user.email}</Typography>
                    <Box sx={{ mt: 1 }}>
                      <Chip 
                        label={user.email_verified ? "Email Verified" : "Email Not Verified"}
                        color={user.email_verified ? "success" : "warning"}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <Chip 
                        label={isAuth0User ? "Auth0 Account" : "Database Account"}
                        color="info"
                        size="small"
                      />
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              
              {/* Token Information Section */}
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>Authentication Status</Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography variant="subtitle1" fontWeight="bold">Access Token</Typography>
                          {tokenInfo.accessToken.token ? (
                            <Tooltip title={tokenInfo.accessToken.expiresAt ? `Expires: ${tokenInfo.accessToken.expiresAt.toLocaleString()}` : "Token exists but expiration unknown"} arrow>
                              <Chip 
                                icon={<span style={{ color: 'green' }}>✓</span>} 
                                label="Valid" 
                                size="small" 
                                color="success" 
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="No valid token found" arrow>
                              <Chip 
                                icon={<span style={{ color: 'red' }}>✗</span>} 
                                label="Missing" 
                                size="small" 
                                color="error" 
                                variant="outlined"
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="body2">
                          <strong>Expires:</strong> {tokenInfo.accessToken.expiresAt ? 
                            tokenInfo.accessToken.expiresAt.toLocaleString() : 
                            'Unknown'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Time Remaining:</strong> {tokenInfo.accessToken.timeRemaining}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, wordBreak: 'break-all' }}>
                          <strong>Token:</strong> {tokenInfo.accessToken.token || 'Not available'}
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                          <Button 
                            variant="outlined" 
                            size="small"
                            onClick={() => {
                              authService.refreshAccessToken();
                              setTimeout(fetchTokenInfo, 2000); // Refresh data after token refresh
                            }}
                          >
                            Refresh Token Now
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <Paper variant="outlined" sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography variant="subtitle1" fontWeight="bold">Refresh Token</Typography>
                          {tokenInfo.refreshToken.exists ? (
                            <Tooltip title={tokenInfo.refreshToken.expiresAt ? `Expires: ${tokenInfo.refreshToken.expiresAt.toLocaleString()}` : "Token exists but expiration unknown"} arrow>
                              <Chip 
                                icon={<span style={{ color: 'green' }}>✓</span>} 
                                label="Valid" 
                                size="small" 
                                color="success" 
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="No valid refresh token found" arrow>
                              <Chip 
                                icon={<span style={{ color: 'red' }}>✗</span>} 
                                label="Missing" 
                                size="small" 
                                color="error" 
                                variant="outlined"
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="body2">
                          <strong>Expires:</strong> {tokenInfo.refreshToken.expiresAt ? 
                            tokenInfo.refreshToken.expiresAt.toLocaleString() : 
                            'Typically 30 days from last login'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Time Remaining:</strong> {tokenInfo.refreshToken.timeRemaining}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <strong>Status:</strong> {tokenInfo.refreshToken.exists ? 
                            'Available (hidden for security)' : 
                            'Not available'}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              
              {/* API Tokens Section */}
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>API Service Tokens</Typography>
                  <Grid container spacing={2}>
                    {Object.keys(serviceTokens).length > 0 ? (
                      Object.entries(serviceTokens).map(([service, token]) => {
                        // Decode token to get expiration if it exists
                        const decodedToken = token ? parseJwt(token) : null;
                        const hasValidToken = token && decodedToken?.exp;
                        
                        // Format expiration date in AWST (UTC+8)
                        let expirationDate = null;
                        let timeRemaining = "";
                        
                        if (hasValidToken) {
                          // Convert to AWST timezone (UTC+8)
                          const expiryMs = decodedToken.exp * 1000;
                          expirationDate = new Date(expiryMs);
                          const awstExpirationDate = new Date(expiryMs + (8 * 60 * 60 * 1000 - expirationDate.getTimezoneOffset() * 60 * 1000));
                          
                          // Format date for display
                          const formattedDate = awstExpirationDate.toLocaleDateString('en-AU', { 
                            day: '2-digit', 
                            month: '2-digit', 
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                          });
                          
                          // Calculate time remaining
                          const now = Date.now();
                          const diffMs = expiryMs - now;
                          
                          if (diffMs > 0) {
                            const hoursRemaining = Math.floor(diffMs / (1000 * 60 * 60));
                            const minutesRemaining = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
                            timeRemaining = `in ${hoursRemaining} hours and ${minutesRemaining} mins`;
                          } else {
                            timeRemaining = "Expired";
                          }
                          
                          expirationDate = `Expires: ${formattedDate} AWST (${timeRemaining})`;
                        }
                        
                        return (
                          <Grid item xs={12} key={service}>
                            <Paper variant="outlined" sx={{ p: 2 }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                                  {service.replace('_', ' ')}
                                </Typography>
                                {hasValidToken ? (
                                  <Tooltip title={expirationDate || "Token exists but expiration unknown"} arrow>
                                    <Chip 
                                      icon={<span style={{ color: 'green' }}>✓</span>} 
                                      label="Valid" 
                                      size="small" 
                                      color="success" 
                                      variant="outlined"
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="No valid token found" arrow>
                                    <Chip 
                                      icon={<span style={{ color: 'red' }}>✗</span>} 
                                      label="Missing" 
                                      size="small" 
                                      color="error" 
                                      variant="outlined"
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                              <Typography variant="body2" sx={{ wordBreak: 'break-all', opacity: 0.7, mt: 1 }}>
                                {token ? token.substring(0, 20) + '...' : 'Not available'}
                              </Typography>
                            </Paper>
                          </Grid>
                        );
                      })
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">
                          No service tokens available
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              
              {/* User Metadata Section */}
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>User Metadata</Typography>
                  <pre style={{ 
                    whiteSpace: 'pre-wrap', 
                    wordBreak: 'break-word',
                    background: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '4px',
                    maxHeight: '200px',
                    overflow: 'auto'
                  }}>
                    {JSON.stringify(user, null, 2)}
                  </pre>
                </Paper>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAccountDialogOpen(false)}>
            Close
          </Button>
          <Button 
            variant="contained" 
            onClick={() => {
              fetchTokenInfo();
            }}
          >
            Refresh Info
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Header;