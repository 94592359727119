import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Select, CircularProgress, Tooltip, Chip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListAltIcon from '@mui/icons-material/ListAlt';
import axios from 'axios';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import authService from '../services/authService';
import ClickCollectFlow from '../assets/ClickCollectFlow.png';

const ClickCollectOrders = ({ stores }) => {
  const navigate = useNavigate();
  const [store, setStore] = useState(() => {
    try {
      const storedValue = localStorage.getItem('selectedStore');
      return storedValue ? JSON.parse(storedValue).storename : '';
    } catch (error) {
      return '';
    }
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filter out "Warehouse" and add "All" manually
  const filteredStores = [{ id: 'all', storename: 'All' }, ...stores.filter(s => s.storename !== 'Warehouse')];

  const translateOrderStatus = (status) => {
    switch (status) {
      case 'prod-scheduled':
        return 'Awaiting Production';
      case 'pending-fulfilment':
        return 'Not Actioned Yet';
      case 'ready-to-collect':
        return 'Ready to Collect';
      case 'waiting-for-store-transfer':
        return 'Pending Transfer to Store';
      case 'pickup':
        return 'Awaiting Action';
      case 'packed':
        return 'Ready to Collect';
      case 'click-collect-esc':
        return 'Escalated to Warehouse';
      case 'store-transfer':
        return 'Awaiting Store Transfer';
      case 'ready-collect':
        return 'Ready to Collect';
      default:
        return status;
    }
  };  

  const getStatusColor = (status) => {
    switch (status) {
      case 'Ready to Collect':
        return 'success';
      case 'Not Actioned Yet':
        return 'error';
      case 'Awaiting Production':
        return 'warning';
      case 'Pending Transfer to Store':
        return 'info';
      case 'Awaiting Action':
        return 'warning';
      case 'Escalated to Warehouse':
        return 'error';
      case 'Awaiting Store Transfer':
        return 'info';
      default:
        return 'default';
    }
  };

  const formatDate = (dateString) => {
    try {
      return format(parseISO(dateString), 'dd/MM/yy');
    } catch (error) {
      return dateString;
    }
  };

  useEffect(() => {
    if (store) {
      setLoading(true);
      const fetchOrders = async () => {
        try {
          const token = authService.getApiKey("petfresh_backend"); // Get the token from authService
          const response = await axios.get(
            `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${encodeURIComponent(store === 'Warehouse' ? 'All' : store)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Add the token to the Authorization header
              },
            }
          );
          setOrders(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching orders:', error);
          setLoading(false);
        }
      };
  
      fetchOrders();
    }
  }, [store]);  

  const handleStoreChange = (event) => {
    let selectedStore = event.target.value;
    
    // Set store to "All" if "Warehouse" is selected
    if (selectedStore === 'Warehouse') {
      selectedStore = 'All';
    }

    setStore(selectedStore);
    const storeObject = stores.find(s => s.storename === selectedStore) || { storename: 'All' };
    localStorage.setItem('selectedStore', JSON.stringify(storeObject));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Typography variant="h4" sx={{marginBottom:"0.5em"}}>Click & Collect Orders</Typography>
          <img src={ClickCollectFlow} alt="Click and Collect Flow" style={{ height: "100%", width: "100%" }} />
      
      <Grid container alignItems="center" spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={8}>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            value={store}
            onChange={handleStoreChange}
            displayEmpty
            fullWidth
            sx={{ background: 'white' }}
          >
            <MenuItem value="">Select Store</MenuItem>
            {filteredStores.map((store) => (
              <MenuItem key={store.id} value={store.storename}>
                {store.storename}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      {loading ? (
        <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <TableContainer sx={{ mt: 2, borderRadius: "4px" , boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;" }}>
          <Table>
            <TableHead>
              <TableRow style={{background:"rgb(228 228 228)"}}>
                <TableCell style={{fontWeight:"bold"}}>Order #</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Customer Name</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Order Date</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Store Location</TableCell>
                <TableCell style={{fontWeight:"bold"}}>Time Since Order</TableCell>
                <TableCell align="center" style={{fontWeight:"bold"}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{background:"white"}}>
              {orders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Click and Collects at this time.
                  </TableCell>
                </TableRow>
              ) : (
                orders.map((order) => {
                  const statusText = translateOrderStatus(order.current_status);
                  const statusColor = getStatusColor(statusText);
                  return (
                    <TableRow key={order.order_id}>
                      <TableCell>{order.order_id}</TableCell>
                      <TableCell>{`${order.first_name} ${order.last_name}`}</TableCell>
                      <TableCell>{formatDate(order.order_date)}</TableCell>
                      <TableCell>
                        <Chip 
                          label={statusText}
                          color={statusColor}
                          size="small"
                          sx={{ fontWeight: 'medium' }}
                        />
                      </TableCell>
                      <TableCell>{order.store_location || ''}</TableCell>
                      <TableCell>
                        {formatDistanceToNow(parseISO(order.order_date), { addSuffix: true })}
                        <br />
                        <small>
                          Last Update: 
                          {order.order_notes.length > 0
                            ? formatDistanceToNow(parseISO(order.order_notes[0].date), { addSuffix: true })
                            : 'No updates'}
                        </small>
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="View Order Details">
                          <VisibilityIcon 
                            color="primary" 
                            sx={{ cursor: 'pointer', mr: 2 }}
                            onClick={() => navigate(`/view-click-collect-order/${order.order_id}`, { state: { order } })}
                          />
                        </Tooltip>
                        <Tooltip title={
                          <div>
                            <Typography variant="subtitle2">Order Items:</Typography>
                            {order.order_items.map((item, idx) => (
                              <Typography key={idx} variant="body2">
                                {item.title} - {item.qty}
                              </Typography>
                            ))}
                          </div>
                        }>
                          <ListAltIcon 
                            color="action" 
                            sx={{ cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />
    </>
  );
};

export default ClickCollectOrders;