// src/components/ViewClickCollectOrder.js

import React from 'react';
import { Paper} from '@mui/material';



const ExpressDelivery = ({ style }) => {
  return (
    <div style={style}>
      <Paper style={{ padding: '20px' }}>
          <h1>Express Delivery Page - More info to come!</h1>
      </Paper>
    </div>
  );
};

export default ExpressDelivery;
