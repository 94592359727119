import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Button, Grid, Typography, TextField, Select, MenuItem, Table, TableBody, 
  TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, 
  Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, IconButton, 
  FormControl, FormLabel, Snackbar, SnackbarContent, Tooltip, FormControlLabel, 
  Switch, Box, Chip, InputLabel, Backdrop, Drawer, ListItem, ListItemText,
  Card, CardContent, CardActions, styled, Alert, AlertTitle
} from '@mui/material';
import { List as MuiList } from '@mui/material';

// Import Timeline components from @mui/lab instead
import {
  Timeline, TimelineItem, TimelineSeparator, TimelineConnector,
  TimelineContent, TimelineDot
} from '@mui/lab';

// Icons imports
import { Close, ArrowBack, Delete, CheckCircle, Cancel, Remove } from '@mui/icons-material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { Add, Person, Email, Phone, Badge, Refresh, Visibility } from '@mui/icons-material';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon } from '@mui/icons-material';

// Other imports
import axios from 'axios';
import moment from 'moment-timezone';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { FixedSizeList as List } from 'react-window';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import authService from '../services/authService';

// Import aliases for Material UI components
import { 
  Dialog as MuiDialog, 
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent, 
  DialogActions as MuiDialogActions, 
  FormControl as MuiFormControl, 
  Select as MuiSelect, 
  MenuItem as MuiMenuItem 
} from '@mui/material';

// Custom scrollable calendar component using react-big-calendar
const ScrollingCalendar = ({ selectedDate, onDateSelect, minDate }) => {
  const locales = {
    'en-US': require('date-fns/locale/en-US')
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // State to track current month view
  const [currentDate, setCurrentDate] = useState(new Date());
  const containerRef = useRef(null);
  
  useEffect(() => {
    // Reset scroll position when component mounts
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, []);

  // Custom day cell renderer
  const CustomDayPropGetter = (date) => {
    const isMonday = moment(date).day() === 1;
    const isPast = moment(date).isBefore(moment(), 'day');
    
    let style = {};
    
    // Highlight Mondays
    if (isMonday && !isPast) {
      style.backgroundColor = '#e6eec9';
      style.borderRadius = '4px';
      style.cursor = 'pointer';
    }
    
    // Gray out past dates or non-Mondays
    if (isPast || !isMonday) {
      style.color = '#bbb';
      style.backgroundColor = '#f5f5f5';
      style.cursor = 'not-allowed';
    }
    
    // Highlight selected date
    if (selectedDate && moment(date).isSame(selectedDate, 'day')) {
      style.backgroundColor = '#9ba03b';
      style.color = 'white';
      style.fontWeight = 'bold';
    }
    
    return {
      style,
      disabled: isPast || !isMonday
    };
  };

  // Handle wheel event for month scrolling
  const handleWheel = (e) => {
    // Prevent default scrolling behavior
    e.preventDefault();
    
    // Clone current date to avoid direct mutation
    const newDate = new Date(currentDate);
    
    // Change month based on scroll direction
    if (e.deltaY > 0) {
      // Scrolling down - move forward one month
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (e.deltaY < 0) {
      // Scrolling up - move back one month (if not before current month)
      const oneMonthBack = new Date(currentDate);
      oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
      
      // Only allow scrolling back if it's not before current month
      if (!minDate || oneMonthBack >= minDate) {
        newDate.setMonth(newDate.getMonth() - 1);
      }
    }
    
    setCurrentDate(newDate);
  };

  return (
    <Box 
      ref={containerRef}
      onWheel={handleWheel}
      sx={{ 
        height: '500px',
        position: 'relative',
        overflow: 'hidden' // Hide scrollbars but keep scroll events
      }}
    >
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 1, 
          textAlign: 'center', 
          backgroundColor: '#f5f5f5',
          padding: '8px',
          borderRadius: '4px'
        }}
      >
        {format(currentDate, 'MMMM yyyy')}
      </Typography>
      <Calendar
        localizer={localizer}
        events={[]}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 420 }}
        dayPropGetter={CustomDayPropGetter}
        onSelectSlot={(slotInfo) => {
          const clickedDate = slotInfo.start;
          if (minDate && clickedDate < minDate) {
            return;
          }
          onDateSelect(clickedDate);
        }}
        selectable={true}
        views={['month']}
        view="month"
        date={currentDate}
        toolbar={false}
        onNavigate={() => {}} // Prevent default navigation
      />
      <Box 
        sx={{ 
          position: 'absolute',
          bottom: 10,
          width: '100%',
          textAlign: 'center',
          color: 'text.secondary',
          fontSize: '0.8rem'
        }}
      >
        Scroll to navigate between months
      </Box>
    </Box>
  );
};

const BulkScheduleDetails = ({ id: propId, hideBackButton = false, style }) => {

    const { id: paramId } = useParams();
    const navigate = useNavigate();
    // Final ID to use (from prop if provided, else from route)
    const scheduledOrderId = propId || paramId;

    const [schedule, setSchedule] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [cycleModalOpen, setCycleModalOpen] = useState(false);
    const [eventLogsModalOpen, setEventLogsModalOpen] = useState(false);
    const [processNowModalOpen, setProcessNowModalOpen] = useState(false);
    const [processingHistory, setProcessingHistory] = useState([]);
    const [eventLogs, setEventLogs] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });
    const [customerOrders, setCustomerOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const [productQuantities, setProductQuantities] = useState({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);

    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [storeDialogOpen, setStoreDialogOpen] = useState(false);
    const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [loadingAllProducts, setLoadingAllProducts] = useState(false);

    // Add these state variables
    const [addingProduct, setAddingProduct] = useState(false);
    const [updatingSchedule, setUpdatingSchedule] = useState(false);
    const [nextOccurrenceDialogOpen, setNextOccurrenceDialogOpen] = useState(false);
    const [selectedOccurrenceDate, setSelectedOccurrenceDate] = useState(null);
    const [frequencyDialogOpen, setFrequencyDialogOpen] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(1);

    // Add these state variables to your component
    const [cycleDetailsDrawerOpen, setCycleDetailsDrawerOpen] = useState(false);
    const [selectedCycle, setSelectedCycle] = useState(null);
    const [cycleDetails, setCycleDetails] = useState(null);
    const [loadingCycleDetails, setLoadingCycleDetails] = useState(false);
    const [processingRetry, setProcessingRetry] = useState(false);
    const [statusUpdateDialogOpen, setStatusUpdateDialogOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    // First, add a new state variable to track deletion in progress
    const [deletingProduct, setDeletingProduct] = useState(false);

    const fetchProcessingHistory = async () => {
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}/ProcessingCycles`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setProcessingHistory(response.data.items);
        } catch (error) {
            console.error('Failed to fetch processing history', error);
        }
    };

	const relativeWeekLabel = (dateUtc) => {
		if (!dateUtc) return 'N/A';
	
		// Set "now" to the start of the current ISO week (i.e. Monday)
		const now = moment().startOf('isoWeek');
	
		// Set "target" to the start of its ISO week
		const target = moment(dateUtc).startOf('isoWeek');
	
		const diffInWeeks = target.diff(now, 'weeks'); // can be negative
	
		if (diffInWeeks === 0) return 'This Week';       // same Monday-Sunday window
		if (diffInWeeks === 1) return 'Next Week';
		if (diffInWeeks > 1) return `In ${diffInWeeks} Weeks`;
	
		if (diffInWeeks === -1) return 'Last Week';
		if (diffInWeeks < -1) return `${Math.abs(diffInWeeks)} Weeks Ago`;
	
		return 'N/A';
	};
	
	// Function to get status color
	const getStatusColor = (status) => {
		switch (status) {
			case 'Active':
				return 'green';
			case 'Paused':
				return 'orange';
			case 'Failed':
				return 'red';
			case 'Deleted':
				return 'grey';
			default:
				return '#A020F0';
		}
	};
	
	// Function to handle next occurrence update
	const handleNextOccurrenceUpdate = () => {
		if (!selectedOccurrenceDate) return;
		
		// Convert selected date to 1AM AWST in UTC
		const utcDate = moment.tz(selectedOccurrenceDate, "Australia/Perth")
			.set({
				hour: 1,  // 1AM AWST
				minute: 0,
				second: 0,
				millisecond: 0
			})
			.utc()
			.toISOString();
		
		setSchedule({ ...schedule, nextOccurrenceUtc: utcDate });
		setIsChanged(true);
		setNextOccurrenceDialogOpen(false);
	};
	
	// Function to handle frequency update
	const handleFrequencyUpdate = () => {
		setUpdatingSchedule(true);
		setSchedule({ 
			...schedule, 
			frequency: selectedFrequency,
			frequencyType: "Week" 
		});
		setIsChanged(true);
		setFrequencyDialogOpen(false);
		setUpdatingSchedule(false);
	};

    const fetchEventLogs = async () => {
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const response = await axios.get(`https://api.qpilot.cloud/api/ScheduledOrders/${scheduledOrderId}/EventLogs`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setEventLogs(response.data);
        } catch (error) {
            console.error('Failed to fetch event logs', error);
        }
    };

    const fetchSchedule = async () => {
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setSchedule(response.data);
            await fetchProcessingHistory();
            await fetchEventLogs();
        } catch (error) {
            setError('Failed to fetch schedule');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomerOrders = async (customerId) => {
        try {
            setOrdersLoading(true);
            const pfresh_token = authService.getApiKey("bulk_system");
            const response = await axios.get(`https://order.petfresh.com.au/wp-json/wc/v3/orders?customer=${customerId}`, {
                headers: {
                    'Authorization': `Bearer ${pfresh_token}`
                }
            });
            setCustomerOrders(response.data);
        } catch (error) {
            console.error('Failed to fetch customer orders', error);
        } finally {
            setOrdersLoading(false);
        }
    };

    useEffect(() => {
        if (scheduledOrderId) {
            fetchSchedule();
        }
    }, [scheduledOrderId]);

    const fetchProducts = async (query) => {
        setLoadingProducts(true);
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/Products?search=${query}`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setProducts(response.data.items);
        } catch (error) {
            console.error('Failed to fetch products', error);
        } finally {
            setLoadingProducts(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleFrequencyChange = (event) => {
        setSchedule({ ...schedule, frequency: event.target.value });
        setIsChanged(true);
    };

    const handleStoreChange = (event) => {
        setSchedule({ ...schedule, metadata: { ...schedule.metadata, collection_store: event.target.value } });
        setIsChanged(true);
    };

    const handleFieldChange = (field, value) => {
        setSchedule({ ...schedule, [field]: value });
        setIsChanged(true);
    };

    const handleCustomerFieldChange = (field, value) => {
        setSchedule({ ...schedule, customer: { ...schedule.customer, [field]: value } });
        setIsChanged(true);
    };

    const handleSearchSubmit = () => {
        fetchProducts(searchQuery);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
        fetchProducts('');
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setProductQuantities({}); // Reset quantities
        setSearchQuery(''); // Clear search query
        setProducts([]); // Clear product list
    };

    const handleAddProduct = async (product) => {
        const quantity = productQuantities[product.id] || 1; // Get the quantity from state or default to 1
        if (isChanged) {
            const confirmation = window.confirm("There are unsaved changes on the scheduled order. Would you like to save these changes before adding this item?");

            // If the user clicks "Cancel", return early and do not add the product
            if (!confirmation) {
                return; // Exit early if user cancels
            }

            // Save changes if the user clicks "OK"
            await handleSaveChanges();
        }

        try {
            const qpilot_token = authService.getApiKey("qpilot");

            const payload = {
                scheduledOrderItems: [
                    {
                        productId: product.id.toString(),
                        quantity: quantity // Pass the correct quantity here
                    }
                ],
                scheduledOrderId: schedule.id
            };

            await axios.post(`https://api.qpilot.cloud/Sites/2633/ScheduledOrderItems`, payload, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`,
                    'Content-Type': 'application/json',
                }
            });

            setSnackbar({ open: true, message: 'Product added successfully', type: 'success' });
            fetchSchedule(); // Refresh schedule after adding product
        } catch (error) {
            setSnackbar({ open: true, message: `Failed to add product: ${error.message}`, type: 'error' });
        } finally {
            handleCloseModal();
        }
    };

    const handleDeleteProduct = async () => {
        setDeletingProduct(true); // Show the deletion overlay
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            await axios.delete(`https://api.qpilot.cloud/Sites/2633/ScheduledOrderItems/${productToDelete.id}`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`,
                },
            });

            setSnackbar({ open: true, message: 'Product deleted successfully', type: 'success' });
            fetchSchedule();  // Refresh the schedule after deletion
            setDeleteModalOpen(false);
        } catch (error) {
            setSnackbar({ open: true, message: `Failed to delete product: ${error.message}`, type: 'error' });
        } finally {
            setDeletingProduct(false); // Hide the deletion overlay
        }
    };

    const handleOpenDeleteModal = (product) => {
        setProductToDelete(product);
        setDeleteModalOpen(true);
    };

    const handleQuantityChange = (index, quantity) => {
        const updatedItems = [...schedule.scheduledOrderItems];
        updatedItems[index].quantity = quantity;
        setSchedule({ ...schedule, scheduledOrderItems: updatedItems });
        setIsChanged(true);
    };

    const handleModalQuantityChange = (productId, quantity) => {
        setProductQuantities({ ...productQuantities, [productId]: quantity });
    };


    const handleMaxCyclesChange = (index, maxCycles) => {
        const updatedItems = [...schedule.scheduledOrderItems];
        updatedItems[index].maxCycles = maxCycles;
        setSchedule({ ...schedule, scheduledOrderItems: updatedItems });
        setIsChanged(true);
    };
    const handleMaxCyclesToggle = (index, isChecked) => {
        const updatedItems = [...schedule.scheduledOrderItems];
        updatedItems[index].maxCycles = isChecked ? 1 : null;
        setSchedule({ ...schedule, scheduledOrderItems: updatedItems });
        setIsChanged(true);
    };

    const handleProcessNow = async () => {
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            await axios.post(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}/Retry`, {}, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setProcessNowModalOpen(false);
            setSnackbar({ open: true, message: 'Processing initiated', type: 'success' });
            await fetchProcessingHistory();
        } catch (error) {
            setSnackbar({ open: true, message: `Processing failed: ${error.message}`, type: 'error' });
        }
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;  // The date selected by the user (YYYY-MM-DD)

        // Convert the date to ISO format and set the time to "00:00:00" in UTC
        const isoFormattedDate = moment.utc(selectedDate).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        }).toISOString();

        // Update the schedule with the correctly formatted next occurrence date
        setSchedule({ ...schedule, nextOccurrenceUtc: isoFormattedDate });
        setIsChanged(true);
    };

    const copyScheduleId = () => {
        if (!schedule) return;
        navigator.clipboard.writeText(String(schedule.id));
        setSnackbar({ open: true, message: 'Schedule ID copied!', type: 'success' });
    };


    const handleHistoryModalOpen = () => {
        fetchCustomerOrders(schedule.customer.id);
        setHistoryModalOpen(true);
    };

    const handleHistoryModalClose = () => setHistoryModalOpen(false);
    const handleCycleModalOpen = () => setCycleModalOpen(true);
    const handleCycleModalClose = () => setCycleModalOpen(false);
    const handleEventLogsModalOpen = () => setEventLogsModalOpen(true);
    const handleEventLogsModalClose = () => setEventLogsModalOpen(false);

    const handleSaveChanges = async () => {
        setUpdatingSchedule(true);
        setSnackbar({ open: true, message: 'Updating Schedule...', type: 'info' });

        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const pfresh_token = authService.getApiKey("bulk_system");

            await axios.put(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}`, schedule, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`,
                    'Content-Type': 'application/json',
                }
            });

            await axios.post('https://order.petfresh.com.au/wp-json/wc/v3/update-user-data/', {
                user_id: schedule.customer.id,
                first_name: schedule.customer.firstName,
                last_name: schedule.customer.lastName,
                email: schedule.customer.email,
                phone: schedule.customer.phoneNumber,
            }, {
                headers: {
                    'Authorization': `Bearer ${pfresh_token}`,
                    'Content-Type': 'application/json',
                }
            });

            setSnackbar({ open: true, message: 'User Updated in Qpilot\nUser Updated in Pet Fresh System', type: 'success' });
            setIsChanged(false); // Remove save button once the order has been updated
            await fetchSchedule();
        } catch (error) {
            console.error('Update Failed:', error);
            setSnackbar({ open: true, message: `Update Failed: ${error.message}`, type: 'error' });
        } finally {
            setUpdatingSchedule(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', type: '' });
    };

    const fetchAllProducts = async () => {
        setLoadingAllProducts(true);
        try {
            const qpilot_token = authService.getApiKey("qpilot");
            const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/Products`, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`
                }
            });
            setAllProducts(response.data.items);
        } catch (error) {
            console.error('Failed to fetch products', error);
            setSnackbar({ open: true, message: 'Failed to load products', type: 'error' });
        } finally {
            setLoadingAllProducts(false);
        }
    };

    const handleOpenProductDialog = () => {
        setAddProductDialogOpen(true);
        fetchAllProducts();
    };

    const handleAddProductFromCard = async (product) => {
        try {
            setAddingProduct(true); // Show spinner
            const qpilot_token = authService.getApiKey("qpilot");

            const payload = {
                scheduledOrderItems: [
                    {
                        productId: product.id.toString(),
                        quantity: 5 // Auto-add 5 quantity
                    }
                ],
                scheduledOrderId: schedule.id
            };

            await axios.post(`https://api.qpilot.cloud/Sites/2633/ScheduledOrderItems`, payload, {
                headers: {
                    'Authorization': `Bearer ${qpilot_token}`,
                    'Content-Type': 'application/json',
                }
            });

            setSnackbar({ open: true, message: 'Product added successfully', type: 'success' });
            fetchSchedule(); // Refresh schedule after adding product
            setAddProductDialogOpen(false);
        } catch (error) {
            setSnackbar({ open: true, message: `Failed to add product: ${error.message}`, type: 'error' });
        } finally {
            setAddingProduct(false); // Hide spinner
        }
    };

    const AddProductRow = styled(TableRow)(({ theme }) => ({
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        height: '60px'
    }));

    // Add this function to get status color
    const getCycleStatusColor = (status) => {
      switch (status) {
        case 'Success':
          return 'green';
        case 'Failed':
          return 'red';
        case 'Processing':
          return 'blue';
        case 'Pending':
          return 'orange';
        default:
          return 'grey';
      }
    };

    // Add these functions to handle the cycle details
    const handleViewCycleDetails = async (cycle) => {
      setSelectedCycle(cycle);
      setCycleDetailsDrawerOpen(true);
      setLoadingCycleDetails(true);
      
      try {
        const qpilot_token = authService.getApiKey("qpilot");
        const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/ProcessingCycles/${cycle.id}/ProcessingLogs`, {
          headers: {
            'Authorization': `Bearer ${qpilot_token}`
          }
        });
        setCycleDetails(response.data);
      } catch (error) {
        console.error('Failed to fetch cycle details', error);
        setSnackbar({ open: true, message: `Error fetching cycle details: ${error.message}`, type: 'error' });
      } finally {
        setLoadingCycleDetails(false);
      }
    };

    // Function to handle retry
    const handleRetryCycle = async () => {
      setProcessingRetry(true);
      setSnackbar({ open: true, message: 'Processing retry...', type: 'info' });
      
      try {
        const qpilot_token = authService.getApiKey("qpilot");
        await axios.post(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}/Retry`, {}, {
          headers: {
            'Authorization': `Bearer ${qpilot_token}`
          }
        });
        
        // Begin polling for status changes
        await pollForStatusChange();
      } catch (error) {
        console.error('Failed to retry cycle', error);
        setSnackbar({ open: true, message: `Retry failed: ${error.message}`, type: 'error' });
        setProcessingRetry(false);
      }
    };

    // Function to poll for status changes
    const pollForStatusChange = async () => {
      let attempts = 0;
      const maxAttempts = 30; // 30 attempts * 5 seconds = 150 seconds max waiting time
      
      const poll = async () => {
        try {
          const qpilot_token = authService.getApiKey("qpilot");
          const response = await axios.get(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}`, {
            headers: {
              'Authorization': `Bearer ${qpilot_token}`
            }
          });
          
          if (response.data.status !== 'Processing' || attempts >= maxAttempts) {
            setSchedule(response.data);
            setProcessingRetry(false);
            await fetchProcessingHistory();
            setSnackbar({ 
              open: true, 
              message: response.data.status === 'Processing' 
                ? 'Processing is taking longer than expected. Please check back later.' 
                : 'Processing completed', 
              type: response.data.status === 'Processing' ? 'info' : 'success' 
            });
            return;
          }
          
          attempts++;
          setTimeout(poll, 5000);
        } catch (error) {
          console.error('Error polling for status', error);
          setProcessingRetry(false);
          setSnackbar({ open: true, message: `Error checking status: ${error.message}`, type: 'error' });
        }
      };
      
      await poll();
    };

    // Function to open status update dialog
    const handleStatusClick = () => {
      setSelectedStatus(schedule.status);
      setStatusUpdateDialogOpen(true);
    };

    // Function to update schedule status
    const handleStatusUpdate = async () => {
      setUpdatingSchedule(true);
      
      try {
        const qpilot_token = authService.getApiKey("qpilot");
        await axios.patch(`https://api.qpilot.cloud/Sites/2633/ScheduledOrders/${scheduledOrderId}`, {
          status: selectedStatus
        }, {
          headers: {
            'Authorization': `Bearer ${qpilot_token}`,
            'Content-Type': 'application/json'
          }
        });
        
        setSchedule({ ...schedule, status: selectedStatus });
        setSnackbar({ open: true, message: 'Status updated successfully', type: 'success' });
      } catch (error) {
        console.error('Failed to update status', error);
        setSnackbar({ open: true, message: `Failed to update status: ${error.message}`, type: 'error' });
      } finally {
        setStatusUpdateDialogOpen(false);
        setUpdatingSchedule(false);
      }
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div style={style}>
            <Grid container alignItems="center" spacing={2} style={{ marginBottom: '20px' }}>
                {/* Back Button */}
                <Grid item>
                    {!hideBackButton && (
                        <Button
                            style={{ background: "#9ba03b", color: "white" }}
                            startIcon={<ArrowBack />}
                            onClick={() => navigate(-1)}
                            size="small"
                        >
                            Back
                        </Button>
                    )}
                </Grid>

                {/* Edit Bulk Schedule Heading */}
                <Grid item style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6">Edit Bulk Schedule #{schedule.id}</Typography>
                    <Box sx={{ ml: 2, display: 'inline-block' }}>
                      <Tooltip title="Click to update status">
                        <Chip 
                          label={schedule.status} 
                          onClick={handleStatusClick}
                          sx={{ 
                            backgroundColor: getStatusColor(schedule.status), 
                            color: 'white',
                            fontWeight: 'bold',
                            cursor: 'pointer'
                          }} 
                        />
                      </Tooltip>
                    </Box>
                </Grid>

                {/* Action Buttons */}
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleHistoryModalOpen} size="small">
                                Customer Order History
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleCycleModalOpen} size="small">
                                Cycle History
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleEventLogsModalOpen} size="small">
                                Event Logs
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => setProcessNowModalOpen(true)} size="small">
                                Process Now
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Alert Section */}
            <Box sx={{ mb: 3 }}>
                {/* Payment Method Alert */}
                {(!schedule.paymentMethod || !schedule.paymentMethod.description) && (
                    <Alert 
                        severity="error" 
                        sx={{ mb: 2 }}
                        variant="filled"
                    >
                        <AlertTitle>Missing Payment Method</AlertTitle>
                        There is no payment method set on this schedule and will fail on the next occurrence.
                    </Alert>
                )}
                
                {/* Empty Schedule Alert */}
                {schedule.scheduledOrderItems.length === 0 && (
                    <Alert 
                        severity="error" 
                        sx={{ mb: 2 }}
                        variant="filled"
                    >
                        <AlertTitle>Empty Schedule</AlertTitle>
                        This schedule doesn't contain any products and will fail on the next occurrence.
                    </Alert>
                )}
                
                {/* Failed Processing Cycle Alert */}
                {processingHistory.length > 0 && processingHistory[0].status === 'Failed' && (
                    <Alert 
                        severity="warning" 
                        sx={{ mb: 2 }}
                        variant="filled"
                        action={
                            <Button 
                                color="inherit" 
                                size="small" 
                                onClick={handleCycleModalOpen}
                                sx={{ 
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    textDecoration: 'underline',
                                    my: 'auto'
                                }}
                            >
                                VIEW
                            </Button>
                        }
                    >
                        <AlertTitle>Processing Failed</AlertTitle>
                        The last processing cycle failed. You can retry this by clicking Cycle History.
                    </Alert>
                )}
            </Box>

            {/* "Your Plan" section */}
            <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={0}>
                    {/* Column 1: Plan (Schedule ID) */}
                    <Grid item xs={12} sm={6} md={2} sx={{ borderRight: '1px solid grey', paddingLeft: "10px" }}>
                        <Typography variant="caption" color="text.secondary">Schedule ID</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Tooltip title="Click to copy ID">
                                <Button
                                    variant="contained"
                                    sx={{ 
                                        color: "black", 
                                        border: "none", 
                                        boxShadow: "none", 
                                        padding: 0, 
                                        background: "none", 
                                        "&:hover": {
                                            background: "none",
                                            boxShadow: "none"
                                        } 
                                    }}
                                    onClick={copyScheduleId}
                                >
                                    #{schedule.id}
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>

                    {/* Column 2: Active members (Customer) */}
                    <Grid item xs={12} sm={6} md={2} sx={{ borderRight: '1px solid grey', paddingLeft: "10px" }}>
                        <Typography variant="caption" color="text.secondary">Customer</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Button
                                variant="contained"
                                sx={{ 
                                    color: "black", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    padding: 0, 
                                    background: "none", 
                                    "&:hover": {
                                        background: "none",
                                        boxShadow: "none"
                                    } 
                                }}
                                onClick={() => setCustomerDialogOpen(true)}
                            >
                                {`${schedule.customer.firstName} ${schedule.customer.lastName}`}
                            </Button>
                        </Box>
                    </Grid>

                    {/* Column 3: Monthly plan price per member (Collection Store) */}
                    <Grid item xs={12} sm={6} md={2} sx={{ borderRight: '1px solid grey', paddingLeft: "10px" }}>
                        <Typography variant="caption" color="text.secondary">Collection Store</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Button
                                variant="contained"
                                sx={{ 
                                    color: "black", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    padding: 0, 
                                    background: "none", 
                                    "&:hover": {
                                        background: "none",
                                        boxShadow: "none"
                                    } 
                                }}
                                onClick={() => setStoreDialogOpen(true)}
                            >
                                {schedule.metadata?.collection_store || 'NO STORE'}
                            </Button>
                        </Box>
                    </Grid>

                    {/* Column 4: Monthly charge (Payment Method) */}
                    <Grid item xs={12} sm={6} md={2} sx={{ borderRight: '1px solid grey', paddingLeft: "10px"  }}>
                        <Typography variant="caption" color="text.secondary">Payment Method</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Button
                                variant="contained"
                                sx={{ 
                                    color: "black", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    textAlign: "left",
                                    padding: 0, 
                                    background: "none", 
                                    "&:hover": {
                                        background: "none",
                                        boxShadow: "none"
                                    } 
                                }}							>
                                {schedule.paymentMethod?.description || 'None'}
                            </Button>
                        </Box>
                    </Grid>

                    {/* Column 5: Subscription renewal date (Next Occurrence) */}
                    <Grid item xs={12} sm={6} md={2} sx={{ paddingLeft: "10px" }}>
                        <Typography variant="caption" color="text.secondary">Next Occurrence</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Button
                                variant="contained"
                                onClick={() => setNextOccurrenceDialogOpen(true)}
                                sx={{ 
                                    color: "black", 
                                    border: "none", 
                                    boxShadow: "none", 
                                    padding: 0, 
                                    background: "none", 
                                    "&:hover": {
                                        background: "none",
                                        boxShadow: "none"
                                    } 
                                }}
                            >
                                {relativeWeekLabel(schedule.nextOccurrenceUtc)}
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} sx={{ borderLeft: '1px solid grey', paddingLeft: "10px" }}>
                        <Typography variant="caption" color="text.secondary">FREQUENCY</Typography>
                        <Box display="flex" alignItems="center" mt={1}>
                            <Tooltip title="Click to Update Frequency">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setSelectedFrequency(schedule.frequency || 1);
                                        setFrequencyDialogOpen(true);
                                    }}
                                    sx={{ 
                                        color: "black", 
                                        border: "none", 
                                        boxShadow: "none", 
                                        padding: 0, 
                                        background: "none", 
                                        "&:hover": {
                                            background: "none",
                                            boxShadow: "none"
                                        } 
                                    }}
                                >
                                    {`Every ${schedule.frequency || 1} ${schedule.frequency === 1 ? 'Week' : 'Weeks'}`}
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>

                </Grid>
            </Paper>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Sale Price</TableCell>
                            <TableCell>
                                <Tooltip title="# of times this should process. Set to 1 for next order only">
                                    <span>Max Cycles</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {schedule.scheduledOrderItems.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{item.product.title}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={item.quantity}
                                        onChange={(e) => handleQuantityChange(index, Number(e.target.value))}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton 
                                                        onClick={() => handleQuantityChange(index, Math.max(1, item.quantity - 1))}
                                                        size="small"
                                                    >
                                                        <Remove />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton 
                                                        onClick={() => handleQuantityChange(index, item.quantity + 1)}
                                                        size="small"
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            inputProps: { min: 1 }
                                        }}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={item.price || ''} fullWidth
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={item.salePrice || ''} fullWidth
                                        placeholder='Authorised Discount Price'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControlLabel
                                        label="One Time Only"
                                        control={
                                            <Switch
                                                checked={item.maxCycles === 1}
                                                onChange={(e) => handleMaxCyclesToggle(index, e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleOpenDeleteModal(item)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            ))}
                            
                            {/* Add Product Row */}
                            <AddProductRow onClick={handleOpenProductDialog}>
                                <TableCell colSpan={6} align="center">
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Add sx={{ mr: 1 }} />
                                        <Typography variant="body1">Add a Product</Typography>
                                    </Box>
                                </TableCell>
                            </AddProductRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={3}>
                <Typography variant="h6">Order Notes</Typography>
                <TextField
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    placeholder='Enter notes for the order - Please be aware that the customer can see these notes.'
                    value={schedule.note || ''}
                    onChange={(e) => {
                        setSchedule({ ...schedule, note: e.target.value });
                        setIsChanged(true);
                    }}
                />
            </Box>
            <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <DialogTitle>
                    Are you sure you want to delete {productToDelete?.product.title} from schedule order #{schedule.id}?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteModalOpen(false)}
                        color="secondary"
                        sx={{ backgroundColor: 'grey', color: 'white' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteProduct}
                        color="primary"
                        sx={{ backgroundColor: '#9ba03b', color: 'white' }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
                <DialogTitle>
                    Add Products
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Search Products"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button onClick={handleSearchSubmit}>Search</Button>
                                </InputAdornment>
                            )
                        }}
                    />
                    {loadingProducts ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <CircularProgress />
                        </div>
                    ) : products.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Product Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell width="10%">Quantity</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((product) => (
                                        <TableRow key={product.id}>
                                            <TableCell><img src={product.imageUrl} alt={product.title} style={{ width: 50 }} /></TableCell>
                                            <TableCell>{product.title}</TableCell>
                                            <TableCell dangerouslySetInnerHTML={{ __html: product.description }} />
                                            <TableCell>{product.price}</TableCell>
                                            <TableCell width="10%">
                                                <TextField
                                                    type="number"
                                                    value={productQuantities[product.id] || 1}
                                                    onChange={(e) => handleModalQuantityChange(product.id, Number(e.target.value))}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton 
                                                                    onClick={() => handleModalQuantityChange(product.id, Math.max(1, (productQuantities[product.id] || 1) - 1))}
                                                                    size="small"
                                                                >
                                                                    <Remove />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton 
                                                                    onClick={() => handleModalQuantityChange(product.id, (productQuantities[product.id] || 1) + 1)}
                                                                    size="small"
                                                                >
                                                                    <Add />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: { min: 1, style: { textAlign: 'center' } }
                                                    }}
                                                    style={{ width: 80 }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button variant="contained" color="primary" onClick={() => handleAddProduct(product)}>
                                                    Add to Schedule
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No products found</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>

            {/* Order History Modal */}
            <Dialog open={historyModalOpen} onClose={handleHistoryModalClose} fullWidth maxWidth="md">
                <DialogTitle>
                    {`${schedule.customer.firstName} ${schedule.customer.lastName}'s Bulk Order History`}
                    <IconButton
                        aria-label="close"
                        onClick={handleHistoryModalClose}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {ordersLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order #</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Items</TableCell>
                                        <TableCell>Note</TableCell>
                                        <TableCell>Scheduled?</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customerOrders.map((order) => (
                                        <TableRow key={order.id}>
                                            <TableCell>#{order.id}</TableCell>
                                            <TableCell>
                                                {moment(order.date_created).format('ddd, DD/MM/YY')}<br />
                                                <small>{moment(order.date_created).fromNow()}</small>
                                            </TableCell>
                                            <TableCell>
                                                {order.status === 'prod-scheduled' ? 'Awaiting Production' :
                                                    order.status === 'pending-fulfilment' ? 'Not Actioned Yet' :
                                                        order.status === 'ready-to-collect' ? 'Ready to Collect' :
                                                            order.status === 'waiting-for-store-transfer' ? 'Pending Transfer to Store' :
                                                                order.status === 'pickup' ? 'Awaiting Action' :
                                                                    order.status === 'packed' ? 'Ready to Collect' :
                                                                        order.status === 'click-collect-esc' ? 'Escalated to Warehouse' :
                                                                            order.status === 'store-transfer' ? 'Awaiting Store Transfer' :
                                                                                order.status === 'ready-collect' ? 'Ready to Collect' :
                                                                                    order.status}
                                            </TableCell>
                                            <TableCell>
                                                {order.line_items.map((item) => (
                                                    <div key={item.id}>
                                                        {item.name}
                                                        {item.meta_data.some(meta => meta.key === 'Status') && (
                                                            <Tooltip title={item.meta_data.find(meta => meta.key === 'Status')?.value || ''}>
                                                                <CheckCircle sx={{ color: 'green', fontSize: '1rem' }} />
                                                            </Tooltip>
                                                        )}
                                                        <br />
                                                    </div>
                                                ))}
                                            </TableCell>
                                            <TableCell>{order.customer_note || 'N/A'}</TableCell>
                                            <TableCell>
                                                {order.meta_data.some(meta => meta.key === 'Scheduled Order Id') ? (
                                                    <Tooltip title={`Scheduled Order Id: ${order.meta_data.find(meta => meta.key === 'Scheduled Order Id').value}`}>
                                                        <a href={`https://wms.neicha.com.au/bulk-schedules/${order.meta_data.find(meta => meta.key === 'Scheduled Order Id').value}`}>
                                                            <CheckCircle sx={{ color: 'green', fontSize: '1rem' }} />
                                                        </a>
                                                    </Tooltip>
                                                ) : (
                                                    <Cancel sx={{ color: 'red', fontSize: '1rem' }} />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHistoryModalClose} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>

            {/* Cycle History Modal */}
            <Dialog open={cycleModalOpen} onClose={handleCycleModalClose} fullWidth maxWidth="md">
              <DialogTitle>
                Cycle History
                <IconButton
                  aria-label="close"
                  onClick={handleCycleModalClose}
                  style={{ position: 'absolute', right: 8, top: 8 }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cycle ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processingHistory.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.processingId}</TableCell>
                        <TableCell>{moment.utc(item.startDateUtc).tz('Australia/Perth').format('DD/MM/YY HH:mm')}</TableCell>
                        <TableCell>
                          <Tooltip title={item.scheduledOrderFailureReason || ''}>
                            <Typography 
                              component="span" 
                              style={{ 
                                color: getCycleStatusColor(item.status),
                                fontWeight: 'bold'
                              }}
                            >
                              {item.status}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>${item.chargedTotal}</TableCell>
                        <TableCell>
                          <Box display="flex" gap={1}>
                            <Button 
                              variant="contained" 
                              size="small" 
                              onClick={() => handleViewCycleDetails(item)}
                              startIcon={<Visibility />}
                            >
                              View Details
                            </Button>
                            {index === 0 && item.status === 'Failed' && (
                              <Button 
                                variant="contained" 
                                color="primary"
                                size="small"
                                onClick={handleRetryCycle}
                                startIcon={<Refresh />}
                              >
                                Retry
                              </Button>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCycleModalClose} color="secondary">Close</Button>
              </DialogActions>
            </Dialog>

            {/* Cycle Details Drawer */}
            <Drawer
              anchor="right"
              open={cycleDetailsDrawerOpen}
              onClose={() => setCycleDetailsDrawerOpen(false)}
              sx={{
                zIndex: "5000"
              }}
              PaperProps={{
                sx: { 
                  width: '40%'              
                }
              }}
            >
              <Box sx={{ p: 3 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                  <Typography variant="h6">
                    Cycle Details: #{selectedCycle?.processingId}
                  </Typography>
                  <IconButton onClick={() => setCycleDetailsDrawerOpen(false)}>
                    <Close />
                  </IconButton>
                </Box>
                
                {loadingCycleDetails ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="400px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    {cycleDetails && (
                      <>
                        <Typography variant="subtitle1" gutterBottom>
                          Processing Stages
                        </Typography>
                        <Box sx={{ mb: 4 }}>
                          {Object.keys(cycleDetails.processingCycleStagesByAttempt).map(attempt => (
                            <Box key={attempt} sx={{ mb: 3 }}>
                              <Typography variant="subtitle2" gutterBottom>
                                Attempt {attempt}
                              </Typography>
                              <MuiList>
                                {cycleDetails.processingCycleStagesByAttempt[attempt].map((stage, index) => (
                                  <ListItem key={index} divider>
                                    <ListItemText 
                                      primary={stage.stage} 
                                      secondary={
                                        <Typography 
                                          component="span" 
                                          style={{ 
                                            color: getCycleStatusColor(stage.status),
                                            fontWeight: 'bold'
                                          }}
                                        >
                                          {stage.status}
                                        </Typography>
                                      } 
                                    />
                                  </ListItem>
                                ))}
                              </MuiList>
                            </Box>
                          ))}
                        </Box>
                        
                        <Typography variant="subtitle1" gutterBottom>
                          Processing Logs
                        </Typography>
                        {Object.keys(cycleDetails.processingCycleLogsByAttempt).map(attempt => (
                          <Box key={attempt} sx={{ mb: 4 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Attempt {attempt}
                            </Typography>
                            <TableContainer component={Paper} variant="outlined">
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="15%">Time</TableCell>
                                    <TableCell width="15%">Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell width="20%">Stage</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {cycleDetails.processingCycleLogsByAttempt[attempt]
                                    .sort((a, b) => new Date(a.createdUtc) - new Date(b.createdUtc))
                                    .map(log => {
                                      // Get status color
                                      const getLogColor = (type) => {
                                        switch(type) {
                                          case 'ProcessingError': return '#ffebee'; // light red background
                                          case 'ProcessingSuccess': return '#e8f5e9'; // light green background
                                          case 'ProcessingStart': return '#e3f2fd'; // light blue background
                                          case 'ProcessingComplete': return '#e8f5e9'; // light green background
                                          default: return 'inherit';
                                        }
                                      };
                                      
                                      // Get text color
                                      const getTextColor = (type) => {
                                        switch(type) {
                                          case 'ProcessingError': return '#d32f2f'; // darker red text
                                          case 'ProcessingSuccess': return '#2e7d32'; // darker green text
                                          case 'ProcessingStart': return '#1565c0'; // darker blue text
                                          case 'ProcessingComplete': return '#2e7d32'; // darker green text
                                          default: return 'inherit';
                                        }
                                      };
                                      
                                      return (
                                        <TableRow 
                                          key={log.id} 
                                          sx={{ 
                                            backgroundColor: getLogColor(log.type),
                                            '&:hover': { opacity: 0.9 }
                                          }}
                                        >
                                          <TableCell>
                                            {moment.utc(log.createdUtc).tz('Australia/Perth').format('HH:mm:ss')}
                                          </TableCell>
                                          <TableCell>
                                            <Typography 
                                              sx={{ 
                                                fontWeight: 'medium', 
                                                color: getTextColor(log.type)
                                              }}
                                            >
                                              {log.type.replace('Processing', '')}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Typography 
                                              sx={{ 
                                                fontWeight: log.type === 'ProcessingError' ? 'bold' : 'normal',
                                                color: log.type === 'ProcessingError' ? getTextColor(log.type) : 'inherit'
                                              }}
                                            >
                                              {log.description}
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            {log.processingStage && (
                                              <Typography variant="body2" color="textSecondary">
                                                {log.processingStage} {log.processingStep ? `(${log.processingStep})` : ''}
                                              </Typography>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        ))}
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Drawer>

            {/* Event Logs Modal */}
            <Dialog open={eventLogsModalOpen} onClose={handleEventLogsModalClose} fullWidth maxWidth="md">
                <DialogTitle>
                    Event Logs
                    <IconButton
                        aria-label="close"
                        onClick={handleEventLogsModalClose}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eventLogs.map((log) => (
                                <TableRow key={log.Id}>
                                    <TableCell>{moment(log.EventUtc).tz('Australia/Perth').format('DD/MM/YY hh:mm A')}</TableCell>
                                    <TableCell dangerouslySetInnerHTML={{ __html: log.Description }} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEventLogsModalClose} color="secondary">Close</Button>
                </DialogActions>
            </Dialog>

            {
                isChanged && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveChanges}
                        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
                    >
                        Save Changes
                    </Button>
                )
            }

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <SnackbarContent
                    message={
                        snackbar.type === 'info' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CircularProgress size={20} style={{ marginRight: 10 }} />
                                {snackbar.message}
                            </div>
                        ) : (
                            snackbar.message
                        )
                    }
                    style={{
                        backgroundColor: snackbar.type === 'success' ? 'green' : snackbar.type === 'error' ? 'red' : 'blue',
                    }}
                />
            </Snackbar>

            {/* Process Now Confirmation Dialog */}
            <Dialog open={processNowModalOpen} onClose={() => setProcessNowModalOpen(false)}>
                <DialogTitle>Confirm Process Now</DialogTitle>
                <DialogContent>Are you sure you want to process this schedule now?</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setProcessNowModalOpen(false)}
                        color="secondary"
                        sx={{ backgroundColor: 'grey', color: 'white' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleProcessNow}
                        color="primary"
                        sx={{ backgroundColor: '#9ba03b', color: 'white' }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Customer Dialog */}
            <Dialog open={customerDialogOpen} onClose={() => setCustomerDialogOpen(false)}>
                <DialogTitle>
                    Customer Details
                    <IconButton
                        aria-label="close"
                        onClick={() => setCustomerDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {schedule?.customer && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <strong>Name:</strong> {schedule.customer.firstName} {schedule.customer.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <strong>Email:</strong> {schedule.customer.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <strong>Phone:</strong> {schedule.customer.phoneNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    <strong>ID:</strong> {schedule.customer.id}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>

            {/* Store Dialog */}
            <Dialog open={storeDialogOpen} onClose={() => setStoreDialogOpen(false)}>
                <DialogTitle>
                    Select Store
                    <IconButton
                        aria-label="close"
                        onClick={() => setStoreDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Array of store options */}
                    {(() => {
                        const stores = ['Bellevue', 'Bayswater', 'Maddington'];
                        return stores.map((storeName) => (
                            <Button
                                key={storeName}
                                onClick={() => {
                                    if (schedule.metadata?.collection_store === storeName) {
                                        // Do nothing if the same store is selected
                                        setStoreDialogOpen(false);
                                        return;
                                    }
                                    setSchedule({
                                        ...schedule,
                                        metadata: { ...schedule.metadata, collection_store: storeName },
                                    });
                                    setIsChanged(true);
                                    setStoreDialogOpen(false);
                                }}
                                fullWidth
                                style={{
                                    margin: '5px 0', // Add margin to separate buttons vertically
                                    border: '2px solid #9ba03b',
                                    backgroundColor: schedule.metadata?.collection_store === storeName ? '#9ba03b' : 'transparent',
                                    color: schedule.metadata?.collection_store === storeName ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#9ba03b',
                                        color: 'white',
                                    },
                                }}
                            >
                                {storeName}
                            </Button>
                        ));
                    })()}
                </DialogContent>
            </Dialog>

            {/* Add Product Dialog with Card Layout */}
            <Dialog 
                open={addProductDialogOpen} 
                onClose={() => setAddProductDialogOpen(false)} 
                fullWidth 
                maxWidth="xl"
                sx={{ '& .MuiDialog-paper': { height: '90vh' } }}
            >
                <DialogTitle>
                    Add Products
                    <IconButton
                        aria-label="close"
                        onClick={() => setAddProductDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {loadingAllProducts ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {allProducts.map((product) => (
                                <Grid item xs={12} sm={6} md={3} key={product.id}>
                                    <Card 
                                        sx={{ 
                                            height: '100%', 
                                            display: 'flex', 
                                            border: "1px solid grey",
                                            boxShadow: 'none',
                                            flexDirection: 'column',
                                            '&:hover': { boxShadow: 6 }
                                        }}
                                    >
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Box 
                                                component="img" 
                                                src={product.imageUrl || 'https://via.placeholder.com/150'} 
                                                alt={product.title}
                                                sx={{ 
                                                    width: '100%', 
                                                    height: '150px', 
                                                    objectFit: 'contain', 
                                                    mb: 2 
                                                }}
                                            />
                                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                                {product.title}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                ${product.price}/{product.weight ? product.weight + 'kg' : 'unit'}
                                            </Typography>
                                        </CardContent>
                                        <CardActions 
                                            style={{
                                                margin: "0px",
                                                }}
                                        >
                                            <Button 
                                                size="small" 
                                                variant="contained" 
                                                color="primary" 
                                                style={{
                                                    padding: "0.5em",
                                                }}
                                                fullWidth
                                                onClick={() => handleAddProductFromCard(product)}
                                            >
                                                Add to Schedule
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>

            {/* Customer Dialog - Enhanced version */}
            <Dialog open={customerDialogOpen} onClose={() => setCustomerDialogOpen(false)}>
                <DialogTitle>
                    Customer Details
                    <IconButton
                        aria-label="close"
                        onClick={() => setCustomerDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {schedule?.customer && (
                        <Box sx={{ p: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Person sx={{ color: '#9ba03b', mr: 2, fontSize: 30 }} />
                                        <Box>
                                            <Typography variant="overline" color="textSecondary">Customer Name</Typography>
                                            <Typography variant="h6">{schedule.customer.firstName} {schedule.customer.lastName}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Email sx={{ color: '#9ba03b', mr: 2, fontSize: 30 }} />
                                        <Box>
                                            <Typography variant="overline" color="textSecondary">Email Address</Typography>
                                            <Typography variant="body1">
                                                <a href={`mailto:${schedule.customer.email}`} style={{ color: '#1976d2', textDecoration: 'none' }}>
                                                    {schedule.customer.email}
                                                </a>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Phone sx={{ color: '#9ba03b', mr: 2, fontSize: 30 }} />
                                        <Box>
                                            <Typography variant="overline" color="textSecondary">Phone Number</Typography>
                                            <Typography variant="body1">
                                                <a href={`tel:${schedule.customer.phoneNumber}`} style={{ color: '#1976d2', textDecoration: 'none' }}>
                                                    {schedule.customer.phoneNumber}
                                                </a>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" alignItems="center">
                                        <Badge sx={{ color: '#9ba03b', mr: 2, fontSize: 30 }} />
                                        <Box>
                                            <Typography variant="overline" color="textSecondary">Customer ID</Typography>
                                            <Typography variant="body1">{schedule.customer.id}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>

            {/* Next Occurrence Dialog */}
            <Dialog 
                open={nextOccurrenceDialogOpen} 
                onClose={() => setNextOccurrenceDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    Update Next Occurrence Date
                    <IconButton
                        aria-label="close"
                        onClick={() => setNextOccurrenceDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Select a Monday for the next occurrence date. Past dates are disabled.
                    </Typography>
                    <Box sx={{ height: 500, width: '100%' }}>
                        <ScrollingCalendar 
                            selectedDate={selectedOccurrenceDate ? new Date(selectedOccurrenceDate) : null}
                            onDateSelect={(date) => {
                                // Only allow Mondays
                                if (moment(date).day() === 1) {
                                    setSelectedOccurrenceDate(moment(date).format('YYYY-MM-DD'));
                                } else {
                                    setSnackbar({ 
                                        open: true, 
                                        message: 'Please select a Monday for the next occurrence date', 
                                        type: 'error' 
                                    });
                                }
                            }}
                            minDate={new Date()}
                        />
                    </Box>
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                        Note: Only Mondays are valid occurrence dates.
                    </Typography>
                    <Typography variant="caption" color="textSecondary" sx={{ display: 'block', mt: 1 }}>
                        Processing will occur at 1:00 AM (AWST).
                    </Typography>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNextOccurrenceDialogOpen(false)} color="secondary">Cancel</Button>
                    <Button 
                        onClick={handleNextOccurrenceUpdate} 
                        disabled={!selectedOccurrenceDate}
                        color="primary"
                        sx={{background:"#9ba03b", color:"white"}}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Frequency Dialog */}
            <Dialog open={frequencyDialogOpen} onClose={() => setFrequencyDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    Update Schedule Frequency
                    <IconButton
                        aria-label="close"
                        onClick={() => setFrequencyDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Frequency</InputLabel>
                        <Select
                            value={selectedFrequency}
                            onChange={(e) => setSelectedFrequency(e.target.value)}
                            label="Frequency"
                        >
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((freq) => (
                                <MenuItem key={freq} value={freq}>
                                    Every {freq} {freq === 1 ? 'Week' : 'Weeks'}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button sx={{background:"black", color:"white"}} onClick={() => setFrequencyDialogOpen(false)} color="secondary">Cancel</Button>
                    <Button sx={{background:"#9ba03b", color:"white"}} onClick={handleFrequencyUpdate} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Backdrop for loading states */}
            <Backdrop
                sx={{ color: '#fff', zIndex: "1000000" }}
                open={addingProduct || updatingSchedule}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {addingProduct ? 'Adding Product...' : 'Updating Schedule...'}
                    </Typography>
                </Box>
            </Backdrop>

            {/* Store Dialog */}
            <Dialog open={storeDialogOpen} onClose={() => setStoreDialogOpen(false)}>
                <DialogTitle>
                    Select Store
                    <IconButton
                        aria-label="close"
                        onClick={() => setStoreDialogOpen(false)}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* Array of store options */}
                    {(() => {
                        const stores = ['Bellevue', 'Bayswater', 'Maddington'];
                        return stores.map((storeName) => (
                            <Button
                                key={storeName}
                                onClick={() => {
                                    if (schedule.metadata?.collection_store === storeName) {
                                        // Do nothing if the same store is selected
                                        setStoreDialogOpen(false);
                                        return;
                                    }
                                    setSchedule({
                                        ...schedule,
                                        metadata: { ...schedule.metadata, collection_store: storeName },
                                    });
                                    setIsChanged(true);
                                    setStoreDialogOpen(false);
                                }}
                                fullWidth
                                style={{
                                    margin: '5px 0', // Add margin to separate buttons vertically
                                    border: '2px solid #9ba03b',
                                    backgroundColor: schedule.metadata?.collection_store === storeName ? '#9ba03b' : 'transparent',
                                    color: schedule.metadata?.collection_store === storeName ? 'white' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#9ba03b',
                                        color: 'white',
                                    },
                                }}
                            >
                                {storeName}
                            </Button>
                        ));
                    })()}
                </DialogContent>
            </Dialog>

            {/* Status Update Dialog */}
            <MuiDialog open={statusUpdateDialogOpen} onClose={() => setStatusUpdateDialogOpen(false)}>
              <MuiDialogTitle>Update Schedule Status</MuiDialogTitle>
              <MuiDialogContent>
                <MuiFormControl fullWidth margin="dense">
                  <InputLabel>Status</InputLabel>
                  <MuiSelect
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    label="Status"
                  >
                    <MuiMenuItem value="Active">Active</MuiMenuItem>
                    <MuiMenuItem value="Paused">Paused</MuiMenuItem>
                    <MuiMenuItem value="Failed">Failed</MuiMenuItem>
                    <MuiMenuItem value="Deleted">Deleted</MuiMenuItem>
                  </MuiSelect>
                </MuiFormControl>
              </MuiDialogContent>
              <MuiDialogActions>
                <Button sx={{background:"black", color:"white"}} onClick={() => setStatusUpdateDialogOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button sx={{background:"#9ba03b", color:"white"}} onClick={handleStatusUpdate} color="primary">
                  Update
                </Button>
              </MuiDialogActions>
            </MuiDialog>

            {/* Processing overlay */}
            <Backdrop
              sx={{ color: '#fff', zIndex: "1000000" }}
              open={processingRetry}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Processing retry...
                </Typography>
              </Box>
            </Backdrop>

            {/* Deleting Product overlay */}
            <Backdrop
              sx={{ color: '#fff', zIndex: "1000000" }}
              open={deletingProduct}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress color="inherit" />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Removing Product...
                </Typography>
              </Box>
            </Backdrop>
        </div >
    );
};

export default BulkScheduleDetails;