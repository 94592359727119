import React, { useState } from "react";
import { Box, Grid, Card, CardContent, CardActions, Button, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScaleIcon from "@mui/icons-material/Scale";
import LanguageIcon from "@mui/icons-material/Language";

import WebStockManager from "./sub_components/WebStockManager";
import BulkStockManager from "./sub_components/BulkStockManager";

const StockLevelManager = () => {
  const [selectedStockType, setSelectedStockType] = useState(null);

  // Helper function to return the title for the selected view
  const getEditingTitle = () => {
    if (selectedStockType === "website") {
      return "You're Editing Online Website Availability";
    } else if (selectedStockType === "bulk") {
      return "You're Editing Bulk Order Availability";
    }
    return "";
  };

  // If nothing is selected, show the selection screen
  if (!selectedStockType) {
    return (
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          What type of stock would you like to manage?
        </Typography>
        <Grid container spacing={2}>
          {/* Website Card */}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <LanguageIcon fontSize="large" />
                <Typography variant="h5" gutterBottom>
                  Website Stock
                </Typography>
                <Typography variant="body2">Manage online website availability</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={() => setSelectedStockType("website")}>
                  Manage Website
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* Bulk Card */}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <ScaleIcon fontSize="large" />
                <Typography variant="h5" gutterBottom>
                  Bulk Stock
                </Typography>
                <Typography variant="body2">Manage bulk order availability</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={() => setSelectedStockType("bulk")}>
                  Manage Bulk
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Otherwise, show the chosen sub-component
  return (
    <Box p={2}>
      {/* Header with back button */}
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={() => setSelectedStockType(null)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" style={{fontWeight: "bold"}} ml={1}>
          {getEditingTitle()}
        </Typography>
      </Box>

      {/* Conditionally render the sub-component */}
      {selectedStockType === "website" && <WebStockManager />}
      {selectedStockType === "bulk" && <BulkStockManager />}
    </Box>
  );
};

export default StockLevelManager;