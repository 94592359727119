import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Chip,
  Autocomplete,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from '../hooks/useAuth';

const ConfigureProteins = () => {
  const { getApiKey } = useAuth();
  
  const [proteins, setProteins] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Dialog states
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentProtein, setCurrentProtein] = useState(null);
  const [newProteinName, setNewProteinName] = useState('');
  
  // Fetch all proteins and products on component mount
  useEffect(() => {
    fetchProteins();
    fetchProducts();
  }, []);
  
  const fetchProteins = async () => {
    try {
      setLoading(true);
      const accessToken = getApiKey('wms');
      const response = await axios.get('https://wms-api.neicha.com.au/protein', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setProteins(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch proteins');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchProducts = async () => {
    try {
      const accessToken = getApiKey('wms');
      const response = await axios.get('https://wms-api.neicha.com.au/all-items', {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setProducts(response.data);
    } catch (err) {
      console.error('Failed to fetch products', err);
    }
  };
  
  const handleAddProtein = async () => {
    try {
      const accessToken = getApiKey('wms');
      await axios.post('https://wms-api.neicha.com.au/protein', 
        { proteinName: newProteinName },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setNewProteinName('');
      setAddDialogOpen(false);
      fetchProteins(); // Refresh the list
    } catch (err) {
      setError('Failed to add protein');
      console.error(err);
    }
  };
  
  const handleEditProtein = async () => {
    if (!currentProtein) return;
    try {
      const accessToken = getApiKey('wms');
      await axios.put(`https://wms-api.neicha.com.au/protein/${currentProtein.id}`, 
        { proteinName: newProteinName },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setEditDialogOpen(false);
      fetchProteins(); // Refresh the list
    } catch (err) {
      setError('Failed to update protein');
      console.error(err);
    }
  };
  
  const handleDeleteProtein = async () => {
    if (!currentProtein) return;
    try {
      const accessToken = getApiKey('wms');
      await axios.delete(`https://wms-api.neicha.com.au/protein/${currentProtein.id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      setDeleteDialogOpen(false);
      fetchProteins(); // Refresh the list
    } catch (err) {
      setError('Failed to delete protein');
      console.error(err);
    }
  };
  
  const handleOpenEditDialog = (protein) => {
    setCurrentProtein(protein);
    setNewProteinName(protein.name);
    setEditDialogOpen(true);
  };
  
  const handleOpenDeleteDialog = (protein) => {
    setCurrentProtein(protein);
    setDeleteDialogOpen(true);
  };
  
  const handleAssignProduct = async (proteinId, sku) => {
    try {
      const accessToken = getApiKey('wms');
      await axios.post(`https://wms-api.neicha.com.au/protein/${proteinId}/assign`, 
        { sku },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      fetchProteins(); // Refresh the list
    } catch (err) {
      setError(`Failed to assign product ${sku}`);
      console.error(err);
    }
  };
  
  const handleUnassignProduct = async (proteinId, sku) => {
    try {
      const accessToken = getApiKey('wms');
      await axios.delete(`https://wms-api.neicha.com.au/protein/${proteinId}/unassign`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        data: { sku }
      });
      fetchProteins(); // Refresh the list
    } catch (err) {
      setError(`Failed to unassign product ${sku}`);
      console.error(err);
    }
  };
  
  // Get available products not already assigned to this protein
  const getAvailableProducts = (proteinId) => {
    const protein = proteins.find(p => p.id === proteinId);
    if (!protein) return products;
    
    const assignedSkus = protein.products.map(p => p.sku);
    return products.filter(p => !assignedSkus.includes(p.sku));
  };
  
  if (loading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }
  
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Configure Proteins
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setNewProteinName('');
            setAddDialogOpen(true);
          }}
        >
          Add Protein
        </Button>
      </Box>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Products</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proteins.map((protein) => (
              <TableRow key={protein.id}>
                <TableCell>{protein.id}</TableCell>
                <TableCell>{protein.name}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {protein.products && protein.products.map((product) => (
                      <Chip
                        key={product.sku}
                        label={`${product.productname} (${product.sku})`}
                        onDelete={() => handleUnassignProduct(protein.id, product.sku)}
                      />
                    ))}
                    <Autocomplete
                      sx={{ mt: 1, minWidth: 300 }}
                      options={getAvailableProducts(protein.id)}
                      getOptionLabel={(option) => `${option.productname} (${option.sku})`}
                      renderInput={(params) => (
                        <TextField {...params} label="Add Product" size="small" />
                      )}
                      onChange={(event, value) => {
                        if (value) {
                          handleAssignProduct(protein.id, value.sku);
                        }
                      }}
                      value={null}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <IconButton 
                    color="primary" 
                    onClick={() => handleOpenEditDialog(protein)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    color="error" 
                    onClick={() => handleOpenDeleteDialog(protein)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Add Protein Dialog */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add New Protein</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Protein Name"
            fullWidth
            value={newProteinName}
            onChange={(e) => setNewProteinName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleAddProtein}
            variant="contained" 
            color="primary"
            disabled={!newProteinName.trim()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Edit Protein Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Protein</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Protein Name"
            fullWidth
            value={newProteinName}
            onChange={(e) => setNewProteinName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleEditProtein}
            variant="contained" 
            color="primary"
            disabled={!newProteinName.trim()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the protein "{currentProtein?.name}"? 
            This will remove all product assignments.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleDeleteProtein}
            variant="contained" 
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ConfigureProteins;