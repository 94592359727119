import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, CircularProgress } from '@mui/material';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import axios from 'axios';
import authService from '../services/authService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Production = () => {
  const [productionData, setProductionData] = useState([]);
  const [filteredSkus, setFilteredSkus] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductionData = async () => {
      setLoading(true);
      const accessToken = authService.getApiKey("wms");
  
      // Get the current date in AWST (UTC+8)
      const currentDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'Australia/Perth' }));
  
      // Calculate the start date by going back 2 months and rounding to the previous Monday
      const startDate = new Date(currentDate);
      startDate.setMonth(startDate.getMonth() - 2);
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1); // Adjust to the previous Monday
  
      // Add one day to currentDate for the endDate
      const endDate = new Date(currentDate);
      endDate.setDate(endDate.getDate() + 1);
  
      // Format the dates to 'DD/MM/YYYY'
      const formatDate = (date) => {
        const d = date.getDate().toString().padStart(2, '0');
        const m = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const y = date.getFullYear();
        return `${d}/${m}/${y}`;
      };
  
      try {
        const response = await axios.get(`https://wms-api.neicha.com.au/stockmade?startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setProductionData(response.data);
  
        const uniqueCategories = [...new Set(response.data.map(item => item.departmentname))];
        setCategories(uniqueCategories.map(cat => ({ label: cat, value: cat })));
        setSelectedCategories(uniqueCategories);
      } catch (error) {
        console.error('Error fetching production data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching data
      }
    };
    fetchProductionData();
  }, []);  

  const filteredData = productionData.filter(item =>
    selectedCategories.includes(item.departmentname)
  );

  const getProductionByWeek = (weekOffset) => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - 7 * weekOffset);
    startOfWeek.setHours(0, 0, 0, 0); // Start of the week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // End of the week

    let totalQty = 0;
    let totalValue = 0;

    filteredData
      .filter(
        (item) => new Date(item.createdAt) >= startOfWeek && new Date(item.createdAt) <= endOfWeek
      )
      .forEach(item => {
        totalQty += item.qty;
        totalValue += item.qty * (item.productprice / 100); // Ensure correct valuation
      });

    return { totalQty, totalValue };
  };

  const thisWeekProduction = getProductionByWeek(0).totalQty;
  const lastWeekProduction = getProductionByWeek(1).totalQty;
  const thisWeekProductionValue = getProductionByWeek(0).totalValue;
  const lastWeekProductionValue = getProductionByWeek(1).totalValue;

  const getWeeklyProductionOverTime = () => {
    const weeksData = [];
    for (let i = 7; i >= 0; i--) {
      weeksData.push(getProductionByWeek(i));
    }
    return weeksData;
  };

  const weeklyProductionData = getWeeklyProductionOverTime();

  const getTopProducedSkus = (weekOffset) => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - 7 * weekOffset);
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const skuMap = {};
    filteredData
      .filter(
        (item) => new Date(item.createdAt) >= startOfWeek && new Date(item.createdAt) <= endOfWeek
      )
      .forEach((item) => {
        if (skuMap[item.sku]) {
          skuMap[item.sku].qty += item.qty;
        } else {
          skuMap[item.sku] = { ...item };
        }
      });

    const groupedSkus = Object.values(skuMap)
      .sort((a, b) => b.qty - a.qty)
      .slice(0, 10);

    return groupedSkus;
  };

  const topSkusThisWeek = getTopProducedSkus(0);
  const topSkusLastWeek = getTopProducedSkus(1);

  const getSkuProductionOverLast4Weeks = () => {
    const skus = {};
  
    for (let i = 0; i < 4; i++) {
      const weeklyData = getTopProducedSkus(i); // Get data for this week
      weeklyData.forEach((item) => {
        if (!skus[item.sku]) {
          skus[item.sku] = Array(4).fill(0);
        }
        skus[item.sku][i] += item.qty;
      });
    }
  
    return skus;
  };  

  const skusData = getSkuProductionOverLast4Weeks();

  const formatCurrency = (value) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  const getCategoryPieData = (weekOffset, metric) => {
    const startOfWeek = new Date();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - 7 * weekOffset);
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const categoryMap = {};

    filteredData
      .filter(
        (item) => new Date(item.createdAt) >= startOfWeek && new Date(item.createdAt) <= endOfWeek
      )
      .forEach((item) => {
        const key = item.departmentname || 'Uncategorized';
        if (!categoryMap[key]) {
          categoryMap[key] = 0;
        }
        categoryMap[key] += metric === 'qty' ? item.qty : (item.qty * item.productprice) / 100;
      });

    return {
      labels: Object.keys(categoryMap),
      datasets: [
        {
          data: Object.values(categoryMap),
          backgroundColor: Object.keys(categoryMap).map((_, i) => `hsl(${(i * 60) % 360}, 70%, 50%)`),
          label: metric === 'value' ? 'Total Valuation' : 'Total Quantity',
        },
      ],
    };
  };

  const formatPieTooltipLabel = (tooltipItem) => {
    const value = tooltipItem.raw;
    return formatCurrency(value);
  };

  const thisWeekQtyPieData = getCategoryPieData(0, 'qty');
  const thisWeekValuePieData = getCategoryPieData(0, 'value');
  const lastWeekQtyPieData = getCategoryPieData(1, 'qty');
  const lastWeekValuePieData = getCategoryPieData(1, 'value');

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Production Reporting
      </Typography>

      <Select
        isMulti
        options={categories}
        value={categories.filter(cat => selectedCategories.includes(cat.value))}
        onChange={(selected) => setSelectedCategories(selected.map(sel => sel.value))}
        placeholder="Filter by Categories"
      />

      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Quantity of SKUs Produced This Week</Typography>
              <Typography variant="h4">{thisWeekProduction}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Quantity of SKUs Produced Last Week</Typography>
              <Typography variant="h4">{lastWeekProduction}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">This Week Production Valuation</Typography>
              <Typography variant="h4">{formatCurrency(thisWeekProductionValue)}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Last Week Production Valuation</Typography>
              <Typography variant="h4">{formatCurrency(lastWeekProductionValue)}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {selectedCategories.length > 1 && (
          <Grid container spacing={2} style={{ marginTop: '20px', marginLeft: "8px"}}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Qty Produced by Category (This Week)</Typography>
                <div style={{ height: '25vh', padding: '10px' }}>
                  <Pie 
                    data={thisWeekQtyPieData} 
                    options={{
                      plugins: {
                        legend: { display: false },
                      },
                    }}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Valuation by Category (This Week)</Typography>
                <div style={{ height: '25vh', padding: '10px' }}>
                  <Pie 
                    data={thisWeekValuePieData} 
                    options={{
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          callbacks: {
                            label: formatPieTooltipLabel,
                          },
                        },
                      },
                    }} 
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Qty Produced by Category (Last Week)</Typography>
                <div style={{ height: '25vh', padding: '10px' }}>
                  <Pie 
                    data={lastWeekQtyPieData} 
                    options={{
                      plugins: {
                        legend: { display: false },
                      },
                    }} 
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h6">Valuation by Category (Last Week)</Typography>
                <div style={{ height: '25vh', padding: '10px' }}>
                  <Pie 
                    data={lastWeekValuePieData} 
                    options={{
                      plugins: {
                        legend: { display: false },
                        tooltip: {
                          callbacks: {
                            label: formatPieTooltipLabel,
                          },
                        },
                      },
                    }} 
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>        
        )}


        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Weekly Production Over Last 2 Months</Typography>
              <div style={{ height: '40vh' }}>
                <Bar
                  data={{
                    labels: Array.from({ length: 8 }, (_, i) => `Week ${8 - i}`),
                    datasets: [
                      {
                        label: 'Qty Produced',
                        backgroundColor: '#9ba03b',
                        data: weeklyProductionData.map(item => item.totalQty),
                      },
                      {
                        label: 'Total Valuation ($)',
                        backgroundColor: '#6c757d',
                        data: weeklyProductionData.map(item => item.totalValue),
                      },
                    ],
                  }}
                  options={{ responsive: true, maintainAspectRatio: false }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Top 10 Produced SKUs This Week</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">Qty Produced</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topSkusThisWeek.map((item) => (
                      <TableRow key={item.sku}>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell>{item.productname}</TableCell>
                        <TableCell align="right">{item.qty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Top 10 Produced SKUs Last Week</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">Qty Produced</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topSkusLastWeek.map((item) => (
                      <TableRow key={item.sku}>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell>{item.productname}</TableCell>
                        <TableCell align="right">{item.qty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">SKU Production Over Last 4 Weeks</Typography>
              <TextField
                fullWidth
                label="Search SKU or Product Name"
                variant="outlined"
                margin="normal"
                value={filteredSkus}
                onChange={(e) => setFilteredSkus(e.target.value)}
              />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell align="right">This Week</TableCell>
                      <TableCell align="right">Last Week</TableCell>
                      <TableCell align="right">2 Weeks Ago</TableCell>
                      <TableCell align="right">3 Weeks Ago</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(skusData)
                      .filter(sku => {
                        const product = filteredData.find(item => item.sku === sku);
                        const productName = product?.productname || '';
                        return sku.includes(filteredSkus) || productName.toLowerCase().includes(filteredSkus.toLowerCase());
                      })
                      .map((sku) => (
                        <TableRow key={sku}>
                          <TableCell>{sku}</TableCell>
                          <TableCell>{filteredData.find(item => item.sku === sku)?.productname}</TableCell>
                          {skusData[sku].map((qty, index) => (
                            <TableCell key={index} align="right">{qty}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};

export default Production;