// NetworkDetector.js
// filepath: /workspaces/warehouse-management-system/src/components/NetworkDetector.js
import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const NetworkDetector = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOfflineMessage, setShowOfflineMessage] = useState(false);
  const [hasConnectivityIssues, setHasConnectivityIssues] = useState(false);
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);

  useEffect(() => {
    // Simple online/offline detection
    const handleOnline = () => {
      setIsOnline(true);
      setShowOfflineMessage(false);
    };
    
    const handleOffline = () => {
      setIsOnline(false);
      setShowOfflineMessage(true);
    };
    
    // More reliable connectivity check with less false positives
    const checkConnectivity = async () => {
      // Skip frequent checks if we're already confirmed online
      if (navigator.onLine && !hasConnectivityIssues && consecutiveFailures === 0) {
        return;
      }
      
      if (navigator.onLine) {
        try {
          // Use a fast and reliable endpoint (Cloudflare)
          const controller = new AbortController();
          const timeoutId = setTimeout(() => controller.abort(), 3000);
          
          // Simple timestamp to avoid caching
          const timestamp = Date.now();
          const response = await fetch(`https://cloudflare.com/cdn-cgi/trace?${timestamp}`, { 
            method: 'HEAD',
            signal: controller.signal,
            cache: 'no-store'
          });
          
          clearTimeout(timeoutId);
          
          if (response.ok) {
            setConsecutiveFailures(0);
            setHasConnectivityIssues(false);
          } else {
            handleFailedCheck();
          }
        } catch (error) {
          handleFailedCheck();
        }
      }
    };
    
    // Require more consecutive failures before showing warning
    const handleFailedCheck = () => {
      setConsecutiveFailures(prev => {
        const newCount = prev + 1;
        // Only show warning after 3 consecutive failures (instead of 2)
        if (newCount >= 3) {
          setHasConnectivityIssues(true);
        }
        return newCount;
      });
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    
    // Check connectivity every 30 seconds
    const intervalId = setInterval(checkConnectivity, 30000);
    
    // Initial check with a slight delay to allow page to fully load
    const initialCheckId = setTimeout(checkConnectivity, 3000);
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      clearInterval(intervalId);
      clearTimeout(initialCheckId);
    };
  }, []);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      {/* Offline banner */}
      <Snackbar
        open={!isOnline}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          icon={<WifiOffIcon />}
          severity="error"
          sx={{ width: '100%' }}
          action={
            <Button 
              variant="outlined"
              sx={{ 
                color: '#fff',
                borderColor: '#fff',
                '&:hover': {
                  borderColor: '#fff',
                  backgroundColor: 'rgba(255, 255, 255, 0.08)'
                }
              }}
              size="small" 
              onClick={handleReload}
            >
              Try Again
            </Button>
          }
        >
          You are currently offline. Some features may not work properly.
        </Alert>
      </Snackbar>
      
      {/* Connectivity issues banner - when technically online but can't reach API */}
      <Snackbar
        open={isOnline && hasConnectivityIssues}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          icon={<SignalWifiStatusbarConnectedNoInternet4Icon />}
          severity="warning"
          sx={{ width: '100%' }}
          action={
            <Button 
              variant="outlined"
              sx={{ 
                color: '#663c00',
                borderColor: '#663c00',
                '&:hover': {
                  borderColor: '#663c00',
                  backgroundColor: 'rgba(102, 60, 0, 0.08)'
                }
              }}
              size="small" 
              onClick={handleReload}
            >
              Retry
            </Button>
          }
        >
          Limited connectivity detected. You may experience issues.
        </Alert>
      </Snackbar>
    </>
  );
};

export default NetworkDetector;