import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Paper, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress, Card, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import Barcode from 'react-barcode';
import dayjs from 'dayjs';
import authService from '../services/authService';

const ViewRequest = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [request, setRequest] = useState(null);
  const [excludeFulfilled, setExcludeFulfilled] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const barcodeRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const [printStatus, setPrintStatus] = useState('');

  useEffect(() => {
    const fetchRequest = async () => {
      const accessToken = authService.getApiKey("wms");
      try {
        const response = await axios.get(`https://wms-api.neicha.com.au/stock-request/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setRequest(response.data);
      } catch (error) {
        console.error('Error fetching stock request:', error);
      }
    };

    fetchRequest();
  }, [id]);

  const priorityText = (priority) => {
    switch (priority) {
      case 1:
        return 'Low';
      case 2:
        return 'Medium';
      case 3:
        return 'High';
      default:
        return 'Unknown';
    }
  };

  const statusBackgroundColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#fff3cd'; // Slight yellow
      case 'Deleted':
        return '#f8d7da'; // Slight red
      case 'Pending Transfer':
        return '#cce5ff'; // Slight blue
      case 'Fulfilled':
        return '#d4edda'; // Slight green
      default:
        return ''; // Default card background
    }
  };

  const priorityBackgroundColor = (priority) => {
    switch (priority) {
      case 1:
        return '#fff3cd'; // Yellow
      case 2:
        return '#ffcccb'; // Orange
      case 3:
        return '#f8d7da'; // Red
      default:
        return ''; // Default card background
    }
  };

  const svgToDataURL = () => {
    const svg = barcodeRef.current.querySelector('svg');
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  const handlePrint = async () => {
    if (!request) return;

    setIsPrinting(true);
    setPrintStatus('Processing Request...');

    const barcodeDataUrl = svgToDataURL();

    const orderedItems = Object.entries(request.itemsByDepartment).map(([department, items]) => `
      <tr>
        <td colspan="4" style="background-color: #f1f1f1;"><strong>Department: ${department}</strong></td>
      </tr>
      ${items.map(item => `
        <tr style="height: 23px;">
          <td style="height: 23px;">SKU: ${item.sku}</td>
          <td style="height: 23px;">${item.productname}</td>
          <td style="height: 23px;">${item.qtyrequested}</td>
          <td style="height: 23px;">SENT:   </td> <!-- Display as blank cell -->
        </tr>
      `).join('')}
    `).join('');

    setPrintStatus('Checking for Escalated Click and Collect Orders...');
    const token = authService.getApiKey("petfresh_backend");
    const formattedStoreName = encodeURIComponent(request.storename);
    let escalatedOrders = [];
    try {
      const response = await axios.get(`https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${formattedStoreName}?escalated=true`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      escalatedOrders = response.data;
    } catch (error) {
      console.error('Error fetching escalated orders:', error);
    }

    const escalatedOrdersTable = escalatedOrders.length > 0
      ? escalatedOrders.map(order => `
          <tr>
            <td rowspan="${order.order_items.length + (order.customer_note ? 2 : 1)}" style="text-align: center;">
              <img src="${svgToDataURL(order.order_id)}" alt="Barcode" style="width: 100px; height: 50px;" />
              <br />${order.order_id}
            </td>
            <td rowspan="${order.order_items.length + (order.customer_note ? 2 : 1)}">${order.first_name} ${order.last_name}</td>
            <td rowspan="${order.order_items.length + (order.customer_note ? 2 : 1)}">${order.order_age}</td>
            ${order.order_items.map((item, index) => `
              ${index === 0 ? `
                <td>${item.title}</td>
                <td>${item.qty}</td>
              </tr>` : `
              <tr>
                <td>${item.title}</td>
                <td>${item.qty}</td>
              </tr>`}
            `).join('')}
            ${order.customer_note ? `
              <tr>
                <td colspan="2" style="text-align: left; font-style: italic; padding: 10px; background-color: #f9f9f9;">
                  <strong>Customer Note:</strong> ${order.customer_note}
                </td>
              </tr>
            ` : ''}
          </tr>
        `).join('')
      : `
          <tr>
            <td colspan="5" style="text-align: center; padding: 20px; font-size: 16px; color: #555;">
              No Escalated Click and Collects for this store
            </td>
          </tr>
        `;

    setPrintStatus('Generating the Printout...');
    const printContent = `
      <p>**START REQUEST**</p>
      <table style="font-family: Times New Roman; width: 100%;" border="1px black" cellpadding="10px">
        <tbody>
          <tr style="height: 23px;">
            <td style="height: 23px;" colspan="2">
              <h2>STOCK REQUEST</h2>
            </td>
            <td style="height: 23px;" colspan="1" style="text-align: center;">
              <h2 style="text-align: right;">REQ #${request.id}</h2>
            </td>
            <td style="height: 23px;" colspan="1">
              <img src="${barcodeDataUrl}" alt="Barcode" />
            </td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;">&nbsp;<strong>REQUEST DATE</strong></td>
            <td style="height: 23px;">${dayjs(request.dateplaced).format('YYYY-MM-DD')}&nbsp;</td>
            <td style="height: 23px;">&nbsp;<strong>REQUESTED BY</strong></td>
            <td style="height: 23px;">${request.createdBy}&nbsp;</td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;">&nbsp;<strong>STORE</strong></td>
            <td style="height: 23px;">&nbsp;${request.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '')}</td>
            <td style="height: 23px;">&nbsp;<strong>PRIORITY</strong></td>
            <td style="height: 23px;">&nbsp;${priorityText(request.priority)}</td>
          </tr>
          <tr style="height: 23px;">
            <td colspan="4">&nbsp;<strong>REQUEST NOTES</strong></td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23.5px;" colspan="4">&nbsp;${request.notes || ''}</td>
          </tr>
          <tr style="background-color: #f5f5f5; height: 35px;">
            <td style="height: 23px;" colspan="4"><strong>REQUEST ITEMS</strong></td>
          </tr>
          <tr style="height: 23px;">
            <td style="height: 23px;"><strong>SKU</strong></td>
            <td style="height: 23px;"><strong>Product Name</strong></td>
            <td style="height: 23px;"><strong>QTY REQ</strong></td>
            <td style="height: 23px;"><strong>QTY SENT</strong></td>
          </tr>
          ${orderedItems}
          <tr style="background-color: #f5f5f5; height: 35px;">
            <td style="height: 23px;" colspan="4"><strong>WAREHOUSE USAGE ONLY</strong></td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Date Packed</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Date Sent</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 23.5px;">
            <td style="height: 23px;" colspan="1"><strong>Packed By</strong></td>
            <td style="height: 23px;" colspan="3">&nbsp;</td>
          </tr>
          <tr style="height: 150px;">
            <td colspan="1"><strong>Warehouse Remarks</strong></td>
            <td colspan="3">&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <p>**END REQUEST**</p>

      <p style="page-break-before: always;">**ESCALATED CLICK AND COLLECT ORDERS**</p>
      <table style="font-family: Times New Roman; width: 100%;" border="1px black" cellpadding="10px">
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Customer Name</th>
            <th>Order Placed</th>
            <th>Product</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          ${escalatedOrdersTable}
        </tbody>
      </table>
    `;

    const printWindow = window.open('', '', 'height=800,width=600');
    printWindow.document.write('<html><head><title>Stock Request</title></head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');

    setTimeout(() => {
      printWindow.document.close();
      printWindow.print();
      setIsPrinting(false);
    }, 500); // Add a delay to ensure the barcode loads
  };

  const handleStatusChange = async () => {
    const accessToken = authService.getApiKey("wms");

    try {
      await axios.post(
        'https://wms-api.neicha.com.au/stock-request-status',
        { id: request.id, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );
      setRequest({ ...request, status: newStatus });
      setDialogOpen(false);
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDeleteOrRecover = () => {
    if (request.status === 'Deleted') {
      setNewStatus('Pending');
    } else {
      setNewStatus('Deleted');
    }
    setDialogOpen(true);
  };

  const handleSendOrFulfill = () => {
    if (request.status === 'Pending') {
      setNewStatus('Pending Transfer');
    } else if (request.status === 'Pending Transfer') {
      setNewStatus('Fulfilled');
    }
    setDialogOpen(true);
  };

  const handleUpdateRequest = () => {
    // Create the barcode value
    const barcodeValue = `REQ${request.id}`;
    
    // Create a custom event that BarcodeListen will detect
    const customEvent = new CustomEvent('barcodescanned', { 
      detail: { barcode: barcodeValue }
    });
    
    // Dispatch the event to be caught by the BarcodeListen component
    window.dispatchEvent(customEvent);
  };

  const filteredItemsByDepartment = Object.entries(request?.itemsByDepartment || {}).reduce(
    (acc, [department, items]) => {
      const filteredItems = items.filter(item => excludeFulfilled ? item.qtytransferred === 0 : true);
      if (filteredItems.length > 0) {
        acc[department] = filteredItems;
      }
      return acc;
    },
    {}
  );

  const allItemsFulfilled = Object.values(filteredItemsByDepartment).length === 0;

  if (!request) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Paper style={{ padding: '20px' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <div>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" style={{ display: 'inline', marginLeft: '8px' }}>
              Back to Stock Requests
            </Typography>
          </div>
          <div>
            {/* Add the Update Request button here */}
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleUpdateRequest} 
              style={{ marginRight: '8px' }}
            >
              Update Request
            </Button>
            <Button variant="contained" color="primary" onClick={handlePrint} style={{ marginRight: '8px' }}>
              Print Request
            </Button>
            <Button
              variant="contained"
              style={{ marginRight: '8px', backgroundColor: request.status === 'Deleted' ? '#1976d2' : '#d32f2f', color: 'white' }}
              onClick={handleDeleteOrRecover}
            >
              {request.status === 'Deleted' ? 'Recover' : 'Delete Request'}
            </Button>
            {(request.status === 'Pending' || request.status === 'Pending Transfer') && (
              <Button variant="contained" color="secondary" onClick={handleSendOrFulfill}>
                {request.status === 'Pending' ? 'Mark as Sent' : 'Mark as Fulfilled'}
              </Button>
            )}
          </div>
        </Grid>
        <Grid item>
          <Typography variant="h4">Stock Request Details</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px' }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                ID
              </Typography>
              <Typography variant="h5" component="div">
                {request.id}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px' }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Request Date
              </Typography>
              <Typography variant="h5" component="div">
                {dayjs(request.dateplaced).format('YYYY-MM-DD')}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px', backgroundColor: statusBackgroundColor(request.status) }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Status
              </Typography>
              <Typography variant="h5" component="div">
                {request.status}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px' }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Store Name
              </Typography>
              <Typography variant="h5" component="div">
                {request.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '')}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px' }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Requested By
              </Typography>
              <Typography variant="h5" component="div">
                {request.createdBy}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: '16px', backgroundColor: priorityBackgroundColor(request.priority) }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Priority
              </Typography>
              <Typography variant="h5" component="div">
                {priorityText(request.priority)}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ padding: '16px' }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Notes
              </Typography>
              <Typography variant="h5" component="div">
                {request.notes || 'None'}
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={excludeFulfilled}
                onChange={() => setExcludeFulfilled(!excludeFulfilled)}
                color="primary"
              />
            }
            label="Exclude already Fulfilled items"
          />
        </Grid>
        <Grid item>
          {allItemsFulfilled ? (
            <div>
              <Typography variant="h6">
                All items have been fulfilled. Would you like to mark this as "{request.status === 'Pending' ? 'Pending Transfer' : 'Fulfilled'}"?
              </Typography>
              <Button variant="contained" color="secondary" onClick={handleSendOrFulfill}>
                Change Status to {request.status === 'Pending' ? 'Pending Transfer' : 'Fulfilled'}
              </Button>
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Qty Requested</TableCell>
                    <TableCell>Qty Fulfilled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(filteredItemsByDepartment).map(([department, items], deptIndex) => (
                    <React.Fragment key={deptIndex}>
                      <TableRow>
                        <TableCell colSpan={4} style={{ backgroundColor: '#f1f1f1', fontWeight: 'bold' }}>
                          {department}
                        </TableCell>
                      </TableRow>
                      {items.map((item, itemIndex) => (
                        <TableRow key={itemIndex}>
                          <TableCell>{item.sku}</TableCell>
                          <TableCell>{item.productname}</TableCell>
                          <TableCell>{item.qtyrequested}</TableCell>
                          <TableCell>{item.qtytransferred}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item style={{ display: 'none' }}>
          <div ref={barcodeRef}>
            <Barcode value={`REQ${request.id}`} displayValue={false} />
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to update Request #{request.id}'s status from "{request.status}" to "{newStatus}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{backgroundColor: "#9ba03b"}} onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button style={{backgroundColor: "grey"}} onClick={handleStatusChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {isPrinting && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
          color: 'white',
          fontSize: '20px'
        }}>
          <CircularProgress style={{ color: 'white', marginBottom: '20px' }} />
          <div>{printStatus}</div>
        </div>
      )}
    </Paper>
  );
};

export default ViewRequest;
