import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Tooltip, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NumberWidget from './widgets/NumberWidget';
import MostSoldItemsTable from './widgets/MostSoldItemsTable';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import authService from '../services/authService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Dashboard = () => {
  const { user } = useAuth0();
  const [selectedStore, setSelectedStore] = useState(JSON.parse(localStorage.getItem('selectedStore')) || '');
  const [salesData, setSalesData] = useState({
    bulkOrdersThisWeek: 0,
    bulkOrdersPendingCollection: 0,
    clickCollectAwaitingPacking: 0,
    clickCollectAwaitingPickup: 0,
    clickCollectAwaitingTransfer: 0,
    mostCommonlySoldItems: [],
    bulkOrdersThisWeekData: [],
    bulkOrdersPendingCollectionData: [],
    clickCollectAwaitingPackingData: [],
    clickCollectAwaitingPickupData: [],
    clickCollectAwaitingTransferData: []
  });
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [originalDialogTitle, setOriginalDialogTitle] = useState(''); // Save the original dialog title
  const [dialogData, setDialogData] = useState([]);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteDialogData, setNoteDialogData] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [addingNote, setAddingNote] = useState(false);
  const [newNote, setNewNote] = useState('');

  const searchInputRef = useRef(null);
  const pdfIframeRef = useRef(null);
  const addNoteRef = useRef(null);

  const fetchOrdersData = async (url, token) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error('Error fetching orders data', error);
      return [];
    }
  };

  const handleWidgetClick = (title, dataKey) => {
    setDialogTitle(title);
    setOriginalDialogTitle(title); // Save the original title
    setDialogData(salesData[dataKey]);
    setDialogOpen(true);
  };

  const updateSalesData = async () => {
    try {
      const bulkOrdersThisWeekUrl = `https://order.petfresh.com.au/wp-json/wc/v3/store-orders/${selectedStore.storename}`;
      const bulkOrdersPendingCollectionUrl = `https://order.petfresh.com.au/wp-json/wc/v3/store-orders-awaiting-pickup/${selectedStore.storename}`;
      const clickCollectAwaitingPackingUrl = `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${selectedStore.storename}?status=pickup`;
      const clickCollectAwaitingPickupUrl = `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${selectedStore.storename}?status=packed`;
      const clickCollectAwaitingTransferUrl = `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${selectedStore.storename}?status=click-collect-esc`;

      const bulkOrdersThisWeekToken = authService.getApiKey("bulk_system");
      const bulkOrdersPendingCollectionToken = authService.getApiKey("bulk_system");
      const clickCollectToken = authService.getApiKey("petfresh_backend");

      const [bulkOrdersThisWeek, bulkOrdersPendingCollection, clickCollectAwaitingPacking, clickCollectAwaitingPickup, clickCollectAwaitingTransfer] = await Promise.all([
        fetchOrdersData(bulkOrdersThisWeekUrl, bulkOrdersThisWeekToken),
        fetchOrdersData(bulkOrdersPendingCollectionUrl, bulkOrdersPendingCollectionToken),
        fetchOrdersData(clickCollectAwaitingPackingUrl, clickCollectToken),
        fetchOrdersData(clickCollectAwaitingPickupUrl, clickCollectToken),
        fetchOrdersData(clickCollectAwaitingTransferUrl, clickCollectToken),
      ]);

      setSalesData(prevData => ({
        ...prevData,
        bulkOrdersThisWeek: bulkOrdersThisWeek.length || 0,
        bulkOrdersPendingCollection: bulkOrdersPendingCollection.length || 0,
        clickCollectAwaitingPacking: clickCollectAwaitingPacking.length || 0,
        clickCollectAwaitingPickup: clickCollectAwaitingPickup.length || 0,
        clickCollectAwaitingTransfer: clickCollectAwaitingTransfer.length || 0,
        bulkOrdersThisWeekData: bulkOrdersThisWeek,
        bulkOrdersPendingCollectionData: bulkOrdersPendingCollection,
        clickCollectAwaitingPackingData: clickCollectAwaitingPacking,
        clickCollectAwaitingPickupData: clickCollectAwaitingPickup,
        clickCollectAwaitingTransferData: clickCollectAwaitingTransfer
      }));
      setLoading(false);
    } catch (error) {
      console.error('Error updating sales data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchMostSoldItems = async () => {
      try {
        const today = dayjs().tz('Australia/Perth');
        const startOfThisWeek = today.startOf('week').add(1, 'day'); // Monday
        const endOfThisWeek = startOfThisWeek.add(6, 'days'); // Sunday

        const lastWeekStart = startOfThisWeek.subtract(1, 'week'); // Last Monday
        const lastWeekEnd = lastWeekStart.add(6, 'days'); // Last Sunday

        const threeWeeksAgoStart = startOfThisWeek.subtract(3, 'week'); // Three Mondays ago
        const threeWeeksAgoEnd = threeWeeksAgoStart.add(6, 'days'); // Three Sundays ago

        const fetchPeriodData = async (startDate, endDate) => {
          const formattedStartDate = startDate.format('DDMMYYYY');
          const formattedEndDate = endDate.format('DDMMYYYY');
          const access_token = authService.getApiKey("wms");
          const response = await axios.get(
            `https://wms-api.neicha.com.au/sales?location_id=${selectedStore.id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`, {
              headers: {
                Authorization: `Bearer ${access_token}`
              }
            });
          return response.data.filter(item => item.productname);
        };

        const [thisWeekData, lastWeekData, threeWeeksAgoData] = await Promise.all([
          fetchPeriodData(startOfThisWeek, endOfThisWeek),
          fetchPeriodData(lastWeekStart, lastWeekEnd),
          fetchPeriodData(threeWeeksAgoStart, threeWeeksAgoEnd),
        ]);

        const combinedData = [...thisWeekData, ...lastWeekData, ...threeWeeksAgoData];

        const itemMap = combinedData.reduce((acc, item) => {
          if (!acc[item.sku]) {
            acc[item.sku] = {
              sku: item.sku,
              name: item.productname,
              thisWeek: 0,
              lastWeek: 0,
              threeWeeksAgo: 0,
              totalSold: 0,
              avgWeekly: 0,
              avgDaily: 0,
            };
          }
          acc[item.sku].totalSold += parseInt(item.total_sold, 10);
          return acc;
        }, {});

        thisWeekData.forEach(item => {
          if (itemMap[item.sku]) {
            itemMap[item.sku].thisWeek += parseInt(item.total_sold, 10);
          }
        });

        lastWeekData.forEach(item => {
          if (itemMap[item.sku]) {
            itemMap[item.sku].lastWeek += parseInt(item.total_sold, 10);
          }
        });

        threeWeeksAgoData.forEach(item => {
          if (itemMap[item.sku]) {
            itemMap[item.sku].threeWeeksAgo += parseInt(item.total_sold, 10);
          }
        });

        const sortedItems = Object.values(itemMap).sort((a, b) => b.totalSold - a.totalSold);

        sortedItems.forEach(item => {
          item.avgWeekly = Math.round((item.thisWeek + item.lastWeek + item.threeWeeksAgo) / 3);
          item.avgDaily = Math.round(item.avgWeekly / 7);
        });

        setSalesData(prevData => ({
          ...prevData,
          mostCommonlySoldItems: sortedItems,
        }));
      } catch (error) {
        console.error('Error fetching most sold items data', error);
      }
    };

    fetchMostSoldItems();
    updateSalesData();
  }, [selectedStore]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateSalesData();
    }, 300000); // 5 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (dialogOpen) {
      setTimeout(() => {
        searchInputRef.current && searchInputRef.current.focus();
      }, 100);
    }
  }, [dialogOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        handleAddNote();
      }
    };

    if (noteDialogOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [noteDialogOpen, newNote]);

  const filteredDialogData = dialogData.filter(order =>
    order.order_id.toString().includes(searchQuery) ||
    order.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.last_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredNoteData = noteDialogData.filter(note => !note.note.includes('📲'));

  const handleNoteDialogOpen = (notes, orderType, orderId, firstName, lastName) => {
    const type = orderType === 'bulk' ? 'Bulk Order' : 'Click and Collect Order';
    setNoteDialogData(notes);
    setDialogTitle(`Order Notes for ${type} #${orderId} - ${firstName} ${lastName}`);
    setNoteDialogOpen(true);
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('DD/MM/YYYY hh:mm A');
  };

  const handlePrintSlip = async (orderID, orderType) => {
    setSnackbarMessage(`Generating Slip...`);
    setSnackbarOpen(true);
    
    // Determine the correct URL and token based on orderType
    const url = orderType === 'Web'
      ? `https://www.petfresh.com.au/wp-json/wc/v3/bulk-packing-slips/?order_ids=${orderID}`
      : `https://order.petfresh.com.au/wp-json/wc/v3/bulk-packing-slips/?order_ids=${orderID}`;
    
    const token = orderType === 'Web'
      ? authService.getApiKey("petfresh_backend")
      : authService.getApiKey("bulk_system");
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    
    try {
      const response = await axios.get(url, { headers, responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      setPdfData(pdfUrl);
      setPdfDialogOpen(true);
      setSnackbarMessage(`Slip generated!`);
    } catch (error) {
      console.error('Error generating packing slip', error);
      setSnackbarMessage(`Error generating Slip`);
    }
  };  

  const handleConfirmUpdateStatus = (orderID, orderType, newStatus, apiUrl) => {
    setConfirmDialogData({ orderID, orderType, newStatus, apiUrl });
    setConfirmDialogOpen(true);
  };

  const handleUpdateStatus = async () => {
    const { orderID, newStatus, apiUrl, orderType } = confirmDialogData;
    const dateTime = dayjs().tz('Australia/Perth').format('DD/MM/YYYY HH:mm A');
    const storeName = selectedStore.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '');
    const data = {
      status: newStatus,
      note: `Order was marked as ${newStatus === 'completed' ? 'Collected/Completed' : 'Ready to Collect'} by ${user?.name || 'User'} on ${dateTime} (AWST) at the ${storeName}.`
    };
  
    const token = apiUrl.includes('order.petfresh.com.au')
      ? authService.getApiKey("bulk_system")
      : authService.getApiKey("petfresh_backend");
  
    setSnackbarMessage(`Updating ${orderType} #${orderID} to ${newStatus}`);
    setSnackbarOpen(true);
  
    try {
      await axios.post(apiUrl, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSnackbarMessage(`${orderType} #${orderID} updated!`);
      setConfirmDialogOpen(false);
      updateSalesData();
    } catch (error) {
      console.error('Error updating order status', error);
      setSnackbarMessage(`Error updating ${orderType} #${orderID}: ${error.message}`);
      setSnackbarOpen(true);
    }
  };  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePdfDialogClose = () => {
    setPdfDialogOpen(false);
    setPdfData(null);
  };

  const handleIframeLoad = () => {
    pdfIframeRef.current.contentWindow.print();
  };

  const handleAddNote = async () => {
    setSnackbarMessage(`Adding Order Note...`);
    setSnackbarOpen(true);
    setAddingNote(true);
    const orderId = dialogTitle.match(/#(\d+)/)[1];
    const isBulkOrder = dialogTitle.includes('Bulk Order');
    const apiUrl = isBulkOrder
      ? `https://order.petfresh.com.au/wp-json/wc/v3/orders/${orderId}/notes`
      : `https://www.petfresh.com.au/wp-json/wc/v3/orders/${orderId}/notes`;
    const token = isBulkOrder
      ? authService.getApiKey("bulk_system")
      : authService.getApiKey("petfresh_backend");
    const storeName = selectedStore.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '');

    try {
      await axios.post(apiUrl, { note: `${newNote} - Added by ${user?.name || 'User'} at the ${storeName}` }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSnackbarMessage(`Order Note added!`);
      setSnackbarOpen(true);
      setNewNote('');
      const updatedNotes = await fetchOrdersData(apiUrl, token);
      setNoteDialogData(updatedNotes);
    } catch (error) {
      console.error('Error adding order note', error);
      setSnackbarMessage(`Error adding Order Note: ${error.message}`);
      setSnackbarOpen(true);
    } finally {
      setAddingNote(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4">
        Welcome <b>{user?.name || 'User'}</b> to the <b>{selectedStore.storename.replace(/ - Western Australia/, '').replace(/\s*\([^)]*\)/g, '')}</b>!
      </Typography>
      <br />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} md={2.4}>
              <div style={{cursor: "pointer"}} onClick={() => handleWidgetClick(
                "Bulk Orders This Week",
                'bulkOrdersThisWeekData'
              )}>
                <NumberWidget 
                  title="Store Bulk Orders This Week" 
                  number={salesData.bulkOrdersThisWeek} 
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <div style={{cursor: "pointer"}} onClick={() => handleWidgetClick(
                "Bulk Orders Pending Collection",
                'bulkOrdersPendingCollectionData'
              )}>
                <NumberWidget 
                  title="Bulk Orders Pending Collection" 
                  number={salesData.bulkOrdersPendingCollection} 
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <div style={{cursor: "pointer"}} onClick={() => handleWidgetClick(
                "Click and Collect Awaiting Packing",
                'clickCollectAwaitingPackingData'
              )}>
                <NumberWidget 
                  title="Click and Collect Awaiting Packing" 
                  number={salesData.clickCollectAwaitingPacking} 
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <div style={{cursor: "pointer"}} onClick={() => handleWidgetClick(
                "Click and Collect Awaiting Pickup",
                'clickCollectAwaitingPickupData'
              )}>
                <NumberWidget 
                  title="Click and Collect Awaiting Pickup" 
                  number={salesData.clickCollectAwaitingPickup} 
                />
              </div>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <div style={{cursor: "pointer"}} onClick={() => handleWidgetClick(
                "Click and Collect Awaiting Transfer",
                'clickCollectAwaitingTransferData'
              )}>
                <NumberWidget 
                  title="Click and Collect Awaiting Transfer" 
                  number={salesData.clickCollectAwaitingTransfer} 
                />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {salesData.mostCommonlySoldItems.length > 0 ? (
              <MostSoldItemsTable items={salesData.mostCommonlySoldItems} />
            ) : (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Looks like the product sales data could not be fetched. Either this store is not doing well, or the system is down.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </Grid>
        </Grid>
      )}
      <Dialog open={dialogOpen} onClose={() => {
        setDialogOpen(false);
        setDialogTitle(originalDialogTitle); // Reset dialog title to the original title
      }} maxWidth="lg" fullWidth>
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label="close"
            onClick={() => {
              setDialogOpen(false);
              setDialogTitle(originalDialogTitle); // Reset dialog title to the original title
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loadingDialog ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField 
                fullWidth 
                label="Search" 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                sx={{ marginBottom: 2, marginTop: '0.5em' }}
                inputRef={searchInputRef}
              />
              {dialogData.length === 0 ? (
                <Typography>No Orders</Typography>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Number</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Current Status</TableCell>
                      <TableCell>Customer Note</TableCell>
                      <TableCell>Order Date</TableCell>
                      <TableCell>Items</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredDialogData.map((order) => (
                      <TableRow key={order.order_id}>
                        <TableCell>{order.order_id}</TableCell>
                        <TableCell>{`${order.first_name} ${order.last_name}`}</TableCell>
                        <TableCell>
                          {order.current_status === 'prod-scheduled' ? 'Awaiting Production' :
                          order.current_status === 'pending-fulfilment' ? 'Not Actioned Yet' :
                          order.current_status === 'ready-to-collect' ? 'Ready to Collect' :
                          order.current_status === 'waiting-for-store-transfer' ? 'Pending Transfer to Store' :
                          order.current_status === 'pickup' ? 'Awaiting Action' :
                          order.current_status === 'packed' ? 'Ready to Collect' :
                          order.current_status === 'click-collect-esc' ? 'Escalated to Warehouse' :
                          order.current_status === 'store-transfer' ? 'Awaiting Store Transfer' :
                          order.current_status === 'ready-collect' ? 'Ready to Collect' :
                          order.current_status}
                        </TableCell>
                        <TableCell>{order.customer_note}</TableCell>
                        <TableCell>{formatDate(order.order_date)}</TableCell>
                        <TableCell>
                          {order.order_items.map((item, index) => (
                            <Tooltip key={index} title={item.Status || ''}>
                              <div>
                                {item.title} x {item.qty} {item.title.includes('Box') ? (item.qty > 1 ? 'boxes' : 'box') : (item.qty > 1 ? 'kgs' : 'kg')} {item.Status && (
                                  <CheckCircleIcon sx={{ color: 'green', fontSize: '1rem' }} />
                                )}
                              </div>
                            </Tooltip>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Button
                              onClick={() => handleNoteDialogOpen(order.order_notes, order.orderType, order.order_id, order.first_name, order.last_name)}
                              sx={{ 
                                backgroundColor: '#9ba03b', 
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: 'black',
                                  color: 'white'
                                }
                              }}
                            >
                              Updates
                            </Button>
                            <Button
                              onClick={() => handlePrintSlip(order.order_id, order.orderType)}
                              sx={{ 
                                backgroundColor: '#9ba03b', 
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: 'black',
                                  color: 'white'
                                }
                              }}
                            >
                              Print
                            </Button>
                            {/* Other buttons and actions */}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={noteDialogOpen} onClose={() => {
        setNoteDialogOpen(false);
        setDialogTitle(originalDialogTitle); // Reset dialog title to the original title
      }} maxWidth="lg" fullWidth>
        <DialogTitle>
          {dialogTitle}
          <IconButton
            aria-label="close"
            onClick={() => {
              setNoteDialogOpen(false);
              setDialogTitle(originalDialogTitle); // Reset dialog title to the original title
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField 
              fullWidth 
              label="Add Note" 
              value={newNote} 
              onChange={(e) => setNewNote(e.target.value)} 
              disabled={addingNote}
              inputRef={addNoteRef}
            />
            <Button
              onClick={handleAddNote}
              variant="contained"
              color="primary"
              disabled={addingNote}
            >
              SAVE
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNoteData.map((note, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(note.date)}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html: note.note }} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          Confirm Update
          <IconButton
            aria-label="close"
            onClick={() => setConfirmDialogOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to update {confirmDialogData.orderType} #{confirmDialogData.orderID} to {confirmDialogData.newStatus === 'completed' ? 'Completed' : 'Ready to Collect'}?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button
              onClick={() => setConfirmDialogOpen(false)}
              variant="outlined"
              sx={{ color: 'red', borderColor: 'red' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleUpdateStatus}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={pdfDialogOpen} onClose={handlePdfDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Packing Slip
          <IconButton
            aria-label="close"
            onClick={handlePdfDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {pdfData && (
            <iframe
              ref={pdfIframeRef}
              src={pdfData}
              title="Packing Slip"
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              onLoad={handleIframeLoad}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('Error') ? 'error' : 'info'} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;
