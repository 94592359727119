import axios from 'axios';
import authConfig from '../auth_config.json';

// Token storage keys
const STORAGE_KEYS = {
  API_KEYS: 'apiKeys',
  AUTH_STATE: 'auth_state'
};

// Service to sessionStorage mapping
const SERVICE_TO_SESSION_KEY = {
  'wms': 'wms_access_token',
  'bulk_system': 'pfresh_bulk_jwt_token',
  'petfresh_backend': 'petfresh_jwt_token',
  'qpilot': 'qpilot_token'
};

class AuthService {
  constructor() {
    this.listeners = [];
    this.isRefreshingToken = false;
    this.isFetchingApiKeys = false;
    
    // Keep track of Auth0's getTokenSilently function
    this.getTokenSilently = null;
    
    // Make sure axios interceptor is set up
    this.setupAxiosInterceptor();
  }
  
  // Add event listener
  addListener(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }
  
  // Notify all listeners
  notifyListeners(event) {
    this.listeners.forEach(listener => listener(event));
  }
  
  // Initialize with Auth0 client functions
  async init(auth0Client) {
    console.log("Initializing auth service with Auth0 client");
    
    if (auth0Client?.getTokenSilently) {
      this.getTokenSilently = auth0Client.getTokenSilently;
      
      // Try to get an initial token if we don't have it
      try {
        const accessToken = this.getAccessToken();
        if (!accessToken) {
          console.log("No access token found, attempting to get one");
          const token = await auth0Client.getTokenSilently();
          if (token) {
            console.log("Successfully got initial token");
          }
        }
      } catch (error) {
        console.error("Error getting initial token:", error);
      }
    }
    
    return Promise.resolve(!!this.getTokenSilently);
  }
  
  // Get current access token
  getAccessToken() {
    // Get Auth0 access token - stored by Auth0 SDK
    const tokenKey = `@@auth0spajs@@::${authConfig.clientId}::${authConfig.audience}::${authConfig.scope}`;
    const tokenDataStr = localStorage.getItem(tokenKey);
    
    if (!tokenDataStr) return null;
    
    try {
      const tokenData = JSON.parse(tokenDataStr);
      
      // Different token structure handling
      if (tokenData.body?.access_token) {
        return tokenData.body.access_token;
      } else if (tokenData.access_token) {
        return tokenData.access_token;
      }
      
      console.error("Token found but has unexpected structure:", 
        JSON.stringify({...tokenData, body: tokenData.body ? "[PRESENT]" : "[MISSING]"}));
      return null;
    } catch (e) {
      console.error("Error parsing access token:", e);
      return null;
    }
  }
  
  // Refresh access token using Auth0
  async refreshAccessToken() {
    if (this.isRefreshingToken) {
      console.log("Already refreshing token, skipping");
      return;
    }
    
    console.log("Starting token refresh");
    this.isRefreshingToken = true;
    this.notifyListeners({ type: 'refreshingToken', isRefreshing: true });
    
    try {
      // NEW: Try to ensure we have auth state in this tab
      this.ensureAuthStateForNewTab();
      
      // Use Auth0's built-in refresh mechanism
      if (this.getTokenSilently) {
        const token = await this.getTokenSilently({ ignoreCache: true });
        console.log("Token refreshed successfully");
        
        // Get new API keys with the refreshed token
        await this.fetchApiKeys(token);
        
        this.notifyListeners({ type: 'tokenRefreshed', success: true });
        return true;
      } else {
        console.error("No getTokenSilently method available");
        // Force page reload to recover auth state
        window.location.reload();
        this.notifyListeners({ 
          type: 'tokenRefreshError', 
          error: 'Authentication service not initialized properly'
        });
        return false;
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      this.notifyListeners({ 
        type: 'tokenRefreshError', 
        error: error.message || 'Failed to refresh token'
      });
      return false;
    } finally {
      this.isRefreshingToken = false;
      this.notifyListeners({ type: 'refreshingToken', isRefreshing: false });
    }
  }
  
  // Fetch API-specific tokens
  async fetchApiKeys(accessToken = null) {
    if (this.isFetchingApiKeys) return;
    
    this.isFetchingApiKeys = true;
    this.notifyListeners({ type: 'fetchingApiKeys', isFetching: true });
    
    try {
      // First ensure we can access Auth0 client in this tab
      this.ensureAuthStateForNewTab();
      
      // Use provided token or get current access token
      let token = accessToken;
      if (!token) {
        token = this.getAccessToken();
        // If no token, try to get a fresh one
        if (!token && this.getTokenSilently) {
          console.log("No token found, getting fresh token");
          try {
            token = await this.getTokenSilently({ ignoreCache: true });
          } catch (tokenError) {
            console.error("Failed to get fresh token:", tokenError);
          }
        }
      }
      
      // If still no token, we can't proceed
      if (!token) {
        throw new Error('No access token available');
      }
      
      console.log("Fetching API keys with access token");
      
      const response = await fetch('https://api.neicha.com.au/auth/generate-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          services: ["wms", "bulk_system", "petfresh_backend", "qpilot"]
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error("API key fetch failed:", errorData);
        
        if (errorData.expired) {
          // If token expired, try to refresh
          await this.refreshAccessToken();
        }
        
        throw new Error(errorData.error || `Failed with status ${response.status}`);
      }
      
      const data = await response.json();
      const errors = data.errors || {};
      const tokens = data.tokens || {};
      
      // Store in localStorage
      localStorage.setItem(STORAGE_KEYS.API_KEYS, JSON.stringify(tokens));
      
      // Also store in sessionStorage for backward compatibility
      Object.entries(tokens).forEach(([service, token]) => {
        const sessionKey = SERVICE_TO_SESSION_KEY[service];
        if (sessionKey) {
          sessionStorage.setItem(sessionKey, token);
        }
      });
      
      this.notifyListeners({ 
        type: 'apiKeysUpdated', 
        tokens: tokens,
        errors: errors
      });
      
      // Show errors if any service denied
      if (Object.keys(errors).length > 0) {
        this.notifyListeners({
          type: 'apiKeyError',
          errors: errors
        });
      }
      
      return { tokens, errors };
    } catch (error) {
      console.error("Error fetching API keys:", error);
      this.notifyListeners({ 
        type: 'apiKeyFetchError', 
        error: error.message || 'Failed to fetch API keys'
      });
      throw error;
    } finally {
      this.isFetchingApiKeys = false;
      this.notifyListeners({ type: 'fetchingApiKeys', isFetching: false });
    }
  }
  
  // Get stored API keys
  getApiKeys() {
    const apiKeys = localStorage.getItem(STORAGE_KEYS.API_KEYS);
    return apiKeys ? JSON.parse(apiKeys) : {};
  }
  
  // Get a specific API key (with fallback to sessionStorage)
  getApiKey(service) {
    // First try localStorage
    const apiKeys = this.getApiKeys();
    const token = apiKeys[service];
    
    if (token) return token;
    
    // Fallback to sessionStorage
    const sessionKey = SERVICE_TO_SESSION_KEY[service];
    return sessionKey ? sessionStorage.getItem(sessionKey) : null;
  }
  
  // Clear all auth data
  clearAuthData() {
    // Clear Auth0 data
    const auth0TokenKey = `@@auth0spajs@@::${authConfig.clientId}::${authConfig.audience}::${authConfig.scope}`;
    const auth0UserKey = `@@auth0spajs@@::${authConfig.clientId}::@@user@@`;
    
    localStorage.removeItem(auth0TokenKey);
    localStorage.removeItem(auth0UserKey);
    localStorage.removeItem(STORAGE_KEYS.API_KEYS);
    
    // Clear session storage
    Object.values(SERVICE_TO_SESSION_KEY).forEach(key => {
      sessionStorage.removeItem(key);
    });
    
    this.notifyListeners({ type: 'authCleared' });
  }
  
  // Force token refresh
  forceTokenRefresh() {
    console.log("Manually forcing token refresh");
    return this.refreshAccessToken();
  }
  
  // Set up axios interceptor for automatic token refresh
  setupAxiosInterceptor() {
    axios.interceptors.response.use(
      response => response,
      async error => {
        // Handle 401/403 errors
        if ((error.response?.status === 401 || error.response?.status === 403) && 
            !error.config._isRetry) {
          console.log(`${error.response.status} response, attempting token refresh`);
          
          try {
            error.config._isRetry = true;
            
            await this.refreshAccessToken();
            
            // Update the authorization header based on the URL
            const service = this.getServiceFromUrl(error.config.url);
            if (service) {
              const token = this.getApiKey(service);
              if (token) {
                error.config.headers.Authorization = `Bearer ${token}`;
              }
            } else {
              const token = this.getAccessToken();
              if (token) {
                error.config.headers.Authorization = `Bearer ${token}`;
              }
            }
            
            return axios(error.config);
          } catch (refreshError) {
            console.error("Token refresh failed during retry:", refreshError);
            return Promise.reject(error);
          }
        }
        
        return Promise.reject(error);
      }
    );
  }
  
  // Determine which service a URL belongs to
  getServiceFromUrl(url) {
    if (!url) return null;
    
    if (url.includes('petfresh.com.au')) return 'petfresh_backend';
    if (url.includes('wms-api.neicha.com.au')) return 'wms';
    if (url.includes('bulk.petfresh.com.au')) return 'bulk_system';
    if (url.includes('qpilot')) return 'qpilot';
    
    return null;
  }
  
  // Check if we need to refresh on wake/visibility
  handleVisibilityChange(isAuthenticated) {
    // Only do this if authenticated
    if (!isAuthenticated) return;
    
    // Check if token is valid or refresh needed
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      console.log("No access token, attempting refresh");
      this.refreshAccessToken();
      return;
    }
    
    // Check if API keys exist
    const apiKeys = this.getApiKeys();
    if (Object.keys(apiKeys).length === 0) {
      console.log("No API keys, fetching");
      this.fetchApiKeys();
    }
  }

  // Add this method to the AuthService class
  async setAuthData(user, tokenData) {
    console.log("Setting auth data with user and token data");
    
    if (!user || !tokenData || !tokenData.access_token) {
      console.error("Invalid user or token data:", { 
        userProvided: !!user, 
        tokenDataProvided: !!tokenData,
        accessTokenProvided: tokenData ? !!tokenData.access_token : false 
      });
      return false;
    }
    
    try {
      // Store user in memory for reference
      this.user = user;
      
      // Use the token to fetch API keys
      await this.fetchApiKeys(tokenData.access_token);
      
      return true;
    } catch (error) {
      console.error("Error in setAuthData:", error);
      return false;
    }
  }

  // Add this method to share Auth0 state between tabs
  ensureAuthStateForNewTab() {
    if (this.getTokenSilently) {
      // Already initialized in this tab
      return true;
    }
    
    console.log("New tab detected, attempting to recover Auth0 client");
    
    // For new tabs, we need to initialize from Auth0's existing state
    if (window.auth0 && window.auth0.getTokenSilently) {
      console.log("Found Auth0 client on window object");
      this.getTokenSilently = window.auth0.getTokenSilently;
      return true;
    }
    
    return false;
  }

  // Add this to ensure the tokens are synced between storage methods
  ensureStorageSynced() {
    // Get tokens from localStorage
    const apiKeys = this.getApiKeys();
    
    // Update sessionStorage with current values
    Object.entries(apiKeys).forEach(([service, token]) => {
      const sessionKey = SERVICE_TO_SESSION_KEY[service];
      if (sessionKey && token) {
        sessionStorage.setItem(sessionKey, token);
      }
    });
    
    // Return a reference to the apiKeys
    return apiKeys;
  }
}

const authService = new AuthService();

// Make authService available globally for debugging
window.authService = authService;

// Export singleton
export default authService;