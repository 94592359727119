import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TextField,
  Button,
  MenuItem,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import {
  Add,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  ControlPoint as ControlPointIcon,
  ExpandLess,
  ExpandMore,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth0 } from '@auth0/auth0-react';
import { Stepper, Step, StepLabel } from "@mui/material";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import authService from '../services/authService';

const CreateBulkOrder = () => {
  const token = authService.getApiKey("bulk_system");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [accordionManuallyOpened, setAccordionManuallyOpened] = useState(false);
  const [orderedItems, setOrderedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rightPanelLoading, setRightPanelLoading] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);
  const [collectionWeeks, setCollectionWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [collectionStore, setCollectionStore] = useState('');
  const [pastCutoffModal, setPastCutoffModal] = useState({ open: false, date: '' });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [lastThreeOrders, setLastThreeOrders] = useState([]);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [outOfStockModal, setOutOfStockModal] = useState({ open: false, productName: '' });
  const [products, setProducts] = useState([]);
  const [stores, setStores] = useState([]);
  const [customerModalType, setCustomerModalType] = useState('create'); // 'create', 'update', 'select'
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [preferredStore, setPreferredStore] = useState('');
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [placeOrderModalOpen, setPlaceOrderModalOpen] = useState(false);
  const [tempQuantities, setTempQuantities] = useState({});
  const [posNoteModal, setPosNoteModal] = useState({ open: false, product: null });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [orderNote, setOrderNote] = useState('');
  const { user } = useAuth0();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [paymentData, setPaymentData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Confirm Order", "Select Payment Method", "Order Placed"];
  const [totalAmount, setTotalAmount] = useState(0);
  const [posReference, setPosReference] = useState('');
  const [orderConfirmation, setOrderConfirmation] = useState(null);
  const [customProductName, setCustomProductName] = useState('');
  const [customProductPrice, setCustomProductPrice] = useState('');
  const [customProductDialogOpen, setCustomProductDialogOpen] = useState(false);
  const isElectronPOS = navigator.userAgent.includes("ElectronPOS");
  let aboutBlankWindow = null;
  const [customProductQty, setCustomProductQty] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to refresh? Any unsaved data will not be saved.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    // Allow only numeric input
    if (!/^\d*$/.test(value)) {
      setError("Please enter a valid phone number in the format '04xxxxxxxx' (no spaces).");
      setSnackbarOpen(true);
      return;
    }

    // Ensure first character is 0 and length is at most 10 digits
    if (value.length > 0 && (!value.startsWith("0") || value.length > 10)) {
      setError("Please enter a valid phone number in the format '04xxxxxxxx' (no spaces).");
      setSnackbarOpen(true);
      return;
    }

    setError('');
    setSnackbarOpen(false);
    setPhoneNumber(value);

    if (value.length < 10) {
      setSelectedCustomer(null);
      return;
    }
  };

  const handleSearchCustomer = () => {
    if (phoneNumber.length !== 10) return; // Prevent searching if the number is incomplete

    setPhoneLoading(true);
    setPhoneDisabled(true); // Disable input during search

    fetch(`https://order.petfresh.com.au/wp-json/wc/v3/get-customers?search=${phoneNumber}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.length === 0) {
          setCustomerModalType("create");
          setCustomerModalOpen(true);
        } else {
          setCustomerModalType("select");
          setCustomers(data);
          setCustomerModalOpen(true);
        }
      })
      .catch(() => {
        setError("Failed to fetch customers. Please try again later.");
        setSnackbarOpen(true);
      })
      .finally(() => {
        setPhoneLoading(false);
        setPhoneDisabled(false);
      });
  };

  const handleEditCustomer = () => {
    if (!selectedCustomer) return;

    setFirstName(selectedCustomer.first_name);
    setLastName(selectedCustomer.last_name);
    setEmail(selectedCustomer.email);
    setPhoneNumber(selectedCustomer.phone);
    setPreferredStore(selectedCustomer.preferred_store);
    setError(''); // Reset error message

    setCustomerModalType('update');
    setCustomerModalOpen(true);
  };

  const groupedProducts = (products || [])
  .filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .reduce((groups, product) => {
    if (product.categories && Array.isArray(product.categories)) {
      product.categories.forEach((category) => {
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(product);
      });
    }
    return groups;
  }, {});

  useEffect(() => {
    if (!customerModalOpen && !historyModalOpen && !placeOrderModalOpen) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [customerModalOpen, historyModalOpen, placeOrderModalOpen]);

  if (!groupedProducts || Object.keys(groupedProducts).length === 0) {
    console.warn('No products to display. Check the data structure or API response.');
  }

  if (!Array.isArray(products)) {
    console.error("Expected an array for 'bulk_products'. Check localStorage data structure.");
  }
  if (!Array.isArray(stores)) {
    console.error("Expected an array for 'bulk_stores'. Check API response or localStorage.");
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storedProducts = localStorage.getItem('bulk_products');
        const storedStores = localStorage.getItem('bulk_stores');
  
        // If no local storage or older than 12h, fetch fresh
        if (!storedProducts || Date.now() - new Date(JSON.parse(storedProducts).timestamp) > 12 * 60 * 60 * 1000) {
          const productResponse = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/get-products', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const productsData = await productResponse.json();
  
          // Ensure prices are numbers
          const sanitizedProducts = productsData.map(product => ({
            ...product,
            price: Number(product.price) || 0
          }));
  
          localStorage.setItem(
            'bulk_products', 
            JSON.stringify({ data: sanitizedProducts, timestamp: Date.now() })
          );
  
         setProducts(sanitizedProducts);
        } else {
         // Already in localStorage; parse and set
         setProducts(JSON.parse(storedProducts).data || []);
        }
  
        if (!storedStores || Date.now() - JSON.parse(storedStores).timestamp > 12 * 60 * 60 * 1000) {
          const storeResponse = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/stores', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const storesData = await storeResponse.json();
  
          localStorage.setItem(
            'bulk_stores',
            JSON.stringify({ data: storesData.data, timestamp: Date.now() })
          );
  
         setStores(storesData.data);
        } else {
         setStores(JSON.parse(storedStores).data || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setCustomerModalOpen(false);
    setPhoneNumber(customer.phone);
    setError('');
    setSnackbarOpen(false);
  };

  const handleTempQuantityChange = (product, change) => {
    setTempQuantities((prev) => {
      const currentQty = prev[product.name] ?? product.default_qty ?? 5;
      const newQty = Math.max(1, currentQty + change); // Ensure it doesn't go below 1
      return { ...prev, [product.name]: newQty };
    });
  };

  const handleConfirmOrder = () => {
    setOverlayLoading(true); // Show loading spinner
  
    let paymentDetails = {};
    let paymentMethod = "";
  
    switch (selectedPaymentMethod) {
      case "manual":
        paymentMethod = "cheque";
        paymentDetails = { payment_method: "cheque", payment_data: { amount: totalAmount, pos_reference: posReference } };
        break;
      case "in_person":
        paymentMethod = "cod";
        paymentDetails = { payment_method: "cod", payment_data: { amount: totalAmount } };
        break;
      case "stripe":
        paymentMethod = "stripe";
        paymentDetails = {
          payment_method: "stripe",
          payment_data: {
            _stripe_charge_id: paymentData?.stripe_charge_id || "",
            _stripe_intent_id: paymentData?.stripe_intent_id || "",
            _stripe_payment_method_id: paymentData?.stripe_payment_method_id || "",
            amount: totalAmount
          }
        };
        break;
      default:
        console.error("Unknown payment method");
        setOverlayLoading(false);
        return;
    }
  
    const orderData = {
      customer_id: selectedCustomer?.user_id || null,
      customer_first_name: selectedCustomer?.first_name || "",
      customer_last_name: selectedCustomer?.last_name || "",
      customer_mobile: selectedCustomer?.phone || "",
      order_notes: orderNote || "",
      collection_store: collectionStore,
      placed_by: user?.name || "Unknown - VIA WMS",
      production_week: selectedWeek,
      products: orderedItems.map(({ sku, name, qty, price }) => ({
        sku,
        name,
        quantity: qty,
        price,
        type: sku.startsWith("CUSTOM-") ? "Arbitrary" : "Product",
      })),
      payment_method: paymentMethod,
      payment_data: paymentDetails.payment_data
    };
  
    fetch("https://order.petfresh.com.au/wp-json/wc/v3/create-order", {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(orderData),
    })
      .then((res) => res.json())
      .then((data) => {
      // Instead, jump to the "Order Placed" step (Step 2) or just setActiveStep(2)
     setActiveStep(2);
     setOrderConfirmation(data);    
      })
      .catch((error) => {
        console.error("Error placing order:", error);
        setSnackbarMessage("Failed to place order. Please try again.");
        setSnackbarOpen(true);
      })
      .finally(() => setOverlayLoading(false)); // Hide loading spinner after request finishes
  };  

  const selectedStyle = {
    border: "2px solid #9ba03b",
    cursor: "pointer",
    backgroundColor: "#f2f3e4",
  };

  const defaultStyle = {
    border: "1px solid #ddd",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "#f9f9f9" },
  };

  const resetOrder = () => {
    setOrderedItems([]);
    setSelectedCustomer(null);
    setCollectionStore('');
    setSelectedWeek('');
    setOrderConfirmation(null);
    setTotalAmount(0);
    setPosReference('');
    setSelectedPaymentMethod("");
    setPhoneNumber('');
    setOrderNote('');
  };

  useEffect(() => {
    setTotalAmount(orderedItems.reduce((acc, item) => acc + item.price * item.qty, 0));
  }, [orderedItems]);

  const CloseButton = ({ onClick }) => (
    <Button
      onClick={() => {
        setSelectedCustomer(null); // Clear the selected customer
        onClick(); // Trigger any additional onClick functionality
      }}
      sx={{
        backgroundColor: 'red',
        color: 'white',
        '&:hover': { backgroundColor: 'darkred' },
      }}
    >
      Close
    </Button>
  );

  const handlePlaceOrderHover = (isHovering) => {
    if (isHovering && !accordionManuallyOpened) {
      setIsAccordionOpen(true);
    } else if (!isHovering && !accordionManuallyOpened) {
      setIsAccordionOpen(false);
    }

    if (!isHovering) {
      setTimeout(() => setShowSnackbar(false), 500); // Short delay before hiding
      return;
    }

    const missingFields = [];
    if (!selectedCustomer) missingFields.push("Customer Not Selected");
    if (!selectedWeek) missingFields.push("Collection Week Not Set");
    if (!collectionStore) missingFields.push("Collection Store Not Set");
    if (orderedItems.length === 0) missingFields.push("No Items Added");

    if (missingFields.length > 0) {
      setSnackbarMessage(`Missing Order Fields:\n• ${missingFields.join('\n• ')}`);
      setShowSnackbar(true);
    } else {
      setShowSnackbar(false);
    }
  };

  useEffect(() => {
    fetchCollectionWeeks();
  }, []);

  const fetchCollectionWeeks = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/available-sundays/', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCollectionWeeks(data);
    } catch (error) {
      console.error('Error fetching collection weeks:', error);
    } finally {
      setLoading(false);
    }
  };

  const getTimeDifference = (date) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    if (diffDays < 7) return `${diffDays} days ago`;
    if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
    if (diffDays < 365) {
      const months = Math.floor(diffDays / 30);
      const weeks = Math.floor((diffDays % 30) / 7);
      return `${months} months${weeks ? ` and ${weeks} weeks` : ''} ago`;
    }
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    return `${years} years${months ? ` and ${months} months` : ''} ago`;
  };

  const handlePhoneNumberUpdate = (value) => {
    // Allow only numeric input
    if (!/^\d*$/.test(value)) {
      setError("Please only enter a phone number in the format '04xxxxxxxx' without spaces or international formatting.");
      return;
    }

    // Ensure it starts with '0' and has at most 10 digits
    if (value.length > 0 && (!value.startsWith("0") || value.length > 10)) {
      setError("Please only enter a phone number in the format '04xxxxxxxx' without spaces or international formatting.");
      return;
    }

    setError('');
    setPhoneNumber(value);
  };

  const handleUpdateCustomer = async () => {
    setOverlayLoading(true);

    const updatedCustomer = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      preferred_store: preferredStore,
    };

    try {
      const response = await fetch(
        `https://order.petfresh.com.au/wp-json/wc/v3/update-customer/${selectedCustomer.user_id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedCustomer),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update customer.');
      }

      // Update selectedCustomer state with the new details
      setSelectedCustomer(data.data);
      setCustomerModalOpen(false);
    } catch (error) {
      setError('Failed to update customer. Please try again.');
    } finally {
      setOverlayLoading(false);
    }
  };

  const handlePhoneBlur = () => {
    if (phoneNumber.length !== 10 || !phoneNumber.startsWith("04")) {
      setError("Please enter a valid Australian mobile number in the format '04xxxxxxxx'.");
      setSnackbarOpen(true);
    } else {
      setError("");
      setSnackbarOpen(false);
      // Trigger customer search or other actions here
    }
  };


  const handleShowOrderHistory = () => {
    const orders = selectedCustomer.last_orders.slice(0, 3);
    setLastThreeOrders(orders);
    setHistoryModalOpen(true);
  };

  const handleWeekChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = collectionWeeks.find((week) => week.Date === selectedValue);
    setSelectedWeek(selectedValue);

    if (selectedOption?.['Past Cutoff']) {
      setPastCutoffModal({ open: true, date: selectedOption.Date });
    }
  };

  const closePastCutoffModal = () => {
    setPastCutoffModal({ open: false, date: '' });
    setSelectedWeek('');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && customers.length === 1 && customerModalType === 'select') {
        handleSelectCustomer(customers[0]); // Auto-select the first customer
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [customers, customerModalType]);

  const handleCreateCustomer = async () => {
    setOverlayLoading(true);
    const newCustomer = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone: phoneNumber,
      preferred_store: preferredStore,
    };

    try {
      const response = await fetch('https://order.petfresh.com.au/wp-json/wc/v3/create-customer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCustomer),
      });

      const data = await response.json();

      if (response.status === 400) {
        if (data.code === 'existing_user_email' || data.code === 'existing_user_phone') {
          setCustomerModalType('select');
          setSelectedCustomer(data.data.existing_user);
          return; // Stop further processing
        }
      }

      setCustomerModalOpen(false); // Close only after success
      handleSelectCustomer(data.data);
    } catch (error) {
      setError('Failed to create customer. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setOverlayLoading(false);
    }
  };

  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const handleAddCustomProduct = () => {
    setOrderedItems([...orderedItems, {
      name: customProductName,
      sku: `CUSTOM-${Date.now()}`,
      qty: customProductQty, // Capture custom quantity
      price: parseFloat(customProductPrice),
      type: "Arbitrary",
    }]);    
    setCustomProductDialogOpen(false);
  };

  const addProductToOrder = (product, quantity) => {
    setOrderedItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.name === product.name);
      if (existingItem) {
        return prevItems.map((item) =>
          item.name === product.name ? { ...item, qty: item.qty + quantity } : item
        );
      } else {
        return [...prevItems, { ...product, qty: quantity, price: Number(product.price) || 0 }];
      }
    });

    setTempQuantities((prev) => {
      const newQuantities = { ...prev };
      delete newQuantities[product.name]; // Remove from tempQuantities
      return newQuantities;
    });

    setIsAccordionOpen(true); // Ensure the accordion opens
  };

  const handleAddToOrder = (product) => {
    const quantity = tempQuantities[product.name] ?? product.default_qty ?? 5;
    
    if (product.stock_status === 'out_of_stock') {
      // Show out-of-stock modal first, then POS note (if applicable)
      setOutOfStockModal({
        open: true,
        product: product,
        onClose: () => {
          if (product.pos_note?.trim()) {
            setPosNoteModal({ open: true, product });
          } else {
            addProductToOrder(product, quantity);
          }
        },
      });
      return;
    }

    if (product.pos_note?.trim()) {
      setPosNoteModal({ open: true, product });
      return;
    }

    addProductToOrder(product, quantity);
  };

  const handleQuantityChange = (product, change) => {
    setOrderedItems((prevItems) =>
      prevItems.map((item) =>
        item.name === product.name
          ? { ...item, qty: Math.max(1, item.qty + change) }
          : item
      )
    );
  };

  const handleRemoveItem = (index) => {
    setOrderedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  // POS Customer Display Order Show.
  useEffect(() => {
    if (isElectronPOS && placeOrderModalOpen && activeStep === 0) {
      // Get screen dimensions for full-screen window
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      // Open about:blank window in full screen
      aboutBlankWindow = window.open(
        "about:blank",
        "_blank",
        `width=${screenWidth},height=${screenHeight},top=0,left=0`
      );

      if (aboutBlankWindow) {
        const doc = aboutBlankWindow.document;
        doc.open();
        doc.write(`
        <html>
          <head>
            <title>Order Confirmation</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 20px; }
              table { width: 100%; border-collapse: collapse; margin-top: 10px; }
              th, td { border: 0.5px solid black; padding: 8px; text-align: left; }
              th { background-color: #f2f2f2; }
              .table-key { background: #D9D9D9; font-weight: bold; padding-left: 1em; }
              .confirm-blk-table-row > td { border: 0.5px solid black; }
            </style>
          </head>
          <body>
            <h2>Order Confirmation</h2>
            <table>
              <tbody>
                <tr class="confirm-blk-table-row">
                  <td class="table-key">Customer Name</td>
                  <td>${selectedCustomer ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}` : "N/A"}</td>
                  <td class="table-key">Customer Email</td>
                  <td>${selectedCustomer?.email || "N/A"}</td>
                </tr>
                <tr class="confirm-blk-table-row">
                  <td class="table-key">Phone</td>
                  <td>${selectedCustomer?.phone || "N/A"}</td>
                  <td class="table-key">Collection Store</td>
                  <td>${collectionStore || "N/A"}</td>
                </tr>
                <tr class="confirm-blk-table-row">
                  <td class="table-key">Collection Week</td>
                  <td>${selectedWeek || "N/A"}</td>
                  <td class="table-key">Placed By</td>
                  <td>${user.name || "N/A"}</td>
                </tr>
                <tr class="confirm-blk-table-row">
                  <td class="table-key">Notes</td>
                  <td colspan="3">${orderNote || "None"}</td>
                </tr>
                <tr class="confirm-blk-table-row">
                  <td class="table-key" colspan="4"><strong>Order Items</strong></td>
                </tr>
                ${orderedItems
            .map(
              (item) =>
                `<tr class="confirm-blk-table-row">
                        <td colspan="2">${item.name}</td>
                        <td>${item.qty} kg</td>
                        <td>$${item.price.toFixed(2)}</td>
                      </tr>`
            )
            .join("")}
              </tbody>
            </table>
          </body>
        </html>
      `);
        doc.close();
      }
    }

    return () => {
      // Ensure about:blank window is closed when modal is exited
      if (aboutBlankWindow && (!placeOrderModalOpen || activeStep > 0)) {
        aboutBlankWindow.close();
        aboutBlankWindow = null;
      }
    };
  }, [placeOrderModalOpen, activeStep]);

  const renderCustomerModalContent = () => {
    switch (customerModalType) {
      case 'create':
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h6">Create New Customer</Typography>
              <Button onClick={() => setCustomerModalOpen(false)}>Close</Button>
            </Box>
            <TextField
              label="First Name"
              fullWidth
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ marginBottom: "1em" }}
            />
            <TextField
              label="Last Name"
              fullWidth
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{ marginBottom: "1em" }}
            />
            <TextField
              label="Email Address"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: "1em" }}
            />
            <TextField
              label="Mobile Phone Number"
              fullWidth
              required
              value={phoneNumber}
              sx={{ mt: 2 }}
              disabled
              style={{ marginBottom: "1em" }}
            />
            <TextField
              select
              label="Preferred Store"
              fullWidth
              required
              value={preferredStore}
              onChange={(e) => setPreferredStore(e.target.value)}
            >
              {Array.isArray(stores) &&
                stores.map((store) => (
                  <MenuItem key={store.id} value={store.g_order_collection_store}>
                    {store.g_order_collection_store}
                  </MenuItem>
                ))}
            </TextField>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="outlined"
                onClick={() => setCustomerModalOpen(false)}
                sx={{ color: '#9ba03b', borderColor: '#9ba03b' }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleCreateCustomer}>
                Add Customer
              </Button>
            </Box>
          </Box>
        );

      case 'select':
        return (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Select Existing Customer</Typography>
              <CloseButton onClick={() => setCustomerModalOpen(false)} />
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Last Order</TableCell>
                    <TableCell>Select</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.user_id}>
                      <TableCell>{`${customer.first_name} ${customer.last_name}`}</TableCell>
                      <TableCell>{customer.email}</TableCell>
                      <TableCell>{customer.phone}</TableCell>
                      <TableCell>
                        {customer.last_orders[0]?.date
                          ? getTimeDifference(customer.last_orders[0].date)
                          : 'Never Ordered'}
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => handleSelectCustomer(customer)}>
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );

      case 'update':
        return (
          <Box>
            {/* Alert for invalid phone number */}
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">Edit Customer</Typography>
              <Button onClick={() => setCustomerModalOpen(false)}>Close</Button>
            </Box>

            <TextField
              label="First Name"
              fullWidth
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Last Name"
              fullWidth
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email Address"
              fullWidth
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Mobile Phone Number"
              fullWidth
              required
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberUpdate(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              select
              label="Preferred Store"
              fullWidth
              required
              value={preferredStore}
              onChange={(e) => setPreferredStore(e.target.value)}
            >
              {stores.map((store) => (
                <MenuItem key={store.id} value={store.g_order_collection_store}>
                  {store.g_order_collection_store}
                </MenuItem>
              ))}
            </TextField>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="outlined"
                onClick={() => setCustomerModalOpen(false)}
                sx={{ color: '#9ba03b', borderColor: '#9ba03b' }}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleUpdateCustomer}>
                Update Customer
              </Button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="order-area" sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
      <style>
        {`
          .order-area {
            margin-left: 0px;
            margin-right: 0px;
          }
          .Quadrant {
            background-color: white;
          }
          textarea[resize] {
            resize: none;
          }
            .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            z-index: 1000;
          }
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            z-index: 1000;
          }
          
          input[type=number]::-webkit-outer-spin-button,
          input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type=number] {
            -moz-appearance: textfield; /* Firefox */
          }
          @keyframes flashRed {
            0% { background-color: red; }
            100% { background-color: darkred; }
          }
        `}
      </style>

      {/* Top Section */}
      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Left Column */}
        <Box
          sx={{
            width: '60%',
            height: '100%',
            overflowY: isAccordionOpen ? 'hidden' : 'auto', // Disable scrolling when accordion is open
            borderRight: '1px solid #ccc',
            padding: '1em 1em 0em 1em',
            position: 'relative',
          }}
          className="Quadrant"
        >
          <Box
            sx={{
              position: 'fixed', // Fixed position so it stays visible on scroll
              top: 0,
              left: 0,
              width: '100%', // Ensures it covers the left section only
              height: '90vh', // Full height of viewport
              background: isAccordionOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
              zIndex: isAccordionOpen ? 5 : -1, // Ensure it is behind the accordion
              pointerEvents: isAccordionOpen ? 'auto' : 'none',
              transition: 'background 0.2s ease-in-out',
            }}
            onClick={() => setIsAccordionOpen(false)}
          />
          <Box display="flex" alignItems="center">
            <h3 style={{ margin: 0 }}>
              <span style={{ fontWeight: "100" }}>Step 1. </span>Select your Items
            </h3>
            <Button
              variant="contained"
              onClick={() => setCustomProductDialogOpen(true)}
              sx={{ ml: "auto" }} // Pushes button to the right
            >
              Add Custom Product
            </Button>
          </Box>
            <TextField
              label="Search Products"
              variant="outlined"
              fullWidth
              sx={{ mb: 2, marginTop: "1em" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1 }} />,
              }}
            />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item Image</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>Add</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
               {loading ? (
                 <TableRow>
                   <TableCell colSpan={5}>
                     <Typography textAlign="center">Loading products...</Typography>
                   </TableCell>
                 </TableRow>
               ) : (              
                Object.keys(groupedProducts).length === 0 ? (
                  <Typography sx={{ textAlign: "center", p: 2 }}>No products found.</Typography>
                ) : (
                  Object.entries(groupedProducts).map(([category, items]) => (
                    <React.Fragment key={category}>
                      <TableRow>
                        <TableCell colSpan={5} style={{ backgroundColor: '#f6f6f6', fontWeight: 'bold' }}>
                          {category}
                        </TableCell>
                      </TableRow>
                      {items.map((product) => (
                        <TableRow
                          key={product.name}
                          style={{
                            backgroundColor: product.stock_status === 'out_of_stock' ? '#ffcccc' : 'transparent',
                          }}
                        >
                          <TableCell>
                            {product.thumbnail ? (
                              <img src={product.thumbnail} alt="Thumbnail" style={{ width: '50px' }} />
                            ) : (
                              'No Image'
                            )}
                          </TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>${(Number(product.price) || 0).toFixed(2)}</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Button
                                onClick={() => handleTempQuantityChange(product, -1)}
                                sx={{
                                  background: '#ebebeb',
                                  aspectRatio: '3.5 / 1',
                                  color: 'black',
                                  minWidth: '30px',
                                  fontSize: '1.2rem',
                                }}
                              >
                                -
                              </Button>
                              <TextField
                                type="number"
                                value={tempQuantities[product.name] ?? product.default_qty ?? 5}
                                onChange={(e) => {
                                  const newQty = Math.max(1, parseInt(e.target.value) || 1);
                                  setTempQuantities((prev) => ({ ...prev, [product.name]: newQty }));
                                }}
                                inputProps={{ min: 1, style: { textAlign: 'center', appearance: 'textfield' } }}
                                sx={{ width: '50px', marginX: 1 }}
                              />
                              <Button
                                onClick={() => handleTempQuantityChange(product, 1)}
                                sx={{
                                  background: '#ebebeb',
                                  aspectRatio: '3.5 / 1',
                                  color: 'black',
                                  minWidth: '30px',
                                  fontSize: '1.2rem',
                                }}
                              >
                                +
                              </Button>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleAddToOrder(product)}
                              variant="contained"
                            >
                              Add
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                    ))
                  )
                )}  
              </TableBody>
            </Table>
          </TableContainer>

          {/* Accordion for Ordered Items */}
          {orderedItems.length > 0 && (
            <Accordion
              expanded={isAccordionOpen}
              onChange={() => setIsAccordionOpen(!isAccordionOpen)}
              sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#f6f6f6',
                zIndex: 50,
              }}
            >
              <AccordionSummary
                expandIcon={
                  isAccordionOpen ? (
                    <ExpandLess sx={{ color: 'white' }} />
                  ) : (
                    <ExpandMore sx={{ color: 'white' }} />
                  )
                }
                sx={{ bgcolor: '#9ba03b', color: 'white' }}
              >
                View Ordered Items | {orderedItems.reduce((total, item) => total + item.qty, 0)} kgs
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  maxHeight: '30vh',
                  overflowY: 'auto',
                  backgroundColor: '#f6f6f6',
                }}
              >
                <TableContainer>
                  <Table>
                    <TableBody>
                      {orderedItems.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{`${item.qty} kg`}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleRemoveItem(index)}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          )}

        </Box>

        {/* Full-Screen Spinner */}
        {loading && (
          <Box className="overlay">
            <CircularProgress className="loading-spinner" />
          </Box>
        )}

        <Dialog open={customProductDialogOpen} onClose={() => setCustomProductDialogOpen(false)}>
          <DialogTitle>Add Custom Product</DialogTitle>
          <p style={{ padding: "0px 24px" }}>Ensure all details are descriptive and correct including, quantity, and price. This is for one-time or special-order items that are not in the standard catalog.</p>
          <DialogContent>
            <TextField
              label="Product Name (Max 40 Characters)"
              fullWidth
              value={customProductName}
              onChange={(e) => setCustomProductName(e.target.value.slice(0, 40))}
              inputProps={{ maxLength: 40 }}
              style={{
                marginTop: "0.5em",
                marginBottom: "0.5em",
              }}
            />
            <TextField
              label="Product Price"
              type="number"
              fullWidth
              style={{
                marginTop: "0.5em",
                marginBottom: "0.5em",
              }}
              value={customProductPrice}
              onChange={(e) => setCustomProductPrice(e.target.value)}
            />
            <TextField
              label="Quantity"
              type="number"
              fullWidth
              value={customProductQty}
              onChange={(e) => setCustomProductQty(Math.max(1, parseInt(e.target.value) || 1))}
              inputProps={{ min: 1 }}
              style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ border: "#9ba03b solid 1px", color: "#9ba03b" }} onClick={() => setCustomProductDialogOpen(false)}>Cancel</Button>
            <Button style={{ background: "#9ba03b", color: "white" }} onClick={handleAddCustomProduct}>Add</Button>
          </DialogActions>
        </Dialog>

        <Modal open={customerModalOpen} onClose={() => setCustomerModalOpen(false)}>
          {overlayLoading ? (
            <CircularProgress sx={{ display: 'block', margin: 'auto' }} />
          ) : (
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: '80%' }}>
              {renderCustomerModalContent()}
            </Box>
          )}
        </Modal>

        <Modal open={posNoteModal.open} onClose={() => setPosNoteModal({ open: false, product: null })}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '50%',
              textAlign: 'center',
              borderRadius: '8px',
            }}
          >
            {/* Close "X" Button */}
            <Button
              onClick={() => setPosNoteModal({ open: false, product: null })}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                minWidth: '30px',
                color: '#9ba03b',
                fontSize: '1rem',
                '&:hover': { color: 'black' },
              }}
            >
              X
            </Button>
            <br />
            {/* Flashing "Product Alert" Header */}
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                background: 'red',
                color: 'white',
                padding: '8px',
                borderRadius: '4px',
                animation: 'flashRed 0.5s ease-in-out infinite alternate',
              }}
            >
              Product Alert
            </Typography>
            {/* POS Note Content */}
            <Typography>
              {posNoteModal.product?.pos_note.replace(/\\/g, '')}
            </Typography>

            {/* Confirmation Button */}
            <Button
              variant="contained"
              onClick={() => {
                const product = posNoteModal.product;
                const quantity = tempQuantities[product.name] ?? product.default_qty ?? 5;
                addProductToOrder(product, quantity);
                setPosNoteModal({ open: false, product: null });
              }}
              sx={{ mt: 2, backgroundColor: '#9ba03b', color: 'white' }}
            >
              I Understand, Add this Item
            </Button>
          </Box>
        </Modal>

        <Modal open={outOfStockModal.open} onClose={() => setOutOfStockModal({ open: false, productName: '' })}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '50%',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, color: '#9ba03b' }}>
              Out of Stock
            </Typography>
            <Typography>{`The product ${outOfStockModal.productName} is currently out of stock.`}</Typography>
            <Button
              variant="contained"
              onClick={() => setOutOfStockModal({ open: false, productName: '' })}
              sx={{ mt: 2, backgroundColor: '#9ba03b', color: 'white' }}
            >
              Close
            </Button>
            <Tooltip title="Please make sure you speak to the production team by dialing 111 before creating an order for an unavailable product">
              <Button
                variant="outlined"
                sx={{ mt: 2, borderColor: '#9ba03b', color: '#9ba03b', marginLeft: "1em" }}
                onClick={() => {
                  const product = outOfStockModal.product;
                  const quantity = tempQuantities[product.name] ?? product.default_qty ?? 5;
                  
                  setOrderedItems((prevItems) => {
                    const existingItem = prevItems.find((item) => item.name === product.name);
                    if (existingItem) {
                      return prevItems.map((item) =>
                        item.name === product.name ? { ...item, qty: item.qty + quantity } : item
                      );
                    } else {
                      return [...prevItems, { ...product, qty: quantity }];
                    }
                  });

                  setTempQuantities((prev) => {
                    const newQuantities = { ...prev };
                    delete newQuantities[product.name];
                    return newQuantities;
                  });

                  setOutOfStockModal({ open: false, productName: '' });
                }}
              >
                Add Anyway
              </Button>
            </Tooltip>
          </Box>
        </Modal>

        <Modal open={pastCutoffModal.open} onClose={closePastCutoffModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '50%',
            }}
          >
            <Typography variant="h6">
              You have selected a date which is PAST the week’s cutoff.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Are you sure you wish to place an order for the selected week of{' '}
              <b>{pastCutoffModal.date}</b>? <br />
              <b>You must check with the production team by dialing extension 111 before continuing.</b>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button variant="contained" onClick={closePastCutoffModal}>
                Cancel and Re-Select
              </Button>
              <Button variant="contained" color="primary" onClick={() => setPastCutoffModal({ open: false, date: '' })}>
                Proceed with Selected Date
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal open={historyModalOpen} onClose={() => setHistoryModalOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '80%',
            }}
          >
            <Typography variant="h6">Order History</Typography>
            The customers last three orders.
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order Placed</TableCell>
                    <TableCell>Scheduled</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Items</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lastThreeOrders.map((order) => (
                    <TableRow key={order.order_id}>
                      <TableCell>{order.time_ago}</TableCell>
                      <TableCell>
                        {order.Scheduled ? (
                          <Tooltip title={`Scheduled Order ID: ${order['Scheduled Order Id']}`}>
                            <CheckCircleIcon sx={{ color: 'green' }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Not Scheduled">
                            <CancelIcon sx={{ color: 'red' }} />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{order.order_id}</TableCell>
                      <TableCell>{new Date(order.date).toLocaleDateString()}</TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>
                        {order.items.map((item) => (
                          <Box key={item.name} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography>{`${item.name} (x${item.qty})`}</Typography>
                            {item.status ? (
                              <Tooltip title={item.status}>
                                <CheckCircleIcon sx={{ color: 'green', ml: 1 }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title="We're working on this item still...">
                                <CancelIcon sx={{ color: 'red', ml: 1 }} />
                              </Tooltip>
                            )}
                          </Box>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>

        <Dialog
          open={placeOrderModalOpen}
          onClose={() => setPlaceOrderModalOpen(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              height: "80vh",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {/* Stepper Navigation */}
          <DialogTitle sx={{ bgcolor: "#9ba03b", color: "white" }}>Confirm Order</DialogTitle>
           {overlayLoading && (
             <Box
               sx={{
                 position: "absolute",
                 top: 0,
                 left: 0,
                 width: "100%",
                 height: "100%",
                 backgroundColor: "rgba(255, 255, 255, 0.6)",
                 display: "flex",
                 alignItems: "center",
                 justifyContent: "center",
                 zIndex: 9999,
               }}
             >
               <CircularProgress />
             </Box>
           )}
          <DialogContent dividers>
            <Stepper activeStep={activeStep} style={{ marginBottom: "1em" }}>
              {["Confirm Order", "Select Payment Method", "Order Placed"].map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Step 1: Confirm Order */}
            {activeStep === 0 && (
              <Box>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='table-key'>Customer Name</TableCell>
                        <TableCell>{selectedCustomer ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}` : "N/A"}</TableCell>
                        <TableCell className='table-key'>Customer Email</TableCell>
                        <TableCell>{selectedCustomer?.email || "N/A"}</TableCell>
                      </TableRow>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='table-key'>Phone</TableCell>
                        <TableCell>{selectedCustomer?.phone || "N/A"}</TableCell>
                        <TableCell className='table-key'>Collection Store</TableCell>
                        <TableCell>{collectionStore || "N/A"}</TableCell>
                      </TableRow>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='table-key'>Collection Week</TableCell>
                        <TableCell>{selectedWeek || "N/A"}</TableCell>
                        <TableCell className='table-key'>Placed By</TableCell>
                        <TableCell>{user.name || "N/A"}</TableCell>
                      </TableRow>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='table-key'>Notes</TableCell>
                        <TableCell colSpan={3}>{orderNote || "None"}</TableCell>
                      </TableRow>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='table-key' colSpan={4}><strong>Order Items</strong></TableCell>
                      </TableRow>
                      {orderedItems.map((item, index) => (
                        <TableRow key={index} className='confirm-blk-table-row'>
                          <TableCell colSpan={2}>{item.name}</TableCell>
                          <TableCell>{item.qty} kg</TableCell>
                          <TableCell>${item.price.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {/* Step 2: Select Payment Method */}
            {activeStep === 1 && (
              <Box>
                <Typography style={{ paddingBottom: "1em" }} variant="h6">Choose Payment Method</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Card sx={selectedPaymentMethod === "manual" ? selectedStyle : defaultStyle} onClick={() => setSelectedPaymentMethod("manual")}>
                      <CardContent>
                        <CreditScoreIcon style={{ fontSize: "2em" }} />
                        <Typography style={{ fontSize: "1.25em" }}>Paid Manually</Typography>
                        <Typography style={{ fontSize: "0.75em" }}>Payment Processed Manually via POS</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={{ ...defaultStyle, opacity: 0.5, pointerEvents: "none" }}>
                      <CardContent>
                        <PaymentIcon style={{ fontSize: "2em" }} />
                        <Typography style={{ fontSize: "1.25em" }}>Pay Now (Card on File)</Typography>
                        <Typography style={{ fontSize: "0.75em", color: "red" }}>Not Currently Available</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                    <Card sx={selectedPaymentMethod === "in_person" ? selectedStyle : defaultStyle} onClick={() => setSelectedPaymentMethod("in_person")}>
                      <CardContent>
                        <CoPresentIcon style={{ fontSize: "2em" }} />
                        <Typography style={{ fontSize: "1.25em" }}>Pay in Person</Typography>
                        <Typography style={{ fontSize: "0.75em" }}>Customer will pay in-store upon collection.</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                {/* POS Reference Input (Only for "Paid Manually") */}
                {selectedPaymentMethod === "manual" && (
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="POS Reference"
                      fullWidth
                      value={posReference}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPosReference(value);

                        // Clear error if the value is valid
                        const regex = /^(PWB)?\d+-\d{1,3}$/;
                        if (regex.test(value)) {
                          setError(""); // Clear the error
                        }
                      }}
                      onBlur={() => {
                        const regex = /^(PWB)?\d+-\d{1,3}$/;
                        if (!regex.test(posReference)) {
                          setError("Invalid POS Reference - Check POS Reciept (Top Left). Format: '123-5'");
                        }
                      }}
                    />
                    {error ? (
                      <Typography color="error">{error}</Typography>
                    ) : posReference && (
                      <Typography color="green">
                        <CheckCircleIcon style={{ verticalAlign: "middle", marginRight: "5px" }} />
                        POS Reference Valid
                      </Typography>
                    )}

                  </Box>
                )}
              </Box>
            )}

            {/* Step 3: Order Confirmation */}
            {activeStep === 2 && (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <CheckCircleIcon sx={{ fontSize: 80, color: "#4caf50" }} />
                <Typography variant="h5">Order Placed!</Typography>
                <TableContainer style={{marginTop: "1em"}}>
                  <Table>
                    <TableHead>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell className='confirm-blk-table-key'>Customer Name</TableCell>
                        <TableCell className='confirm-blk-table-key'>Mobile Number</TableCell>
                        <TableCell className='confirm-blk-table-key'>Qty & Protein Description</TableCell>
                        <TableCell className='confirm-blk-table-key'>Store</TableCell>
                        <TableCell className='confirm-blk-table-key'>Order #</TableCell>
                        <TableCell className='confirm-blk-table-key'>Comments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className='confirm-blk-table-row'>
                        <TableCell>{orderConfirmation?.customer_name || "N/A"}</TableCell>
                        <TableCell>{orderConfirmation?.phone || "N/A"}</TableCell>
                        <TableCell>
                          {orderConfirmation?.products?.map((p) => (
                            <div key={p.sku}>{p.name} {p.quantity}kg</div>
                          ))}
                        </TableCell>
                        <TableCell>{orderConfirmation?.collection_store || "N/A"}</TableCell>
                        <TableCell>#{orderConfirmation?.order_id}</TableCell>
                        <TableCell>{orderConfirmation?.notes || "None"}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </DialogContent>

          {/* Stepper Navigation Buttons */}
          <DialogActions>
            {activeStep === 0 && (
              <>
                <Button style={{ border: "#9ba03b solid 1px", color: "#9ba03b" }} onClick={() => setPlaceOrderModalOpen(false)}>Cancel</Button>
                <Button style={{ background: "#9ba03b", color: "white" }} onClick={() => setActiveStep(1)}>Next</Button>
              </>
            )}
            {activeStep === 1 && (
              <>
                <Button style={{ border: "#9ba03b solid 1px", color: "#9ba03b" }} onClick={() => setActiveStep(0)}>Back</Button>
                <Button
                  style={{
                    background: !selectedPaymentMethod || (selectedPaymentMethod === "manual" && !posReference) ? "grey" : "#9ba03b",
                    color: !selectedPaymentMethod || (selectedPaymentMethod === "manual" && !posReference) ? "#a1a1a1" : "white",
                  }}
                  onClick={handleConfirmOrder}
                  disabled={!selectedPaymentMethod || (selectedPaymentMethod === "manual" && !posReference)}
                >
                  Confirm Order
                </Button>
              </>
            )}
            {activeStep === 2 && (
              <Button
                style={{ background: "#9ba03b", color: "white" }}
                onClick={() => {
                  resetOrder();       
                  setActiveStep(0);   
                  setPlaceOrderModalOpen(false); // Close the modal
                }}>Create a New Order</Button>
            )}
          </DialogActions>
        </Dialog>

        <Box
          onMouseEnter={() => setShowSnackbar(true)}
          onMouseLeave={() => setShowSnackbar(false)}
        >
          <Snackbar
            open={showSnackbar}
            autoHideDuration={null} // Prevents auto-closing
            onClose={() => setShowSnackbar(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{ marginBottom: '10vh', zIndex: 100 }}
          >
            <Alert
              severity="warning"
              onClose={() => setShowSnackbar(false)}
            >
              <Typography variant="body2">
                {decodeHTML(snackbarMessage).split("\n").map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
            </Alert>
          </Snackbar>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning to top-right
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>

        {/* Right Column */}
        <Box
          sx={{
            width: '40%',
            height: '100%',
            overflow: 'auto',
            padding: 2,
            overflowY: 'auto',
          }}
          className="Quadrant"
        >
          {rightPanelLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                zIndex: 10,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <h3><span style={{ fontWeight: "100" }}>Step 2. </span>Enter Phone Number</h3>
          <TextField
            label="Phone Number"
            placeholder="Mobile (04xxxxxxxx)"
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onBlur={handleSearchCustomer} // Trigger search on blur
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleSearchCustomer(); // Trigger search on Enter
              }
            }}
            disabled={phoneDisabled}
            inputProps={{
              maxLength: 10,
              pattern: "[0-9]*",
              inputMode: "numeric",
            }}
            InputProps={{
              startAdornment: phoneLoading ? (
                <CircularProgress style={{ marginRight: "1em" }} size={20} />
              ) : (
                <SearchIcon style={{ marginRight: "1em" }} />
              ),
            }}
          />
          {selectedCustomer && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3><span style={{ fontWeight: "100" }}>Step 3. </span>Confirm Customer Details</h3>
                {selectedCustomer && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditCustomer} // Ensure this calls the edit function
                  >
                    Edit
                  </Button>
                )}
              </Box>
              <TextField
                label="Name"
                fullWidth
                variant="outlined"
                value={selectedCustomer ? `${selectedCustomer.first_name} ${selectedCustomer.last_name}` : ''}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                label="Email Address"
                fullWidth
                variant="outlined"
                value={selectedCustomer ? selectedCustomer.email : ''}
                disabled
                sx={{ mb: 2 }}
              />
              <TextField
                label="Preferred Store"
                fullWidth
                variant="outlined"
                value={selectedCustomer ? selectedCustomer.preferred_store : ''}
                disabled
                sx={{ mb: 2 }}
              />
            </>
          )}
          <h3>
            <span style={{ fontWeight: "100" }}>{selectedCustomer ? "Step 4. " : "Step 3. "}</span>Confirm Order Details
          </h3>
          <TextField
            select
            label="Collection Week"
            fullWidth
            variant="outlined"
            value={selectedWeek}
            onChange={handleWeekChange}
            sx={{ mb: 2 }}
          >
            <MenuItem value="">Please Select</MenuItem>
            {collectionWeeks.map((week) => (
              <MenuItem
                key={week.Date}
                value={week.Date}
                style={{
                  backgroundColor: week['Past Cutoff'] || week.Closure ? '#df1b1b' : '#ACF3AE',
                  color: week['Past Cutoff'] || week.Closure ? 'white' : 'black',
                }}
                sx={{
                  '&:hover': {
                    filter: 'brightness(90%)',
                  },
                  '&.Mui-selected': {
                    filter: 'brightness(80%)',
                  },
                }}
              >
                {week['Display Text']}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Collection Store"
            fullWidth
            variant="outlined"
            sx={{
              mb: 2,
            }}
            value={collectionStore}
            onChange={(e) => setCollectionStore(e.target.value)}
          >
            {stores.map((store) => (
              <MenuItem key={store.id} value={store.g_order_collection_store}>
                {store.g_order_collection_store}
              </MenuItem>
            ))}
          </TextField>
          <TextareaAutosize
            minRows={5}
            placeholder="Notes"
            value={orderNote}
            onChange={(e) => setOrderNote(e.target.value)}
            style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: 4, resize: 'none' }}
          />
        </Box>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          position: 'absolute', // Makes it float relative to the parent
          bottom: 0, // Sticks the footer to the bottom
          left: 0, // Keeps the left margin offset
          right: 0, // Keeps the right margin offset
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'black',
          padding: 2,
          height: '10%',
          boxSizing: 'border-box', // Ensures padding is included in the height
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {selectedCustomer?.last_orders?.length > 0 && (
            <Button
              sx={{
                backgroundColor: '#9ba03b',
                color: 'white',
                border: '2px solid transparent',
                '&:hover': {
                  borderColor: '#9ba03b',
                  backgroundColor: '#7f8c28',
                },
              }}
              startIcon={<HistoryIcon />}
              onClick={() => handleShowOrderHistory()}
            >
              Order History
            </Button>
          )}

          <Button
            sx={{
              backgroundColor: '#9ba03b',
              color: 'white',
              border: '2px solid transparent',
              '&:hover': {
                borderColor: '#9ba03b',
                backgroundColor: '#7f8c28',
              },
            }}
            startIcon={<EventRepeatIcon />}
          >
            Scheduled Orders
          </Button>
        </Box>
        <Box onMouseEnter={() => handlePlaceOrderHover(true)} onMouseLeave={() => handlePlaceOrderHover(false)}>
          <Button
            onClick={() => setPlaceOrderModalOpen(true)}
            disabled={!selectedCustomer || !selectedWeek || !collectionStore || orderedItems.length === 0}
            sx={{
              backgroundColor: '#9ba03b',
              color: 'white',
              '&:hover': { backgroundColor: '#7f8c28' },
            }}
          >
            PLACE ORDER
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBulkOrder;