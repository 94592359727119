import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Barcode from 'react-barcode';
import { styled } from '@mui/system';
import { useReactToPrint } from 'react-to-print';
import authService from '../services/authService';

// Spinner styles
const CenteredSpinner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const OrderReport = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const token = authService.getApiKey("bulk_system");
  const printRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          'https://order.petfresh.com.au/wp-json/wc/v3/production-report',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const handlePrint = () => {
    const printContent = printRef.current.outerHTML; // Use outerHTML instead of innerHTML
    const newWindow = window.open('', '', 'width=800,height=600');
    newWindow.document.write('<html><head><title>Order Report</title>');
    newWindow.document.write(
      `<style>
        body { font-family: Arial, sans-serif; }
        table { border-collapse: collapse; width: 100%; }
        th, td { border: 1px solid #ddd; padding: 8px; }
        th {
          background-color: #000 !important;
          color: white !important; 
        }
        .store-name {
          font-size: 24px;
          margin-bottom: 20px;
          font-weight: bold;
          background-color: #000 !important;
          color: #fff' !important;
          padding: 10px;
          }
          .barcode-cell {
          width: 150px
          }
        .barcode-cell svg { width: 100%; height: auto; } /* Adjust barcode SVG width */
        .tick-icon { width: 1em; vertical-align: middle; } /* Fix tick size */
        @page {
          size: A4 landscape; /* Ensure A4 size in landscape mode */
          margin: 9mm;
        }
      </style>`
    );
    newWindow.document.write('</head><body>');
    newWindow.document.write(printContent); // Insert the outerHTML content
    newWindow.document.write('</body></html>');
    newWindow.document.close();
    newWindow.print();
  };
  

  if (loading) {
    return (
      <CenteredSpinner>
        <CircularProgress />
      </CenteredSpinner>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      {/* Inline Global Styles */}
      <style>
        {`
          @media screen {
            .print-only {
              display: none !important; /* Hide print-only elements in React frontend */
            }
          }
  
          @media print {
            .table-header {
              background-color: #000 !important; /* Black background in print */
              color: #fff !important; /* White text in print */
              -webkit-print-color-adjust: exact; /* Ensure colors are printed */
              print-color-adjust: exact; /* Ensure colors are printed */
            }
  
            .print-only {
              display: table-cell !important; /* Show print-only elements in print */
            }
  
            @page {
              size: A4 landscape; /* Set A4 size in landscape mode */
              margin: 9mm;
            }
          }
  
          .table-header {
            background-color: #000; /* Black background for table headers */
            color: #fff; /* White text for table headers */
            font-weight: bold;
            text-align: left;
            padding: 8px;
            border: 1px solid #ddd;
          }
          
          td {
            border: 1px solid #e0e0e0;
          }
        `}
      </style>
  
      <h1>Bulk Orders Report</h1>
      {/* Print Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handlePrint}
        style={{ marginBottom: '20px', backgroundColor: '#9ba03b' }}
      >
        Print Report
      </Button>
  
      {/* Report Table */}
      <div ref={printRef}>
        {Object.entries(data).map(([store, orders]) => (
          <div key={store} style={{ marginBottom: '50px', pageBreakAfter: 'always' }}>
            <Typography
              variant="h4"
              className="store-name"
              style={{
                backgroundColor: '#000',
                color: '#fff',
                padding: '10px',
                fontWeight: 'bold',
              }}
            >
              {store}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">Store</TableCell>
                    <TableCell className="table-header">Customer</TableCell>
                    <TableCell className="table-header print-only">Barcode</TableCell>
                    <TableCell className="table-header">Product</TableCell>
                    <TableCell className="table-header">Made</TableCell>
                    <TableCell className="table-header">Week Of</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => {
                    const rowSpan = order.order_items.length;
                    return order.order_items.map((item, itemIndex) => (
                      <TableRow key={`${order.order_id}-${itemIndex}`}>
                        {itemIndex === 0 && (
                          <>
                            <TableCell rowSpan={rowSpan}>{store}</TableCell>
                            <TableCell rowSpan={rowSpan}>{order.customer_name}</TableCell>
                            <TableCell rowSpan={rowSpan} className="barcode-cell print-only">
                              <Barcode
                                value={`PWB${order.order_id}-2`}
                                format="CODE128"
                                width={2}
                                height={50}
                                displayValue={true}
                              />
                            </TableCell>
                          </>
                        )}
                        <TableCell>{`${item.product_name} x ${item.quantity} kgs`}</TableCell>
                        <TableCell>
                          {item.status ? (
                            <>
                              <CheckIcon
                                style={{
                                  color: 'green',
                                  width: '1em', // Adjusted size for tick icon
                                  verticalAlign: 'middle',
                                }}
                                className="tick-icon"
                              />{' '}
                              {item.status}
                            </>
                          ) : (
                            ''
                          )}
                        </TableCell>
                        {itemIndex === 0 && (
                          <TableCell rowSpan={rowSpan}>
                            {order.g_order_production_week || 'N/A'}
                          </TableCell>
                        )}
                      </TableRow>
                    ));
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    </div>
  );  
};

export default OrderReport;