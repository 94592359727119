import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import axios from 'axios';
import authService from '../../services/authService';

const StockRequest = ({ open, onClose, orderTitle, orderData, setOrderData }) => {
  const [loading, setLoading] = useState(false);
  const [markingFulfilled, setMarkingFulfilled] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [modifiedItems, setModifiedItems] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const fetchStockRequest = async () => {
    if (!orderData || !orderData.id) return;

    const accessToken = authService.getApiKey("wms");
    try {
      const response = await axios.get(
        `https://wms-api.neicha.com.au/stock-request/${orderData.id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (response.data.status === 'Fulfilled') {
        alert('This Request has already been fulfilled and can no longer be updated.');
        onClose();
        return;
      }

      const flattenedItems = Object.entries(response.data.itemsByDepartment).flatMap(([department, items]) =>
        items.map((item) => ({
          ...item,
          department,
        }))
      );

      setOrderData({ ...response.data, items: flattenedItems });
      setModifiedItems({});
      setUnsavedChanges(false);
      setLoading(false);
    } catch (error) {
      console.error('Error refetching stock request:', error);
    }
  };

  const handleQtyChange = (sku, value) => {
    const numericValue = parseInt(value, 10);
    const isModified = numericValue !== orderData.items.find((item) => item.sku === sku)?.qtytransferred;

    setModifiedItems((prev) => ({
      ...prev,
      [sku]: isModified ? numericValue : undefined,
    }));

    setUnsavedChanges(Object.values(modifiedItems).some((val) => val !== undefined));
  };

  const handleRowClick = (sku) => {
    if (!loading && !markingFulfilled) {
      const inputElement = document.getElementById(`qty-input-${sku}`);
      if (inputElement) inputElement.focus();
    }
  };

  const handleSave = async () => {
    if (!orderData || !orderData.id || !unsavedChanges) return;

    const accessToken = authService.getApiKey("wms");
    const payload = Object.entries(modifiedItems)
        .filter(([sku, qtytransferred]) => qtytransferred !== undefined)
        .map(([sku, qtytransferred]) => ({
            stockrequestid: orderData.id,
            sku,
            qtytransferred,
        }));

    setLoading(true);
    try {
        await axios.put('https://wms-api.neicha.com.au/stock-request-transfered/', payload, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        // Clear modifiedItems for saved SKUs
        const savedSKUs = Object.keys(modifiedItems).filter(
            (sku) => payload.find((item) => item.sku === sku)
        );
        setModifiedItems((prev) =>
            Object.fromEntries(
                Object.entries(prev).filter(([sku]) => !savedSKUs.includes(sku))
            )
        );

        await fetchStockRequest(); // Refetch data after saving
    } catch (error) {
        console.error('Error saving stock request:', error);
    } finally {
        setLoading(false);
    }
};


  const handleMarkFulfilled = async () => {
    if (!orderData || !orderData.id) return;

    const accessToken = authService.getApiKey("wms");
    const requestId = orderData.id;

    setMarkingFulfilled(true);
    try {
      // Save changes before marking as fulfilled
      await handleSave();

      // Mark as fulfilled
      await axios.post(
        'https://wms-api.neicha.com.au/stock-request-status',
        { id: requestId, status: 'Fulfilled' },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      await fetchStockRequest(); // Refetch data after marking fulfilled
      onClose();
    } catch (error) {
      console.error('Error marking stock request as fulfilled:', error);
    } finally {
      setMarkingFulfilled(false);
    }
  };

  useEffect(() => {
    if (orderData?.status === 'Fulfilled') {
      alert('This Request has already been fulfilled and can no longer be updated.');
      onClose();
    }
    setLoading(false);
  }, [orderData, onClose]);

  const groupedItems = orderData?.items?.reduce((acc, item) => {
    acc[item.department] = acc[item.department] || [];
    acc[item.department].push(item);
    return acc;
  }, {});

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {orderTitle}
        <Button
          onClick={handleSave}
          style={{ float: 'right', marginRight: '10px' }}
          disabled={!unsavedChanges || loading || markingFulfilled}
          color="primary"
          variant="contained"
        >
          {loading ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '8px' }} />
              Saving...
            </>
          ) : (
            'Save'
          )}
        </Button>
        <Button
          onClick={() => setConfirmationOpen(true)}
          style={{ float: 'right', marginRight: '10px' }}
          disabled={loading || markingFulfilled}
          color="success"
          variant="contained"
        >
          {markingFulfilled ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '8px' }} />
              Marking as Fulfilled...
            </>
          ) : (
            'Save & Mark Fulfilled'
          )}
        </Button>
      </DialogTitle>
      <DialogContent>
        {groupedItems ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell align="right">QTY Requested</TableCell>
                <TableCell align="right">QTY Sent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedItems).map(([department, items]) => (
                <React.Fragment key={department}>
                  <TableRow>
                    <TableCell colSpan={4} style={{ backgroundColor: '#f2f2f2', fontWeight: 'bold' }}>
                      {department}
                    </TableCell>
                  </TableRow>
                  {items.map((item) => {
                    const currentQtyTransferred =
                      modifiedItems[item.sku] !== undefined
                        ? modifiedItems[item.sku]
                        : item.qtytransferred ?? item.qtyrequested;

                    return (
                      <TableRow
                        key={item.sku}
                        style={{
                          backgroundColor:
                            currentQtyTransferred !== item.qtyrequested ? '#ffffc5' : 'transparent',
                        }}
                        onClick={() => handleRowClick(item.sku)}
                      >
                        <TableCell>
                          {item.productname}{' '}
                          {modifiedItems[item.sku] !== undefined && (
                            <Tooltip title="Not Saved">
                              <SyncProblemIcon color="error" style={{ marginLeft: '8px' }} />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>{item.sku}</TableCell>
                        <TableCell align="right">{item.qtyrequested}</TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            id={`qty-input-${item.sku}`}
                            value={currentQtyTransferred}
                            onChange={(e) => handleQtyChange(item.sku, e.target.value)}
                            inputProps={{
                              min: 0,
                              style: { textAlign: 'right', width: '80px' },
                            }}
                            disabled={loading || markingFulfilled}
                            fullWidth
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No items found for this stock request.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading || markingFulfilled}>
          Close
        </Button>
      </DialogActions>
      {confirmationOpen && (
        <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)} maxWidth="sm" fullWidth>
          <DialogTitle>
            Confirm Action
            <IconButton
              aria-label="close"
              onClick={() => setConfirmationOpen(false)}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to mark this as fulfilled? This will update the stock levels of
              the store and deduct from the warehouse.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setConfirmationOpen(false);
                handleMarkFulfilled();
              }}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

// Process function to fetch stock request data
export const processStockRequest = async (barcode, setOrderData) => {
  const id = barcode.replace(/^REQ/, '');
  const accessToken = authService.getApiKey("wms");
  try {
    const response = await axios.get(`https://wms-api.neicha.com.au/stock-request/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response.data.status === 'Fulfilled') {
      alert('This Request has already been fulfilled and can no longer be updated.');
      return;
    }

    const flattenedItems = Object.entries(response.data.itemsByDepartment).flatMap(([department, items]) =>
      items.map((item) => ({
        ...item,
        department,
      }))
    );

    setOrderData({ ...response.data, items: flattenedItems });
  } catch (error) {
    console.error('Error fetching stock request:', error);
    setOrderData({ items: [] });
  }
};

export default StockRequest;