import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Checkbox,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import authService from '../../services/authService';

//
// Utility functions to handle escaping/unescaping POS notes
//
function unescapePOSNote(note) {
  if (!note) return "";
  return note
    // Replace escaped single quotes \' with '
    .replace(/\\'/g, "'")
    // Replace escaped double quotes \" with "
    .replace(/\\"/g, '"');
}

function escapePOSNote(note) {
  if (!note) return "";
  return note
    // Escape single quotes
    .replace(/'/g, "\\'")
    // Escape double quotes
    .replace(/"/g, '\\"');
}

//
// Overlay spinner styling for "Updating Availability..."
//
const UpdatingOverlay = styled(Box)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  zIndex: 9999,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const BulkStockManager = () => {
  // State for products and loading
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // For fetching availability
  const [updateLoading, setUpdateLoading] = useState(false); // For updating availability
  const [searchQuery, setSearchQuery] = useState("");


  // State for Snackbar (errors / success)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Token from authService
  const token = authService.getApiKey("bulk_system");

  //
  // Helper to open a Snackbar
  //
  const showSnackbar = (message, severity = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  //
  // Fetch products (GET)
  //
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://order.petfresh.com.au/wp-json/wc/v3/product-stock-status/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Fetch error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching bulk products:", error);
      showSnackbar(`Error fetching bulk products: ${error.message}`, "error");
    }
    setLoading(false);
  };

  //
  // Update product (PUT)
  //
  const updateProduct = async (productId, newStatus, newPosNote, productName) => {
    setUpdateLoading(true);
    try {
      const response = await fetch(
        "https://order.petfresh.com.au/wp-json/wc/v3/product-stock-status/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            product_id: productId,
            stock_status: newStatus,
            pos_note: escapePOSNote(newPosNote),
          }),
        }
      );

      if (!response.ok) {
        const respText = await response.text();
        throw new Error(`Update error: ${response.status} - ${respText}`);
      }

      // Success => update local state
      setProducts((prevProducts) =>
        prevProducts.map((item) =>
          item["Product ID"] === productId
            ? {
                ...item,
                "Stock Status": newStatus,
                "POS Note": escapePOSNote(newPosNote),
              }
            : item
        )
      );

      // Show success snackbar
      showSnackbar(
        `${productName} was updated to ${
          newStatus === "instock" ? "In Stock" : "Out of Stock"
        }`,
        "success"
      );
    } catch (error) {
      console.error("Error updating product:", error);
      showSnackbar(`Error updating product: ${error.message}`, "error");
    }
    setUpdateLoading(false);
  };

  //
  // Handle checkbox toggle
  //
  const handleStockToggle = (product) => (event) => {
    const newStatus = event.target.checked ? "instock" : "outofstock";
    updateProduct(
      product["Product ID"],
      newStatus,
      unescapePOSNote(product["POS Note"] || ""),
      product["Product Name"]
    );
  };

  //
  // Handle POS note update on blur
  //
  const handlePosNoteBlur = (product) => (event) => {
    const newPosNote = event.target.value;
    const oldUnescapedNote = unescapePOSNote(product["POS Note"] || "");

    // Update only if changed
    if (newPosNote !== oldUnescapedNote) {
      updateProduct(
        product["Product ID"],
        product["Stock Status"],
        newPosNote,
        product["Product Name"]
      );
    }
  };

  //
  // Filter products by search query
  //
  const filteredProducts = products.filter(
    (prod) =>
      prod["Product Name"].toLowerCase().includes(searchQuery.toLowerCase()) ||
      (prod["SKU"] &&
        prod["SKU"].toLowerCase().includes(searchQuery.toLowerCase()))
  );

  //
  // Group by first category
  //
  const groupedProducts = filteredProducts.reduce((acc, product) => {
    const category = product.Categories?.[0] || "Uncategorized";
    if (!acc[category]) acc[category] = [];
    acc[category].push(product);
    return acc;
  }, {});

  //
  // Fetch on mount
  //
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ p: 2, backgroundColor: "white" }}>
      {/* Top Bar: Title + Refresh Button + Key/Legend */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        flexWrap="wrap"
      >
        {/* Left side: Title */}
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h5" sx={{ mr: 2 }}>
            Update Bulk Availability
          </Typography>

          {/* Key/Legend for in stock / out of stock */}
          <Box display="flex" alignItems="center">
            {/* In Stock Key */}
            <Box
              sx={{
                backgroundColor: "rgba(128,239,128,0.25)",
                width: "1.25em",
                height: "1.25em",
                border: "1px solid #80ef80",
                marginRight: "0.5em",
              }}
            />
            <Typography variant="body2">
              In Stock (Available for Ordering)
            </Typography>

            {/* Out of Stock Key */}
            <Box
              sx={{
                backgroundColor: "rgba(255,116,108,0.25)",
                width: "1.25em",
                height: "1.25em",
                border: "1px solid #ff746c",
                marginX: "0.5em",
              }}
            />
            <Typography variant="body2">
              Out of Stock (Unavailable for Ordering)
            </Typography>
          </Box>
        </Box>

        {/* Right side: Refresh Button */}
        <IconButton onClick={fetchProducts}>
          <RefreshIcon />
        </IconButton>
      </Box>

      {/* Search Field */}
      <Box mb={2}>
        <TextField
          fullWidth
          label="Search Products"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Box>

      {/* Loading Spinner for Fetching */}
      {loading ? (
        <Box textAlign="center" mt={4}>
          <CircularProgress />
          <Typography>Fetching Availability...</Typography>
        </Box>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Product Name</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>In Stock</TableCell>
              <TableCell>POS Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedProducts).map((category) => {
              const items = groupedProducts[category];

              // A row for category (no stock status background)
              return (
                <React.Fragment key={category}>
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{ fontWeight: "bold", backgroundColor: "#f2f2f2" }}
                    >
                      {category}
                    </TableCell>
                  </TableRow>

                  {/* Product rows */}
                  {items.map((product) => {
                    const rowStyle = {
                      backgroundColor:
                        product["Stock Status"] === "instock"
                          ? "rgba(128, 239, 128, 0.25)"
                          : "rgba(255, 116, 108, 0.25)",
                    };

                    return (
                      <TableRow
                        key={product["Product ID"]}
                        sx={{ "&:last-child td": { border: 0 } }}
                        style={rowStyle}
                      >
                        <TableCell>
                          {product["Image URL"] && (
                            <img
                              src={product["Image URL"]}
                              alt={product["Product Name"]}
                              style={{ width: 60, height: 60, objectFit: "cover" }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{product["Product Name"]}</TableCell>
                        <TableCell>{product["SKU"]}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={product["Stock Status"] === "instock"}
                            onChange={handleStockToggle(product)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            multiline
                            fullWidth
                            minRows={3}
                            variant="outlined"
                            defaultValue={unescapePOSNote(product["POS Note"])}
                            onBlur={handlePosNoteBlur(product)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}

      {/* Overlay Spinner for Updating Availability */}
      {updateLoading && (
        <UpdatingOverlay>
          <Box textAlign="center">
            <CircularProgress />
            <Typography variant="h6" mt={1}>
              Updating Availability...
            </Typography>
          </Box>
        </UpdatingOverlay>
      )}

      {/* Snackbar for errors or success messages */}
      <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              border: snackbarSeverity === "success"
                ? "1px solid green"
                : snackbarSeverity === "error"
                ? "1px solid red"
                : "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
    </Box>
  );
};

export default BulkStockManager;