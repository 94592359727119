import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Chip,
  Pagination,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
  Dialog,
  Box,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { format, differenceInDays } from "date-fns";
import authService from '../services/authService';

const AutoshipSchedules = () => {
  const [autoshipSchedules, setAutoshipSchedules] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20);
  const [deliveryZones, setDeliveryZones] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Fetch delivery zones on component load
  useEffect(() => {
    fetchDeliveryZones();
  }, []);

  useEffect(() => {
    fetchSchedules(searchTerm, page, showDeleted);
  }, [page, searchTerm, showDeleted]);

  const fetchDeliveryZones = async () => {
    try {
      const response = await fetch(
        "https://www.petfresh.com.au/wp-json/wc/v3/delivery-zones/"
      );
      const zones = await response.json();
      setDeliveryZones(zones);
    } catch (error) {
      console.error("Failed to fetch delivery zones", error);
    }
  };

  const fetchSchedules = async (query, pageNumber, includeDeleted) => {
    setLoading(true);
    setErrorMessage("");
    try {
      const qpilot_token = authService.getApiKey("qpilot");

      // Build the API URL with query parameters
      let url = `https://api.qpilot.cloud/api/Sites/648/ScheduledOrders?page=${pageNumber}&pageSize=${pageSize}&order=desc&status=Processing,Success,Failed,Retry,Active,Paused,Locked,Queued`;

      if (includeDeleted) {
        url += ",Deleted";
      }

      if (query.trim()) {
        url += `&search=${query.trim()}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${qpilot_token}`,
        },
      });

      const data = await response.json();

      const formattedData = data.Items.map((item) => ({
        id: item.Id,
        customerName: `${item.CustomerFirstName} ${item.CustomerLastName}`,
        nextOccurrence: item.NextOccurrenceUtc ? item.NextOccurrenceUtc : "N/A",
        lastOccurrence: item.LastOccurrenceUtc ? item.LastOccurrenceUtc : "N/A",
        frequency: `${item.Frequency || "Unknown"} ${
          item.FrequencyType || "Unknown Frequency"
        }`,
        cycles: item.Cycles || 0,
        status: item.Status || "Unknown Status",
        lastProcessingCycleStatus:
          item.LastProcessingCycle?.Status || "No Status",
        lifetimeValue: item.LifetimeValue || 0,
        postcode: item.ShippingPostcode || "Unknown Postcode",
      }));

      setAutoshipSchedules(formattedData);
      setTotalPages(Math.ceil(data.TotalCount / pageSize));
    } catch (error) {
      setErrorMessage("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getDeliveryDay = (postcode) => {
    const zone = deliveryZones.find((zone) => zone.postcode === postcode);
    return zone ? zone.delivery_day : "Unknown Day";
  };

  const handleSearchSubmit = () => {
    if (searchInput !== searchTerm) {
      setSearchTerm(searchInput);
      setPage(1); // Reset to first page on new search
    }
  };

  const handleSearchBlur = () => {
    handleSearchSubmit();
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShowDeletedToggle = (event) => {
    setShowDeleted(event.target.checked);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "green";
      case "Paused":
        return "orange";
      case "Failed":
        return "red";
      case "Deleted":
        return "grey";
      default:
        return "#A020F0";
    }
  };

  const formatDate = (date) => {
    if (!date || isNaN(new Date(date))) {
      return "N/A";
    }
    return format(new Date(date), "dd/MM/yy");
  };

  const getTimeDifferenceAgo = (date) => {
    if (!date || isNaN(new Date(date))) {
      return 'N/A';
    }
  
    const now = new Date();
    const targetDate = new Date(date);
    const diffInMilliseconds = targetDate - now; // Use this for future/past detection
    const diffInDays = Math.abs(differenceInDays(targetDate, now));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);
  
    let timeDifference;
  
    // Determine the appropriate unit (days, weeks, months, or years)
    if (diffInYears > 0) {
      timeDifference = diffInYears === 1 ? '1 year' : `${diffInYears} years`;
    } else if (diffInMonths > 0) {
      timeDifference = diffInMonths === 1 ? '1 month' : `${diffInMonths} months`;
    } else if (diffInWeeks > 0) {
      timeDifference = diffInWeeks === 1 ? '1 week' : `${diffInWeeks} weeks`;
    } else if (diffInDays > 0) {
      timeDifference = diffInDays === 1 ? '1 day' : `${diffInDays} days`;
    } else {
      return 'Today'; // If the date is today
    }
  
    // Check if the date is in the past or future
    if (diffInMilliseconds < 0) {
      // Past date
      return `${timeDifference} ago`;
    } else {
      // Future date
      return `in ${timeDifference}`;
    }
  };  

  const handleViewSchedule = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <Typography variant="h4">Autoship Schedules</Typography>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={showDeleted}
                onChange={handleShowDeletedToggle}
              />
            }
            label="Show Deleted"
          />
        </Grid>
        <TextField
          label="Search"
          variant="outlined"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onBlur={handleSearchBlur}
          onKeyPress={handleSearchKeyPress}
          style={{ width: "40%" }}
        />
      </Grid>

      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : errorMessage ? (
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      ) : autoshipSchedules.length === 0 ? (
        <Typography
          variant="h6"
          style={{ textAlign: "center", margin: "20px 0" }}
        >
          No Autoship Schedules Found
        </Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Last Occurrence</TableCell>
                  <TableCell>Next Occurrence</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Delivery Details</TableCell>
                  <TableCell>Valuation</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {autoshipSchedules.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>#{order.id}</TableCell>
                    <TableCell>{order.customerName}</TableCell>
                    <TableCell>
                      {formatDate(order.lastOccurrence)}
                      <br />
                      <small>
                        {getTimeDifferenceAgo(order.lastOccurrence)} -{" "}
                        {order.lastProcessingCycleStatus}
                      </small>
                    </TableCell>
                    <TableCell>
                      {formatDate(order.nextOccurrence)}
                      <br />
                      <small>
                        {getTimeDifferenceAgo(order.nextOccurrence)}
                      </small>
                    </TableCell>
                    <TableCell>{order.frequency}</TableCell>
                    <TableCell>
                      <Chip
                        label={order.status}
                        style={{
                          backgroundColor: getStatusColor(order.status),
                          color: "white",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {getDeliveryDay(order.postcode)}
                      <br />
                      <small>{order.postcode}</small>
                    </TableCell>
                    <TableCell>
                      Lifetime Value: ${order.lifetimeValue.toFixed(2)}
                      <br />
                      Cycles: {order.cycles}
                    </TableCell>
                    <TableCell>
                      <Button
                        style={{ color: "#9ba03b" }}
                        variant="outlined"
                        onClick={() => handleViewSchedule(order)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Typography variant="body1">
              Page {page} of {totalPages}
            </Typography>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>

          {/* Schedule Dialog */}
          {selectedOrder && (
          <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#9ba03b",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              {`Scheduled Order #${selectedOrder.id}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "black",
                  borderRadius: "5px",
                  padding: "8px",
                  marginRight: "8px", // Ensure a small gap between buttons and the close button
                }}
              >
                <Tooltip title="Delete this Schedule">
                  <IconButton style={{ color: "white" }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Process Order Now">
                  <IconButton style={{ color: "white" }}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Retry Failed Payment">
                  <IconButton style={{ color: "white" }}>
                    <ErrorIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <IconButton onClick={handleCloseDialog} style={{ color: "white" }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box p={2}>
            <Typography>Scheduled Order Details</Typography>
            {/* Your order details content */}
          </Box>
        </Dialog>
        )}
        </>
      )}
    </div>
  );
};

export default AutoshipSchedules;