import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, Grid, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StoreSelection = ({ stores, onStoreSelect }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set the body background color when this component is mounted
    document.body.style.backgroundColor = '#9ba03b';

    // Check if the store is programmatically injected
    const injectedStore = window.injectedStore;
    if (injectedStore) {
      onStoreSelect(injectedStore);
      navigate('/');
    }

    return () => {
      // Reset the body background color when this component is unmounted
      document.body.style.backgroundColor = '';
    };
  }, [navigate, onStoreSelect]);

  const handleStoreSelect = (store) => {
    onStoreSelect(store);
    navigate('/');
  };

  return (
    <div
      style={{
        backgroundColor: '#9ba03b',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3em',
        paddingTop: '8em',
      }}
    >
      <div>
        <Typography
          variant="h3"
          style={{ color: 'white', marginBottom: '20px', textAlign: 'center' }}
        >
          Select your Store
        </Typography>
        <Grid container spacing={3}>
          {stores.map((store) => (
            <Grid item xs={12} key={store.id}>
              <Paper
                style={{
                  padding: '20px',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={() => handleStoreSelect(store)}
              >
                <div>
                  <Typography variant="h5">{store.storename}</Typography>
                  <Typography>{store.addressline1}</Typography>
                </div>
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default StoreSelection;