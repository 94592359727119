import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Grid, Select as MuiSelect, MenuItem, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';  // Added necessary imports
import Select from 'react-select';
import axios from 'axios';
import authService from '../services/authService';

const Settings = ({ stores }) => {
  const navigate = useNavigate();
  const [selectedStore, setSelectedStore] = useState(JSON.parse(localStorage.getItem('selectedStore')) || '');
  const [promotionDate, setPromotionDate] = useState('');
  const [selectedStores, setSelectedStores] = useState([]);
  const [promotions, setPromotions] = useState({});

  useEffect(() => {
    const accessToken = authService.getApiKey("wms");
    axios.get('https://wms-api.neicha.com.au/promotions', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => {
      setPromotions(response.data || {});
    })
    .catch(error => {
      console.error('Error fetching promotions:', error);
    });
  }, []);

  const handleStoreChange = (event) => {
    const store = stores.find(s => s.storename === event.target.value);
    setSelectedStore(store);
  };

  const handleSave = () => {
    localStorage.setItem('selectedStore', JSON.stringify(selectedStore));
    window.location.href = '/'; // This reloads the app and navigates to "/"
  };  

  const handlePromotionDateChange = (event) => {
    setPromotionDate(event.target.value);
  };

  const handleStoreSelectChange = (selectedOptions) => {
    setSelectedStores(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handlePromotionSave = () => {
    const accessToken = authService.getApiKey("wms");
    axios.post('https://wms-api.neicha.com.au/promotions', {
      date: promotionDate,
      store_ids: selectedStores,
      promotion: true
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => {
      setPromotions(response.data || {});
      setPromotionDate('');
      setSelectedStores([]);
    })
    .catch(error => {
      console.error('Error saving promotion:', error);
    });
  };

  const getStoreNameById = (id) => {
    const store = stores.find(s => s.id === id);
    return store ? store.storename : '';
  };

  const storeOptions = stores.map(store => ({
    value: store.id,
    label: `${store.storename} - ${store.addressline1}`
  }));

  return (
    <Paper style={{ padding: '20px' }}>
      <Typography variant="h4">Settings</Typography>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <Typography variant="h6">Select Store</Typography>
          <MuiSelect
            value={selectedStore?.storename || ''}
            onChange={handleStoreChange}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>Select Store</MenuItem>
            {stores && stores.length > 0 ? (
              stores.map((store) => (
                <MenuItem key={store.id} value={store.storename}>
                  {store.storename} - {store.addressline1}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>No stores available</MenuItem>
            )}
          </MuiSelect>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!selectedStore?.storename}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h5" style={{ marginTop: '40px' }}>Promotions</Typography>
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            label="Promotion Date"
            type="date"
            value={promotionDate}
            onChange={handlePromotionDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Select Stores for Promotion</Typography>
          <Select
            isMulti
            options={storeOptions}
            value={storeOptions.filter(option => selectedStores.includes(option.value))}
            onChange={handleStoreSelectChange}
            placeholder="Select Stores"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePromotionSave}
            disabled={!promotionDate || selectedStores.length === 0}
          >
            Save Promotion
          </Button>
        </Grid>
      </Grid>

      <Table style={{ marginTop: '40px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Stores</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {promotions && Object.keys(promotions).length > 0 ? (
            Object.keys(promotions).map(date => (
              <TableRow key={date}>
                <TableCell>{date}</TableCell>
                <TableCell>
                  {Array.isArray(promotions[date]) ? (
                    promotions[date].map(storeId => (
                      <div key={storeId}>{getStoreNameById(storeId)}</div>
                    ))
                  ) : (
                    <div>No stores</div>
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={2} align="center">No promotions available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <br />
    </Paper>
  );
};

export default Settings;