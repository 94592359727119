import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  TextField,
  Tooltip,
  CircularProgress,
  IconButton,
  Dialog,
  Box,
  Skeleton,
  Pagination,
  Alert,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { CheckCircle, Cancel, Close as CloseIcon } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PaidIcon from "@mui/icons-material/Paid";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FaceIcon from "@mui/icons-material/Face";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import BulkSystemFlow from '../assets/BulkSystemFlow.png';
import authService from '../services/authService';

dayjs.extend(relativeTime);

const BulkOrders = () => {
  const [bulkOrders, setBulkOrders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // NEW state to control schedule-iframe dialog
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [scheduleUrl, setScheduleUrl] = useState("");

  const [expectedDates, setExpectedDates] = useState({});
  const [loadingDates, setLoadingDates] = useState({});

  const token = authService.getApiKey("bulk_system");

  const fetchOrders = async (searchTerm = "") => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://order.petfresh.com.au/wp-json/wc/v3/search-orders/?search=${searchTerm}&page=${page}&per_page=15`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setBulkOrders(data.orders);
      setTotalOrders(data.total_orders);
      setTotalPages(Math.ceil(data.total_orders / 15));
    } catch (error) {
      console.error("Error fetching bulk orders:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchInput !== searchTerm) {
      setSearchTerm(searchInput);
      fetchOrders(searchInput);
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  // Opens a second dialog containing an <iframe> with the schedule page
  const handleOpenScheduleDialog = (scheduleOrderID) => {
    const url = `https://wms.neicha.com.au/bulk-schedules/${scheduleOrderID}`;
    setScheduleUrl(url);
    setScheduleDialogOpen(true);
  };

  const handleCloseScheduleDialog = () => {
    setScheduleDialogOpen(false);
    setScheduleUrl("");
  };

  // Function to fetch expected production date for a SKU
  const fetchExpectedProductionDate = async (sku) => {
    if (!sku) return;
    
    setLoadingDates(prev => ({ ...prev, [sku]: true }));
    try {
      const accessToken = authService.getApiKey("wms");
      const response = await fetch(`https://wms-api.neicha.com.au/protein/item-schedule/${sku}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setExpectedDates(prev => ({ ...prev, [sku]: data }));
    } catch (error) {
      console.error(`Error fetching expected date for SKU ${sku}:`, error);
    } finally {
      setLoadingDates(prev => ({ ...prev, [sku]: false }));
    }
  };

  // Fetch expected dates when dialog opens
  useEffect(() => {
    if (dialogOpen && selectedOrder && selectedOrder.status === 'prod-scheduled') {
      selectedOrder.items.forEach(item => {
        if (!item.metadata?.Status && item.sku) {
          fetchExpectedProductionDate(item.sku);
        }
      });
    }
  }, [dialogOpen, selectedOrder]);

  const statusTranslate = (status) => {
    switch (status) {
      case "processing":
        return "System Processing...";
      case "prod-scheduled":
        return "Awaiting Production";
      case "on-hold":
        return "System Held Order";
      case "pending-warehouse":
        return "Not Actioned Yet";
      case "ready-to-collect":
        return "Ready to Collect";
      case "pending":
        return "Pending Payment";
      case "waiting-for-store-transfer":
        return "Pending Transfer to Store";
      case "pickup":
        return "Awaiting Action";
      case "packed":
        return "Ready to Collect";
      case "click-collect-esc":
        return "Escalated to Warehouse";
      case "store-transfer":
        return "Awaiting Store Transfer";
      case "readycollecttxt":
        return "Ready to Collect";
      default:
        return status;
    }
  };

  const isNotPaid = (order) =>
    order.orderPaymentMethod?.toLowerCase().includes("[not paid]");

  const isOrderCompleted = (order) => !!order.completedDate;

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: "20px" }}
      >
        <Typography variant="h4">Bulk Orders</Typography>
        <TextField
          label="Search for orders by Name, Email, Phone"
          variant="outlined"
          value={searchInput}
          autoFocus
          onChange={handleSearchChange}
          onBlur={handleSearchSubmit}
          onKeyPress={handleSearchKeyPress}
          style={{ width: "60%" }}
        />
      </Grid>
      
      <img src={BulkSystemFlow} alt="Bulk System Flow" style={{ height: "100%", width: "100%" }} />

      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "300px" }}
        >
          <CircularProgress />
        </Grid>
      ) : bulkOrders.length === 0 ? (
        <Typography variant="h6">No Bulk Orders Found</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{background:"rgb(228 228 228)"}}>
                  <TableCell style={{fontWeight:"bold"}}>Order</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Placed Date</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Week Of</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Name</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Store</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Placed By</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Status</TableCell>
                  <TableCell style={{fontWeight:"bold"}}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkOrders.map((order) => (
                  <TableRow key={order.orderId}>
                    <TableCell>#{order.orderId}</TableCell>
                    <TableCell>{order.dateFormattedAWST}</TableCell>
                    <TableCell>Week Of {order.productionWeek.trim() ? order.productionWeek : "Not Set"}</TableCell>
                    <TableCell>
                      {order.firstName} {order.lastName}
                    </TableCell>
                    <TableCell>{order.collectionStore}</TableCell>
                    <TableCell>{order.placedBy}</TableCell>
                    <TableCell>{statusTranslate(order.status)}</TableCell>


                    {/* Actions */}
                    <TableCell style={{background:"rgb(245 245 245)", borderLeft:"3px dashed #adadad"}}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1, // space between icons
                        }}
                      >
                        {/* View */}
                        <Tooltip title="View Order">
                          <IconButton
                            onClick={() => handleOrderClick(order)}
                            size="small"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Tooltip>

                        {/* Paid */}
                        <Tooltip
                          title={
                            isNotPaid(order)
                              ? "Payment: Not Paid"
                              : "Payment: Paid"
                          }
                        >
                          <PaidIcon
                            sx={{
                              color: isNotPaid(order) ? "red" : "green",
                            }}
                          />
                        </Tooltip>

                        {/* Schedule (clock) */}
                        <Tooltip
                          title={
                            order.scheduled
                              ? `Scheduled Order ID: ${order.scheduleOrderID}`
                              : "Not Scheduled"
                          }
                        >
                          <span>
                            <IconButton
                              onClick={() =>
                                order.scheduled &&
                                handleOpenScheduleDialog(order.scheduleOrderID)
                              }
                              disabled={!order.scheduled}
                              size="small"
                            >
                              <ScheduleIcon
                                sx={{
                                  color: order.scheduled ? "green" : "lightgrey",
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>

                        {/* Completed (clock) */}
                        <Tooltip
                          title={
                            isOrderCompleted(order)
                              ? `Collected on: ${dayjs(
                                  order.completedDate
                                ).format("ddd, Do MMM YYYY")}`
                              : "Not collected yet"
                          }
                        >
                          <CheckCircleOutlineIcon
                            sx={{
                              color: isOrderCompleted(order)
                                ? "green"
                                : "lightgrey",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: "20px" }}
          >
            <Typography variant="body1">
              Page {page} of {totalPages} (Total Orders: {totalOrders})
            </Typography>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
          <br />
        </>
      )}

      {/* ========== ORDER DETAILS DIALOG ========== */}
      {selectedOrder && (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="md"
        >
          {/* Header Bar */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#9ba03b",
              padding: "16px",
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              {loading && !selectedOrder ? (
                <Skeleton width={200} />
              ) : (
                `Bulk Order #${selectedOrder.orderId}`
              )}
            </Typography>
            <IconButton onClick={handleCloseDialog} style={{ color: "white" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ padding: 2, marginBottom: 2 }}>
            {/* If completedDate, show alert */}
            {selectedOrder.completedDate && (
              <Alert severity="info" sx={{ marginBottom: 2 }}>
                {`This order was marked as Completed (Collected) on 
                 ${dayjs(selectedOrder.completedDate).format("ddd, Do MMM YYYY")}, 
                 ${dayjs(selectedOrder.completedDate).fromNow()}.`}
              </Alert>
            )}

            {/* If scheduled, show related schedule alert */}
            {selectedOrder.scheduled && (
              <Alert
                severity="warning"
                sx={{
                  marginBottom: 2,
                  background: "black",
                  color: "white",
                  textTransform: "capitalize",
                }}
                action={
                  <Button
                    color="inherit"
                    onClick={() =>
                      handleOpenScheduleDialog(selectedOrder.scheduleOrderID)
                    }
                  >
                    Edit Schedule
                  </Button>
                }
              >
                This order is related to a Bulk Schedule (
                #{selectedOrder.scheduleOrderID})
              </Alert>
            )}

            {/* Two-column grid for cards */}
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardHeader
                    sx={{ backgroundColor: "#dddddd" }}
                    avatar={<FaceIcon />}
                    title="Customer"
                    titleTypographyProps={{ variant: "h6" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">
                      <strong>Name:</strong> {selectedOrder.firstName}{" "}
                      {selectedOrder.lastName}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Mobile:</strong> {selectedOrder.mobile}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {selectedOrder.email}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardHeader
                    sx={{ backgroundColor: "#dddddd" }}
                    avatar={<InfoIcon />}
                    title="Order Info"
                    titleTypographyProps={{ variant: "h6" }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">
                      <strong>Order Date:</strong>{" "}
                      {selectedOrder.dateFormattedAWST}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Collection Store:</strong>{" "}
                      {selectedOrder.collectionStore}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Collection Week:</strong>{" "}
                      {selectedOrder.productionWeek}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Order Total:</strong> $
                      {selectedOrder.orderTotal}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Payment Method:</strong>{" "}
                      {selectedOrder.orderPaymentMethod}
                    </Typography>
                    {/* Removed the "Scheduled: View Schedule" text */}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Table of items with Product Image, Title, Qty, Status */}
          <TableContainer 
            component={Paper} 
            sx={{ 
              padding: "1em",
              maxHeight: 400, // Set max height to enable scrolling
              overflow: 'auto' // Enable scrolling
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{border: "1px solid lightgrey", background: "#f5f5f5"}}>Product Image</TableCell>
                  <TableCell style={{border: "1px solid lightgrey", background: "#f5f5f5"}}>Product Title</TableCell>
                  <TableCell style={{border: "1px solid lightgrey", background: "#f5f5f5"}}>Qty</TableCell>
                  <TableCell style={{border: "1px solid lightgrey", background: "#f5f5f5"}}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedOrder.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{border: "1px solid lightgrey"}}>
                      {item.thumbnailUrl ? (
                        <img
                          src={item.thumbnailUrl}
                          alt={item.productName}
                          style={{ width: "50px" }}
                        />
                      ) : (
                        "No Image"
                      )}
                    </TableCell>
                    <TableCell style={{border: "1px solid lightgrey"}}>{item.productName}</TableCell>
                    <TableCell style={{border: "1px solid lightgrey"}}>{item.qty}</TableCell>
                    <TableCell style={{border: "1px solid lightgrey"}}>
                      {item.metadata?.Status ? (
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                        >
                          <Tooltip title={item.metadata.Status}>
                            <CheckCircle
                              sx={{ color: "green", fontSize: "1rem" }}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {item.metadata.Status}
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                            <Tooltip title="Has not been made">
                              <Cancel sx={{ color: "red", fontSize: "1rem" }} />
                            </Tooltip>
                            <Typography variant="body2">
                              Not yet produced
                            </Typography>
                          </Box>
                          
                          {/* Expected Production Date - only show for items without status and order is prod-scheduled */}
                          {!item.metadata?.Status && selectedOrder.status === 'prod-scheduled' && item.sku && (
                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mt: 1, pl: 1 }}>
                              <Tooltip title="Expected Production Date">
                                <InfoIcon sx={{ color: "orange", fontSize: "1rem" }} />
                              </Tooltip>
                              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                {loadingDates[item.sku] ? (
                                  "Fetching Expected Date..."
                                ) : expectedDates[item.sku] ? (
                                  `Expected: ${expectedDates[item.sku].expectedDate || 'Date not available'}`
                                ) : (
                                  "No production date info"
                                )}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>
      )}

      {/* ========== SCHEDULE IFRAME DIALOG ========== */}
      <Dialog
        open={scheduleDialogOpen}
        onClose={handleCloseScheduleDialog}
        // Use maxWidth=false to allow custom width
        maxWidth={false}
        PaperProps={{
          sx: { width: "90vw", height: "90vh", position: "relative" },
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={handleCloseScheduleDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 10,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.4)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.6)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Iframe container */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {/* Embed the schedule link in an iframe */}
          <iframe
            title="Bulk Schedule"
            src={scheduleUrl}
            style={{
              border: "none",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Dialog>
    </div>
  );
};

export default BulkOrders;