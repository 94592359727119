import React, { useState, useEffect, useCallback } from 'react';
import * as Modules from './barcodeScannerModules';

const BarcodeListen = () => {
  const [barcode, setBarcode] = useState('');
  const [type, setType] = useState('');
  const [orderTitle, setOrderTitle] = useState('');
  const [orderData, setOrderData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [open, setOpen] = useState(false);

  const determineTypeAndTitle = (code) => {
    if (code.startsWith('PWB') || code.startsWith('BLK')) {
      return { type: 'BulkOrder', title: `Bulk Order #${code.replace(/^(PWB|BLK)/, '')}` };
    } else if (code.startsWith('REQ')) {
      return { type: 'StockRequest', title: `Stock Request #${code}` };
    } else if (code.startsWith('WHL')) {
      return { type: 'WholesaleOrder', title: `Wholesale Order #${code}` };
    } else if (/^\d{12,13}$/.test(code)) {
      return { type: 'ProductScan', title: 'Product Scan' };
    } else {
      return { type: 'WebOrder', title: `Web Order #${code}` };
    }
  };

  const handleScan = useCallback(
    async (code) => {
      const { type, title } = determineTypeAndTitle(code);
      setType(type);
      setOrderTitle(title);

      const processFunction = Modules[`process${type}`];
      if (processFunction) {
        try {
          if (type === 'ProductScan') {
            await processFunction(code, setProductData);
          } else {
            await processFunction(code, setOrderData);
          }
          setOpen(true);
        } catch (error) {
          console.error(`Error processing ${type}:`, error);
        }
      }
    },
    [setType, setOrderTitle, setOrderData, setProductData, setOpen]
  );

  useEffect(() => {
    let buffer = '';

    const handleKeyPress = (e) => {
      const activeElement = document.activeElement;
      const isInputFocused =
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.contentEditable === 'true';

      if (!isInputFocused && /^[a-zA-Z0-9]$/i.test(e.key)) {
        buffer += e.key;
      }

      if (!isInputFocused && e.key === 'Enter') {
        if (buffer) {
          handleScan(buffer);
          buffer = '';
        }
      }
    };

    // Add handler for our custom event
    const handleCustomBarcodeScan = (e) => {
      if (e.detail && e.detail.barcode) {
        handleScan(e.detail.barcode);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    // Listen for our custom event
    window.addEventListener('barcodescanned', handleCustomBarcodeScan);
    
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('barcodescanned', handleCustomBarcodeScan);
    };
  }, [handleScan]);

  const DialogComponent = type && Modules[type];

  return (
    <>
      {DialogComponent && (
        <DialogComponent
          open={open}
          onClose={() => setOpen(false)}
          orderTitle={orderTitle}
          orderData={type === 'ProductScan' ? productData : orderData}
        />
      )}
    </>
  );
};

export default BarcodeListen;