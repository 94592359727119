import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import axios from 'axios';
import authService from '../../services/authService';

const ProductScan = ({ open, onClose, orderTitle, productData }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{orderTitle}</DialogTitle>
      <DialogContent>
        {productData ? (
          <Typography variant="h6">Product Name: {productData.productname}</Typography>
        ) : (
          <Typography variant="h6" color="error">
            Product Not Found
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const processProductScan = async (barcode, setProductData) => {
  const accessToken = authService.getApiKey("wms");
  try {
    const response = await axios.get(`https://wms-api.neicha.com.au/product?barcode=${barcode}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    setProductData(response.data);
  } catch (error) {
    console.error('Error fetching product:', error);
    setProductData(null);
  }
};

export default ProductScan;