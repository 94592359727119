import React, { useState, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from './auth_config.json';
import authService from './services/authService';
import AuthOverlay from './components/AuthOverlay';

const AuthWrapper = ({ children }) => {
  const [skipAuth, setSkipAuth] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    // Extract query parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Define possible Client IDs
    const normalClientId = authConfig.clientId;
    const altClientId = "lZMhsKeFV3oxbdkTICInd7qLHsmv6Hj3"; // Alternate Client ID for query param auth

    // Detect which Client ID should be used
    const queryClientId = urlParams.has("authUser") ? altClientId : normalClientId;

    // Construct the dynamic storage keys based on detected Client ID
    const authUserKey = `@@auth0spajs@@::${queryClientId}::@@user@@`;
    const authTokenKey = `@@auth0spajs@@::${queryClientId}::${authConfig.audience}::${authConfig.scope}`;
    const storeKey = "selectedStore";

    let foundValidAuth = false;

    // Check for Auth0 user data in the URL
    if (urlParams.has("authUser") && urlParams.has("authToken")) {
      try {
        const authUserData = JSON.parse(decodeURIComponent(urlParams.get("authUser")));
        const authTokenData = JSON.parse(decodeURIComponent(urlParams.get("authToken")));

        // Store these in localStorage
        localStorage.setItem(authUserKey, JSON.stringify(authUserData));
        localStorage.setItem(authTokenKey, JSON.stringify(authTokenData));
        
        // Record when we got the refresh token
        localStorage.setItem('auth_refresh_token_timestamp', Date.now().toString());

        // Mark authentication as valid
        foundValidAuth = true;
      } catch (error) {
        console.error("Error parsing Auth0 data from URL:", error);
      }
    }

    // Check for selected store in the URL
    if (urlParams.has(storeKey)) {
      try {
        const storeData = JSON.parse(decodeURIComponent(urlParams.get(storeKey)));
        localStorage.setItem(storeKey, JSON.stringify(storeData));
      } catch (error) {
        console.error("Error parsing selectedStore data from URL:", error);
      }
    }

    // If valid auth tokens were found, skip Auth0 login
    if (foundValidAuth) {
      setSkipAuth(true);

      // Initialize auth service and fetch API keys
      authService.init().then(() => {
        setIsCheckingAuth(false);
      });

      // Remove query parameters from URL for security
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      // Check if we already have valid authentication
      authService.init().then((isValid) => {
        if (isValid) {
          setSkipAuth(true);
        }
        setIsCheckingAuth(false);
      });
    }
  }, []);

  // Auth0's onRedirectCallback function
  const onRedirectCallback = (appState) => {
    console.log("Auth0 redirect callback executed, storing getUserInfo function");
    
    // Extract the access token and user info from Auth0 after login
    const getUserInfo = async (auth0Client) => {
      console.log("getUserInfo called with client:", !!auth0Client);
      
      if (!auth0Client) {
        console.error("Auth0 client not provided to getUserInfo");
        return;
      }
      
      try {
        // Get the token directly from Auth0
        const token = await auth0Client.getTokenSilently({
          authorizationParams: {
            audience: authConfig.audience,
            scope: authConfig.scope
          }
        });
        
        console.log("Token received directly from Auth0:", !!token);
        
        const user = await auth0Client.getUser();
        
        // Get the token object from localStorage 
        const tokenKey = `@@auth0spajs@@::${authConfig.clientId}::${authConfig.audience}::${authConfig.scope}`;
        const tokenDataStr = localStorage.getItem(tokenKey);
        
        console.log("Token data string in storage:", !!tokenDataStr);
        let tokenData = tokenDataStr ? JSON.parse(tokenDataStr) : null;
        console.log("Token data available:", !!tokenData);
        
        // If no token data, create a minimal object
        if (!tokenData && token) {
          console.log("Creating minimal token data object");
          tokenData = { access_token: token };
        }
        // Ensure the tokenData has the access_token property
        else if (tokenData && !tokenData.access_token && token) {
          console.log("Adding access_token to tokenData");
          tokenData.access_token = token;
        }
        
        console.log("User data available:", !!user);
        
        // Use our auth service to handle the token
        if (user && token) {
          if (typeof authService.setAuthData === 'function') {
            await authService.setAuthData(user, tokenData || { access_token: token });
          } else {
            console.error("authService.setAuthData is not a function");
          }
        }
      } catch (error) {
        console.error("Error getting user info after redirect:", error);
      }
    };

    window.history.replaceState(
      {},
      document.title,
      appState?.returnTo || window.location.pathname
    );

    // Store the callback function for later use
    window.__auth0_getUserInfo = getUserInfo;
  };

  if (isCheckingAuth) {
    return null; // Or a loading spinner
  }

  if (skipAuth) {
    // Tokens are already set
    return (
      <>
        <AuthOverlay />
        {children}
      </>
    );
  }

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: authConfig.audience,
        scope: authConfig.scope,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthOverlay />
      {children}
    </Auth0Provider>
  );
};

export default AuthWrapper;