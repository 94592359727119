// src/components/ViewClickCollectOrder.js

import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Button, Typography, Grid, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Select, MenuItem, IconButton, 
  Chip, Box, Divider, Card, CardContent, Alert, Snackbar, Dialog,
  DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, 
  Backdrop, Drawer, List, ListItem, ListItemIcon, ListItemText, Paper
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';
import RefundIcon from '@mui/icons-material/MoneyOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCommentIcon from '@mui/icons-material/AddComment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import NoteIcon from '@mui/icons-material/Note';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import authService from '../services/authService';

const ViewClickCollectOrder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { order } = state;
  const [loading, setLoading] = useState(false);
  const [updateNote, setUpdateNote] = useState('');
  const [noteType, setNoteType] = useState('internal');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState('');
  const [refundReason, setRefundReason] = useState('Customer refund requested');
  const [isPrinting, setIsPrinting] = useState(false);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Existing helper functions
  const translateOrderStatus = (status) => {
    switch (status) {
      case 'prod-scheduled': return 'Awaiting Production';
      case 'pending-fulfilment': return 'Not Actioned Yet';
      case 'ready-to-collect': return 'Ready to Collect';
      case 'waiting-for-store-transfer': return 'Pending Transfer to Store';
      case 'pickup': return 'Awaiting Action';
      case 'packed': return 'Ready to Collect';
      case 'click-collect-esc': return 'Escalated to Warehouse';
      case 'store-transfer': return 'Awaiting Store Transfer';
      case 'ready-collect': return 'Ready to Collect';
      default: return status;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Awaiting Production': return 'warning';
      case 'Not Actioned Yet': return 'error';
      case 'Ready to Collect': return 'success';
      case 'Pending Transfer to Store': return 'info';
      case 'Awaiting Action': return 'warning';
      case 'Escalated to Warehouse': return 'error';
      case 'Awaiting Store Transfer': return 'info';
      default: return 'default';
    }
  };

  const formatDate = (dateString) => {
    try {
      return format(parseISO(dateString), 'dd/MM/yy hh:mm a');
    } catch (error) {
      return dateString;
    }
  };

  // Existing action handlers
  const handlePrint = async () => {
    setIsPrinting(true);
    
    try {
      const token = authService.getApiKey("petfresh_backend");
      const response = await axios.get(
        `https://www.petfresh.com.au/wp-json/wc/v3/bulk-packing-slips/?order_ids=${order.order_id}`,
        { 
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob'
        }
      );
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      
      const newWindow = window.open(pdfUrl, '_blank');
      if (!newWindow) {
        setNotification({
          open: true,
          message: 'Please enable pop-ups to view the packing slip',
          severity: 'warning'
        });
      }
    } catch (error) {
      console.error('Error fetching packing slip:', error);
      setNotification({
        open: true,
        message: 'Failed to generate packing slip',
        severity: 'error'
      });
    } finally {
      setIsPrinting(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    setLoading(true);
    try {
      const token = authService.getApiKey("petfresh_backend");
      await axios.post(
        `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/update-status`,
        {
          order_id: order.order_id,
          status: newStatus,
          note: `Order status updated to ${translateOrderStatus(newStatus)}`
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setNotification({
        open: true,
        message: `Order status updated to ${translateOrderStatus(newStatus)}`,
        severity: 'success'
      });
      // Update local order status
      order.current_status = newStatus;
    } catch (error) {
      console.error('Error updating order status:', error);
      setNotification({
        open: true,
        message: 'Failed to update order status',
        severity: 'error'
      });
    } finally {
      setLoading(false);
      setDrawerOpen(false);
    }
  };

  const openRefundDialog = () => {
    const totalAmount = order.order_items.reduce(
      (total, item) => total + item.qty * parseFloat(item.price), 
      0
    ).toFixed(2);
    setRefundAmount(totalAmount);
    setRefundDialogOpen(true);
    setDrawerOpen(false);
  };

  const handleRefund = async () => {
    if (!refundAmount || isNaN(parseFloat(refundAmount))) {
      setNotification({
        open: true,
        message: 'Please enter a valid refund amount',
        severity: 'error'
      });
      return;
    }

    setLoading(true);
    setRefundDialogOpen(false);
    
    try {
      const token = authService.getApiKey("petfresh_backend");
      await axios.post(
        `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${order.order_id}/refund`,
        {
          amount: parseFloat(refundAmount),
          reason: refundReason
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setNotification({
        open: true,
        message: `Order successfully refunded ($${parseFloat(refundAmount).toFixed(2)})`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error refunding order:', error);
      setNotification({
        open: true,
        message: 'Failed to process refund',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (!updateNote.trim()) return;
    
    setLoading(true);
    try {
      const token = authService.getApiKey("petfresh_backend");
      await axios.post(
        `https://petfresh.com.au/wp-json/wc/v3/pickup-orders/${order.order_id}/notes`,
        {
          note: updateNote,
          type: noteType
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      // Add note to local state
      order.order_notes.unshift({
        note: updateNote,
        date: new Date().toISOString(),
        type: noteType
      });
      
      setUpdateNote('');
      setShowNoteForm(false);
      setNotification({
        open: true,
        message: 'Note added successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error adding note:', error);
      setNotification({
        open: true,
        message: 'Failed to add note',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const statusText = translateOrderStatus(order.current_status);
  const statusColor = getStatusColor(statusText);
  const isReadyToCollect = statusText === 'Ready to Collect';

  // Calculate total amount
  const totalAmount = order.order_items.reduce(
    (total, item) => total + item.qty * parseFloat(item.price), 
    0
  ).toFixed(2);

  return (
    <>
      {/* Header with back button and actions */}
      <Grid container spacing={2} sx={{ mb: 3 }} alignItems="center">
        <Grid item>
          <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant="h5" component="span" sx={{ mr: 1 }}>
              Order #{order.order_id}
            </Typography>
            <Chip 
              label={statusText}
              color={statusColor}
              size="small"
              sx={{ fontWeight: 'medium', mr: 1 }}
            />
            <Typography variant="subtitle2" component="span">
              {formatDate(order.order_date)}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            endIcon={<MoreVertIcon />}
            onClick={() => setDrawerOpen(true)}
          >
            Order Actions
          </Button>
        </Grid>
      </Grid>

      {/* Main content area with 2-column layout */}
      <Grid style={{marginBottom:"1.5em"}} container spacing={3}>
        {/* Left side - Order details (75% width) */}
        <Grid item xs={9}>
          {/* Customer information card */}
          <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>Customer Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PersonIcon sx={{ mr: 1, color: 'text.secondary' }} />
                  <Typography variant="body1">
                    <strong>Name:</strong> {order.first_name} {order.last_name}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
                  <Typography variant="body1">
                    <strong>Email:</strong> {order.customer_email}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PhoneIcon sx={{ mr: 1, color: 'text.secondary' }} />
                  <Typography variant="body1">
                    <strong>Phone:</strong> {order.billing_mobile}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <PaymentIcon sx={{ mr: 1, color: 'text.secondary' }} />
                  <Typography variant="body1">
                    <strong>Payment:</strong> {order.payment_method}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                  <NoteIcon sx={{ mr: 1, mt: 0.5, color: 'text.secondary' }} />
                  <Box>
                    <Typography variant="body1" component="span">
                      <strong>Customer Note:</strong>
                    </Typography>
                    <Typography variant="body1">
                      {order.customer_note || "No notes provided"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Order items */}
          <Paper elevation={1} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Order Items</Typography>
            <TableContainer sx={{ mb: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                    <TableCell>Product</TableCell>
                    <TableCell align="center">Qty</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.order_items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.title}</TableCell>
                      <TableCell align="center">{item.qty}</TableCell>
                      <TableCell align="right">${parseFloat(item.price).toFixed(2)}</TableCell>
                      <TableCell align="right">${(item.qty * parseFloat(item.price)).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell align="right"><strong>Total:</strong></TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1" fontWeight="bold">
                        ${totalAmount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {/* Right side - Order updates sidebar (25% width) */}
        <Grid item xs={3}>
          <Card variant="outlined">
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Order Updates</Typography>
                <Button 
                  startIcon={<AddCommentIcon />} 
                  onClick={() => setShowNoteForm(!showNoteForm)} 
                  size="small"
                  sx={{border: "1px solid #9ba03b", color: "#9ba03b"}}
                >
                  Add Note
                </Button>
              </Box>
              
              {/* Conditionally render the Add Note Form */}
              {showNoteForm && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    label="Add Update"
                    value={updateNote}
                    onChange={(e) => setUpdateNote(e.target.value)}
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                    size="small"
                  />
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Select
                        value={noteType}
                        onChange={(e) => setNoteType(e.target.value)}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="internal">Internal Note</MenuItem>
                        <MenuItem value="customer">Customer Notification</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                      <Button 
                        variant="contained" 
                        onClick={handleAddNote}
                        fullWidth
                        disabled={loading || !updateNote.trim()}
                        size="small"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                </Box>
              )}
              
              {/* Order history list */}
              <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
                {order.order_notes?.length === 0 ? (
                  <Typography variant="body2" color="text.secondary" align="center">
                    No updates yet
                  </Typography>
                ) : (
                  order.order_notes?.map((note, index) => (
                    <Box key={index} sx={{ mb: 2, pb: 2, borderBottom: index < order.order_notes.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none' }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {formatDate(note.date)} • {note.type === 'customer' ? 'Customer Note' : 'Internal'}
                      </Typography>
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: note.note }} />
                    </Box>
                  ))
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Order Actions Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 300, p: 2, paddingTop: "3em" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Order Actions</Typography>
          <Divider sx={{ mb: 2 }} />
          
          <List>
            <ListItem button onClick={handlePrint} disabled={isPrinting}>
              <ListItemIcon>
                <PrintIcon />
              </ListItemIcon>
              <ListItemText primary="Print Packing Slip" />
            </ListItem>
            
            <ListItem button onClick={openRefundDialog} disabled={loading}>
              <ListItemIcon>
                <RefundIcon />
              </ListItemIcon>
              <ListItemText primary="Process Refund" />
            </ListItem>
            
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle2" sx={{ px: 2, mb: 1 }}>Status Updates</Typography>
            
            {isReadyToCollect ? (
              <ListItem button onClick={() => handleStatusChange('completed')} disabled={loading}>
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary="Mark Completed" />
              </ListItem>
            ) : (
              <>
                <ListItem button onClick={() => handleStatusChange('ready-collect')} disabled={loading}>
                  <ListItemIcon>
                    <AssignmentTurnedInIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary="Mark Ready to Collect" />
                </ListItem>
                
                <ListItem button onClick={() => handleStatusChange('store-transfer')} disabled={loading}>
                  <ListItemIcon>
                    <LocalShippingIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Store Transfer" />
                </ListItem>
                
                <ListItem button onClick={() => handleStatusChange('click-collect-esc')} disabled={loading}>
                  <ListItemIcon>
                    <WarningIcon color="warning" />
                  </ListItemIcon>
                  <ListItemText primary="Escalate to Warehouse" />
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Drawer>
      
      {/* Refund Dialog */}
      <Dialog open={refundDialogOpen} onClose={() => setRefundDialogOpen(false)}>
        <DialogTitle>Process Refund</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Refund Amount ($)"
            type="number"
            fullWidth
            variant="outlined"
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
            InputProps={{
              startAdornment: <Typography variant="body1">$</Typography>,
            }}
          />
          <TextField
            margin="dense"
            label="Reason for Refund"
            type="text"
            fullWidth
            variant="outlined"
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            multiline
            rows={2}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRefundDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleRefund} color="primary" variant="contained">Process Refund</Button>
        </DialogActions>
      </Dialog>
      
      {/* Notifications */}
      <Snackbar 
        open={notification.open} 
        autoHideDuration={6000} 
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
      
      {/* Loading overlay */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isPrinting || loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ViewClickCollectOrder;
