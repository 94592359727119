import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField, InputAdornment, Table, TableBody, TableCell, TableRow, Box, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MobileBottomNav from './MobileBottomNav'; // Import the bottom navigation component
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import authService from '../../services/authService';

const SearchInventory = () => {
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null); // Store selected product for modal
    const [lastQuery, setLastQuery] = useState(''); // To avoid duplicate searches
    const [openModal, setOpenModal] = useState(false); // Modal state for product details

    useEffect(() => {
        document.getElementById('search-field').focus(); // Autofocus on load
    }, []);

    const handleSearch = async () => {
        if (query && query !== lastQuery) {
            setLoading(true);
            const access_token = authService.getApiKey("wms");
            const url = `https://wms-api.neicha.com.au/search-items?query=${query}`;

            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setSearchResults(data);
                    setLastQuery(query); // Update last query to avoid duplicate searches
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleBlur = () => {
        handleSearch(); // Trigger search on input blur
    };

    const openProductModal = (product) => {
        setSelectedProduct(product);
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        setSelectedProduct(null);
    };

    return (
            <Grid container direction="column" alignItems="center" spacing={2} style={{ paddingBottom: '56px', paddingTop: '64px', minHeight: 'calc(100vh - 56px)' }}>            <Grid item>
                <Typography variant="h4">Search Inventory</Typography>
            </Grid>
            <Grid item style={{ width: '100%', padding: "0.25em" }}>
                <TextField
                    id="search-field"
                    label="Search Products"
                    variant="outlined"
                    fullWidth
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                    onBlur={handleBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>

            {/* Display search results */}
            {Object.keys(searchResults).length > 0 && (
                <Grid item xs={12} style={{ width: '100%', }}>
                    {Object.entries(searchResults).map(([category, products]) => (
                        <div key={category}>
                            <Typography variant="h6" style={{ backgroundColor: '#a3a3a3', padding: '8px' }}>{category}</Typography>
                            <Table>
                                <TableBody>
                                    {products.map((product) => (
                                        <TableRow key={product.id} onClick={() => openProductModal(product)} style={{ cursor: 'pointer' }}>
                                            <TableCell>{product.productname}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    ))}
                </Grid>
            )}

            {/* Loading spinner */}
            <Backdrop open={loading} style={{ zIndex: 9999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Modal for displaying product details */}
            {selectedProduct && (
                <Dialog open={openModal} onClose={closeModal} fullScreen>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#9ba03b', padding: '16px', color: 'white' }}>
                        <Typography variant="h6">Product Details</Typography>
                    </Box>
                    <DialogContent>
                        <Typography variant="h6">{selectedProduct.productname}</Typography>
                        <Typography variant="body1">SKU: {selectedProduct.sku}</Typography>
                        <Typography variant="body1">Price: ${(selectedProduct.productprice / 100).toFixed(2)}</Typography>
                        <Table>
                            <TableBody>
                                {selectedProduct.stockLevels.length > 0 ? (
                                    selectedProduct.stockLevels.map((stock) => (
                                        <TableRow key={stock.locationId}>
                                            <TableCell>{stock.locationName}</TableCell>
                                            <TableCell>{stock.stockLevel}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <Typography variant="body2">No stock information available</Typography>
                                )}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeModal} variant="contained" fullWidth style={{ backgroundColor: '#9ba03b', color: 'white' }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <MobileBottomNav /> {/* Add the bottom navigation bar */}
        </Grid>
    );
};

export default SearchInventory;
