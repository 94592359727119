import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import authService from '../services/authService';

const SERVICE_TO_SESSION_KEY = {
  'wms': 'wms_access_token',
  'bulk_system': 'pfresh_bulk_jwt_token',
  'petfresh_backend': 'petfresh_jwt_token',
  'qpilot': 'qpilot_token'  // Add this line
};

export default function useAuth() {
  const auth0 = useAuth0();
  const [apiKeys, setApiKeys] = useState(authService.getApiKeys());
  const [apiKeyErrors, setApiKeyErrors] = useState({});
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isFetchingApiKeys, setIsFetchingApiKeys] = useState(false);

  // Initialize auth service when Auth0 is ready
  useEffect(() => {
    if (auth0.isAuthenticated && !auth0.isLoading) {
      authService.init({
        getTokenSilently: auth0.getTokenSilently,
        getUser: () => Promise.resolve(auth0.user)
      });
    }
  }, [auth0.isAuthenticated, auth0.isLoading, auth0.user]);

  // Listen for auth service events
  useEffect(() => {
    const unsubscribe = authService.addListener((event) => {
      if (event.type === 'apiKeysUpdated') {
        setApiKeys(event.tokens || {});
        setApiKeyErrors(event.errors || {});
      } else if (event.type === 'refreshingToken') {
        setIsRefreshingToken(event.isRefreshing);
      } else if (event.type === 'fetchingApiKeys') {
        setIsFetchingApiKeys(event.isFetching);
      }
    });

    return unsubscribe;
  }, []);

  // Token refresh check every 30 minutes
  useEffect(() => {
    if (!auth0.isAuthenticated) return;
    
    const interval = setInterval(() => {
      console.log("Periodic token check");
      authService.refreshAccessToken();
    }, 30 * 60 * 1000); // 30 minutes
    
    return () => clearInterval(interval);
  }, [auth0.isAuthenticated]);
  
  // Handle visibility changes for tab/browser reactivation
  useEffect(() => {
    if (!auth0.isAuthenticated) return;
    
    let lastVisible = Date.now();
    
    const handleVisibility = () => {
      if (document.visibilityState === 'visible') {
        const timeHidden = Date.now() - lastVisible;
        // If hidden for more than 5 minutes
        if (timeHidden > 5 * 60 * 1000) {
          console.log("Page visible after inactivity, checking tokens");
          authService.handleVisibilityChange(auth0.isAuthenticated);
        }
      } else {
        lastVisible = Date.now();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibility);
    return () => document.removeEventListener('visibilitychange', handleVisibility);
  }, [auth0.isAuthenticated]);
  
  // Fetch API keys when authenticated
  useEffect(() => {
    if (auth0.isAuthenticated && !auth0.isLoading) {
      // If we don't have API keys, fetch them
      const currentKeys = authService.getApiKeys();
      if (Object.keys(currentKeys).length === 0) {
        authService.fetchApiKeys();
      }
    }
  }, [auth0.isAuthenticated, auth0.isLoading]);

  // Helper to get API key with fallback
  const getApiKey = useCallback((service) => {
    return authService.getApiKey(service);
  }, []);

  return {
    ...auth0,
    apiKeys,
    apiKeyErrors,
    isRefreshingToken,
    isFetchingApiKeys,
    refreshToken: () => authService.refreshAccessToken(),
    getApiKey,
    hasAccess: (service) => !!getApiKey(service)
  };
}