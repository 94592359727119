import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, Grid, Dialog, DialogContent, DialogActions,
  Button, Box, Card, CardContent, Backdrop, CircularProgress
} from '@mui/material';
import MobileBottomNav from './MobileBottomNav'; // bottom nav
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import authService from '../../services/authService';

const BatchReceiveBulkOrders = () => {
  const [modalMessage, setModalMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [scannedOrder, setScannedOrder] = useState(false);
  const [errorSound, setErrorSound] = useState(false);
  const [successSound, setSuccessSound] = useState(false);
  const [orderCards, setOrderCards] = useState(JSON.parse(localStorage.getItem('orderCards')) || []);
  const [overrideRequest, setOverrideRequest] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const holdTimer = useRef(null);
  

  const handleCameraLongPressStart = () => {
    holdTimer.current = setTimeout(() => {
      setSettingsModalOpen(true);
    }, 3000); // 3 seconds
  };
  
  const handleCameraLongPressEnd = () => {
    if (holdTimer.current) {
      clearTimeout(holdTimer.current);
      holdTimer.current = null;
    }
  };

  // Ref to hold the camera’s container (so we can append the camera’s UI to it)
  const cameraContainerRef = useRef(null);

  // ---------------------------
  // 1) Dynamsoft Camera Setup
  // ---------------------------
  useEffect(() => {
    let cvRouter = null; 
    let cameraEnhancer = null;
    
    // Wrap everything in an async function
    const initDynamsoftScanner = async () => {
      if (!window.Dynamsoft) {
        console.error("Dynamsoft SDK not loaded. Make sure your <script> tag is correct.");
        return;
      }
      try {
        // 1) License
        window.Dynamsoft.License.LicenseManager.initLicense("DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyODQ5MjE0LVRYbFhaV0pRY205cSIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyODQ5MjE0Iiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2Rscy5keW5hbXNvZnRvbmxpbmUuY29tIiwiY2hlY2tDb2RlIjoxOTk4NDI4MDEyfQ==");

        // 2) If using the CDN, optionally set the engine resource path 
        //    (generally not strictly required if you are using the default from the bundle).
        window.Dynamsoft.Core.CoreModule.engineResourcePaths.rootDirectory =
          "https://cdn.jsdelivr.net/npm/";

        // 3) Preload the wasm for speed
        await window.Dynamsoft.Core.CoreModule.loadWasm(["dbr"]);

        // 4) Create the router
        cvRouter = await window.Dynamsoft.CVR.CaptureVisionRouter.createInstance();

        // 5) Create the camera view & cameraEnhancer
        const cameraView = await window.Dynamsoft.DCE.CameraView.createInstance();
        cameraEnhancer = await window.Dynamsoft.DCE.CameraEnhancer.createInstance(cameraView);

        cameraEnhancer.setScanRegion({
          x: 25,
          y: 25,
          width: 55,
          height: 40,
          isMeasuredInPercentage: true,
        });     

        // 6) Append the camera UI to our <div ref={cameraContainerRef}>
        if (cameraContainerRef.current) {
            cameraContainerRef.current.appendChild(cameraView.getUIElement());
        }
          
          // Remove the camera + resolution dropdown
          cameraView.getUIElement().shadowRoot.querySelector('.dce-sel-camera')?.remove();
          cameraView.getUIElement().shadowRoot.querySelector('.dce-sel-resolution')?.remove();
          
          // 7) Plug cameraEnhancer into the router
          cvRouter.setInput(cameraEnhancer);

        // 8) Add a result receiver (this is how we get the scanned barcodes)
        cvRouter.addResultReceiver({
          onCapturedResultReceived: (result) => {
            // If barcodes were found
            const items = result.barcodeResultItems || [];
            if (items.length > 0) {
              // In this example, we only process the first found code
              // If you want to handle multiple, you can iterate here
              const codeText = items[0].text;
              setScannedBarcode(codeText);
              processBarcode(codeText);
            }
          }
        });

        // 9) Add a filter to avoid duplicates
        let filter = new window.Dynamsoft.Utility.MultiFrameResultCrossFilter();
        filter.enableResultCrossVerification("barcode", true);
        filter.enableResultDeduplication("barcode", true);
        await cvRouter.addResultFilter(filter);

        // 10) Start camera & scanning
        await cameraEnhancer.open();
        await cvRouter.startCapturing("ReadSingleBarcode");

      } catch (err) {
        console.error("Error initializing Dynamsoft camera scanning:", err);
      }
    };

    // Initialize the camera scanner
    initDynamsoftScanner();

    // Cleanup on unmount
    return () => {
      if (cvRouter) {
        cvRouter.dispose();
        cvRouter = null;
      }
      if (cameraEnhancer) {
        cameraEnhancer.dispose();
        cameraEnhancer = null;
      }
    };
  }, []); // Run once on component mount

  // -------------------------------
  // 2) Sound Effects / beep Logic
  // -------------------------------
  const playSound = (soundFilename) => {
    const audio = new Audio(require(`../../assets/sounds/${soundFilename}`));
    audio.play();
    return audio;
  };

  useEffect(() => {
    let soundQueue = null;

    if (errorSound) {
      soundQueue = playSound('error.mp3');
      soundQueue.addEventListener('ended', () => {
        if (errorSound) soundQueue.play(); // loop until state changes
      });
    } else if (successSound) {
      soundQueue = playSound('success.mp3');
      soundQueue.addEventListener('ended', () => {
        setSuccessSound(false); // reset after playing
      });
    }

    return () => {
      if (soundQueue) {
        soundQueue.pause();
        soundQueue.currentTime = 0;
      }
    };
  }, [errorSound, successSound]);

  // ----------------------
  // 3) Processing Barcode
  // ----------------------
  const processBarcode = async (scanned) => {
    console.log("Scanned Barcode:", scanned);
    // Keep a state record
    setScannedBarcode(scanned);

    // Bulk order test: starts with 'PWB' and has the pattern PWB{digits}-{digits} or PWB{digits}/{digits}
    if (scanned.startsWith('PWB') && /^PWB\d+[-/]\d+$/.test(scanned)) {
      console.log("This is a Bulk Order");
      const orderNumber = scanned.match(/^PWB(\d+)[-/]/)[1];
      await updateOrderStatus(orderNumber, 'Bulk Order');
    } else {
      // Unrecognized
      setModalTitle('ERROR');
      setModalMessage("Invalid Scan: Only bulk orders are supported.");
      setErrorSound(true);
      setOpenModal(true);
    }
  };

  // --------------------------
  // 4) Update Order via API
  // --------------------------
  const updateOrderStatus = async (formattedOrderId, scanType, override = false) => {
    const token = authService.getApiKey("bulk_system");
    const url = `https://order.petfresh.com.au/wp-json/wc/v3/update-order-status/${formattedOrderId}${override ? '?override=1' : ''}`;

    setLoading(true);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (response.ok) {
        // success
        const { order_id, customer_first_name, customer_last_name, items_ordered } = data;
        addOrderCard(
          order_id,
          `${customer_first_name} ${customer_last_name}`,
          scanType,
          new Date().toLocaleString(),
          'success',
          items_ordered
        );
        setSuccessSound(true); // beep for success
      } else {
        // handle different errors
        handleApiError(data, formattedOrderId, scanType);
      }
    } catch (error) {
      console.error('Error updating order:', error);
      setModalTitle('ERROR');
      setModalMessage('Failed to connect to the server.');
      setErrorSound(true);
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleApiError = (errorData, formattedOrderId, scanType) => {
    const { code, message } = errorData;
    if (code === "invalid_order") {
      setModalTitle('ERROR');
      setModalMessage("Invalid order ID.");
    } else if (code === "order_already_in_status") {
      setModalTitle(`Order is already changed`);
      setModalMessage(message);
      setCurrentOrderId(formattedOrderId);
      setOverrideRequest(true);
    } else {
      setModalTitle('ERROR');
      setModalMessage(message);
    }
    setErrorSound(true);
    setOpenModal(true);
  };

  // Add order to the UI cards
  const addOrderCard = (orderId, customerName, scanType, scanTime, status, itemsOrMessage) => {
    const newCard = {
      orderId,
      customerName,
      scanType,
      scanTime,
      status,
      items: itemsOrMessage
    };

    const updatedOrderCards = [newCard, ...orderCards];
    setOrderCards(updatedOrderCards);
    localStorage.setItem('orderCards', JSON.stringify(updatedOrderCards));
    setScannedOrder(true);
  };

  const resendWithOverride = async () => {
    if (currentOrderId) {
      setLoading(true);
      const token = authService.getApiKey("bulk_system");
      const url = `https://order.petfresh.com.au/wp-json/wc/v3/update-order-status/${currentOrderId}?override=1`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        if (response.ok) {
          // success
          const { order_id, customer_first_name, customer_last_name, items_ordered } = data;
          addOrderCard(
            order_id,
            `${customer_first_name} ${customer_last_name}`,
            'Bulk Order',
            new Date().toLocaleString(),
            'success',
            items_ordered
          );
          setSuccessSound(true);
          closeModal();
        } else {
          // still an error
          handleApiError(data, currentOrderId, 'Bulk Order');
        }
      } catch (error) {
        console.error('Error updating order:', error);
        setModalTitle('ERROR');
        setModalMessage('Failed to connect to the server.');
        setErrorSound(true);
        setOpenModal(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    setOpenModal(false);
    setErrorSound(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      style={{
        paddingBottom: '0px',
        paddingTop: '16px',
        minHeight: 'calc(100vh - 56px)',
      }}
    >

      {/*
        5) Where we show the live camera feed:
        You can style this <div> as you like.
      */}
      <Grid item style={{ width: '100%', background: "black" }}>
        <div
          ref={cameraContainerRef}
          style={{
            width: '100%',
            height: '90vh',
            marginTop: '5vh',
            background: '#000',  // fallback color if camera not loaded
          }}
            onMouseDown={handleCameraLongPressStart}
            onTouchStart={handleCameraLongPressStart}
            onMouseUp={handleCameraLongPressEnd}
            onTouchEnd={handleCameraLongPressEnd}
        />
      </Grid>

      {/* Display scanned orders */}
      {orderCards.map((order) => (
        <Grid item xs={12} key={order.scanTime} style={{ width: '90%' }}>
          <Card
            style={{
              backgroundColor:
                order.status === 'success' ? '#77DD77' : '#FF0000',
              marginBottom: '16px',
            }}
          >
            <CardContent>
              <Typography style={{ fontWeight: 'bold' }} variant="h6">
                {order.status === 'success'
                  ? `${order.scanType} #${order.orderId}`
                  : `ERROR #${order.orderId}`}
              </Typography>
              <Typography variant="body1">Customer: {order.customerName}</Typography>
              <Typography variant="body2">Scanned at: {order.scanTime}</Typography>
              <hr style={{ borderColor: '#00000042', borderWidth: '0.5px' }} />
              {order.status === 'success' ? (
                // Show items
                Object.entries(order.items).map(([item, qty]) => (
                  <Typography
                    variant="body2"
                    key={item}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span>{item}</span>
                    <span style={{ fontWeight: 'bold' }}>{qty}</span>
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">{order.items}</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}

      {/* Loading spinner */}
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" style={{ color: '#FFFFFF' }} />
      </Backdrop>

      {/* Modal for errors or override */}
      <Dialog open={openModal} onClose={closeModal} fullScreen>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#FF0000',
            padding: '16px',
            color: 'white',
          }}
        >
          <Typography variant="h6">{modalTitle}</Typography>
        </Box>
        <DialogContent>
          <Typography variant="body1" align="center">
            {modalMessage}
          </Typography>
          {overrideRequest && (
            <Button
              onClick={resendWithOverride}
              variant="contained"
              color="primary"
              fullWidth
              style={{ marginTop: '16px', backgroundColor: '#FF0000' }}
            >
              Change to Ready To Collect again
            </Button>
          )}
        </DialogContent>
        <DialogActions
          sx={{ position: 'absolute', bottom: 0, width: '100%' }}
        >
          <Button
            onClick={closeModal}
            color="primary"
            variant="contained"
            fullWidth
            style={{
              padding: '16px',
              fontSize: '1.25rem',
              backgroundColor: '#FF0000',
              color: 'white',
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={settingsModalOpen} onClose={() => setSettingsModalOpen(false)}>
        <Box sx={{ padding: '16px' }}>
            <Typography variant="h6">Settings</Typography>
            {/* Place any settings UI here */}
        </Box>
        <DialogActions>
            <Button onClick={() => setSettingsModalOpen(false)} color="primary">
            Close
            </Button>
        </DialogActions>
        </Dialog>

      <MobileBottomNav />
    </Grid>
  );
};

export default BatchReceiveBulkOrders;